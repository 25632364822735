/** @internal */
export function base64ToFile(base64: string, filename: string, mimeType?: string): File {
  const byteString = atob(base64);
  const int8Array = new Uint8Array(byteString.length);

  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }

  const type = mimeType || 'application/octet-stream';
  const blob = new Blob([int8Array], { type });
  return new File([blob], filename, { type });
}
