import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { CopyCodeComponent } from '../copy-code/copy-code.component';
import { MarkdownService } from 'ngx-markdown';

@Component({
  selector: 'doc',
  templateUrl: './doc.component.html',
  styleUrls: ['./doc.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class DocComponent implements OnChanges {
  @Input()
  markdownFileUrl!: string;

  @Input()
  replaceableParams: Record<string, string | undefined> = {};

  @Input()
  markdownData?: string;

  @Input()
  showCopyButton = true;

  protected textToRender?: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected readonly CopyCodeComponent = CopyCodeComponent as any;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly cdr: ChangeDetectorRef,
    markdownService: MarkdownService,
  ) {
    markdownService.renderer.link = ({ href, text }: { href: string | null; text: string }): string => {
      return `<a href="${href}" target="_blank">${text}</a>`;
    };
  }

  async ngOnChanges(): Promise<void> {
    if (this.markdownData) {
      this.textToRender = this.replaceParams(this.markdownData);
    } else {
      const body = await firstValueFrom(this.httpClient.get(this.markdownFileUrl, { responseType: 'text' }));
      this.textToRender = this.replaceParams(body);
      this.cdr.markForCheck();
    }
  }

  private replaceParams(template: string): string {
    let result = template;
    for (const [key, value] of Object.entries(this.replaceableParams)) {
      result = result.replace(new RegExp('\\${' + key + '}', 'g'), value || '');
    }

    // Matches `{{#if condition}} ... {{/if}}` blocks, capturing the condition and content. */
    const ifSearch = /\{\{#if (!?[^}]+)}}([\s\S]*?)\{\{\/if}}/g;

    // Handle simple if conditions (including negation)
    result = result.replace(ifSearch, (_match, condition, content) => {
      const isNegation = condition.startsWith('!');
      const key = isNegation ? condition.substring(1) : condition;
      const conditionMet = !!this.replaceableParams[key];

      return isNegation ? (!conditionMet ? content.trim() : '') : conditionMet ? content.trim() : '';
    });

    return result;
  }
}
