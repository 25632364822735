import { CollectionName, DocumentData, FieldName } from './document.public-types';
import { Paths } from './typescript.public-types';

/**
 * An alias for a join result. This is used to disambiguate fields in the result.
 * @category Database
 */
export type Alias = string;

/**
 * A join condition defining how two tables are related in a database query.
 * The join conditions specify the alias for the left side of the join and the field names to join on.
 * @category Database
 */
export interface JoinCondition {
  /** The alias of the left table in the join operation. */
  leftAlias: Alias;
  /** The field name from the left table to join on. */
  left: FieldName;
  /** The field name from the right table to join on. */
  right: FieldName;
  /** Indicates whether the join is an inner join (true) or outer join (false). */
  isInner: boolean;
}

/**
 * A list of query conditions.
 * @category Database
 */
export type Condition<Doc extends DocumentData = any> = SimpleCondition<Doc> | CompositeCondition<Doc>;

/**
 * A list of query conditions.
 * @category Database
 */
export type Conditions<Doc extends DocumentData = any> = Array<Condition<Doc>>;

/**
 * A single field query condition for filtering database records.
 * @category Database
 */
export interface SimpleCondition<
  Doc extends DocumentData = any,
  F extends Paths<Doc> = Paths<Doc>,
  MyOperator = Operator,
> {
  /** The field name to apply the condition to. */
  fieldName: F;
  /** The operator to use for comparing the field value. */
  operator: MyOperator;
  /** The value to compare against the field. */
  value: any;
}

/**
 * A composite fields query condition combining multiple simple conditions.
 * Just a list of simple conditions with specific set of operators. It should be
 * evaluated this way:
 * If A, B, C are the conditions and A' = A with '==' operator:
 * A || (A' && B) || (A' && B' && C)
 * @category Database
 */
export interface CompositeCondition<Doc extends DocumentData = any> {
  /** An array of simple conditions with restricted operators for composite evaluation. */
  fields: Array<SimpleCondition<Doc, Paths<Doc>, CompositeConditionOperator>>;
}

type CompositeConditionOperator = '>=' | '<=' | '>' | '<';

/**
 * The list of operators related to arrays.
 * @category Database
 */
export const ARRAY_OPERATORS = [
  'in',
  'not in',
  'array_includes_some',
  'array_includes_all',
  'array_not_includes',
] as const;

/**
 * The list of all operators.
 * @category Database
 */
export const ALL_OPERATORS = [
  ...ARRAY_OPERATORS,
  '==',
  '!=',
  '>=',
  '<=',
  '>',
  '<',
  'like',
  'not like',
  'like_cs',
  'not like_cs',
] as const;

/**
 * An operator in a query condition.
 * @category Database
 */
export type Operator = (typeof ALL_OPERATORS)[number];

/**
 * A definition of a sort order by a field in a database query.
 * @category Database
 */
export interface FieldSort<Doc> {
  /** The name of the field to sort by. */
  fieldName: FieldName<Doc>;
  /** Indicates whether the sort is ascending (true) or descending (false). */
  asc: boolean;
}

/**
 * A query object defining a database retrieval operation.
 * @category Database
 */
export interface Query<Doc extends DocumentData = any> {
  /** The full collection path such as "c1" or "c1/d1/c2". */
  collectionName: CollectionName;
  /** The ID of the integration associated with the query. */
  integrationId: string;
  /** An array of conditions to filter the query results. */
  conditions: Conditions<Doc>;
  /** An array of field sort definitions to order the results. */
  sortOrder: Array<FieldSort<Doc>>;
  /** The maximum number of records to return. */
  limit: number;
  /** Optional configuration to limit results by specific fields with custom sorting. */
  limitBy?: {
    /** The maximum number of records to return for this limit configuration. */
    limit: number;
    /** The fields to apply the limit to. */
    fields: Array<FieldName<Doc>>;
    /** Indicates whether to reverse the sort order for this limit. */
    reverseSort: boolean;
  };
}
