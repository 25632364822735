import { AiAgentId, BUILT_IN_AGENT_ID } from '../../../internal-common/src/public-types/communication.public-types';
import { RpcManager } from '../rpc.manager';
import {
  AgentContextRequest,
  AiAgent,
  AiAgentChatOptions,
  AiAgentContext,
  AiChatModelName,
  AiConnectedAgentMetadata,
  AiObserveStatusOptions,
  AiSearchOptions,
  AiSearchResultChunk,
  AiStatusMessage,
  AiTranscribeAndAskResponse,
  GuardrailsOptions,
  UpsertAgentRequest,
} from '../../../internal-common/src/public-types/ai-agent.public-types';
import { concatMap, delay, finalize, merge, Observable, of, share, Subject, takeWhile, tap } from 'rxjs';
import { assertTruthy, truthy } from 'assertic';
import { generateId } from '../../../internal-common/src/public-utils/id-utils';
import { map } from 'rxjs/operators';
import {
  AiAskRequest,
  AiAskResponse,
  AiAskWithVoiceResponse,
  AiChatRequest,
  AiSearchRequest,
  AiSearchResponse,
  AiTranscribeAndAskWithVoiceResponse,
  AiTranscribeAndChatResponse,
  DeleteAgentContextsRequest,
  DeleteAgentCustomGuardrailsRequest,
  DeleteAgentRequest,
  GetAgentContextRequest,
  GetAgentRequest,
  GetAgentResponse,
  ListAgentContextsRequest,
  ListAgentContextsResponse,
  ProvideAgentFeedbackRequest,
  ResetAgentFeedbackRequest,
  UpsertAgentContextsRequest,
  UpsertAgentCustomGuardrailsRequest,
  validateAiContextMetadata,
  validateAiContextMetadataFilter,
} from '../../../internal-common/src/types/ai-agent.types';
import { base64ToFile } from '../file-utils';
import {
  AiOngoingChatSequencesMap,
  AiStatusUpdatesMap,
  AskOptionsWithoutVoice,
  AskWithVoiceResponse,
  ChatOptionsWithoutVoice,
  EMPTY_CHAT_ID,
  TranscribeAndAskWithVoiceResponse,
  TranscribeAndChatResponse,
} from './ai-agent-client.types';
import { SocketManager } from '../socket.manager';

/**
 * Parameters for creating or updating an AI agent.
 * Excludes the `id` field, as it is derived from the agent instance.
 * @category AI
 */
export type UpsertAgentRequestParams = Omit<UpsertAgentRequest, 'id'>;

/**
 * AiAgentReference provides methods for managing AI agents, including
 * retrieving, updating, and deleting agents, handling agent contexts,
 * and interacting with chat or voice-based AI functionalities.
 * @category AI
 */
export class AiAgentReference {
  /** @internal */
  constructor(
    private readonly agentId: AiAgentId,
    private readonly ongoingChatSequences: AiOngoingChatSequencesMap,
    private readonly statusUpdates: AiStatusUpdatesMap,
    private readonly rpcManager: RpcManager,
    private readonly socketManager: SocketManager,
  ) {}

  /**
   * Retrieves metadata and configuration of the AI agent.
   */
  async get(): Promise<AiAgent | undefined> {
    const agentResponse = await this.rpcManager.post<GetAgentResponse | undefined, GetAgentRequest>(
      `ai/agent/getAgent`,
      {
        agentId: this.agentId,
      },
    );
    return agentResponse?.agent;
  }

  /**
   * Creates or updates the AI agent with the provided parameters.
   */
  async upsert(agent: UpsertAgentRequestParams): Promise<void> {
    await this.rpcManager.post<void, UpsertAgentRequest>(`ai/agent/upsertAgent`, {
      ...agent,
      id: this.agentId,
    });
  }

  /**
   * Deletes the AI agent.
   */
  async delete(): Promise<void> {
    await this.rpcManager.post<void, DeleteAgentRequest>(`ai/agent/deleteAgent`, {
      agentId: this.agentId,
    });
  }

  /**
   * Updates the agent's instruction prompt.
   */
  async updateInstructions(instructions: string): Promise<void> {
    const agent = await this.get();
    assertTruthy(agent, 'Agent not found');
    await this.upsert({
      ...agent,
      options: {
        ...agent.options,
        instructions,
      },
    });
  }

  /**
   * Changes the AI model used by the agent.
   */
  async updateModel(model: AiChatModelName): Promise<void> {
    const agent = await this.get();
    assertTruthy(agent, 'Agent not found');
    await this.upsert({
      ...agent,
      options: {
        ...agent.options,
        model,
      },
    });
  }

  /**
   * Updates the list of agents connected to this agent.
   */
  async updateConnectedAgents(connectedAgents: Array<AiConnectedAgentMetadata>): Promise<void> {
    const agent = await this.get();
    assertTruthy(agent, 'Agent not found');
    await this.upsert({
      ...agent,
      options: {
        ...agent.options,
        connectedAgents,
      },
    });
  }

  /**
   * Retrieves a specific agent context by its ID.
   */
  async getContext(contextId: string): Promise<AiAgentContext | undefined> {
    return await this.rpcManager.post<AiAgentContext | undefined, GetAgentContextRequest>(`ai/agent/getContext`, {
      agentId: this.agentId,
      contextId,
    });
  }

  /**
   * Lists all contexts associated with the agent.
   */
  async listContexts(): Promise<Array<AiAgentContext>> {
    const listContextsResponse = await this.rpcManager.post<ListAgentContextsResponse, ListAgentContextsRequest>(
      `ai/agent/listContexts`,
      {
        agentId: this.agentId,
      },
    );
    return listContextsResponse.contexts || [];
  }

  /**
   * Deletes a specific context by its ID.
   */
  async deleteContext(contextId: string): Promise<void> {
    await this.deleteContexts([contextId]);
  }

  /**
   * Deletes multiple agent contexts.
   */
  async deleteContexts(contextIds: Array<string>): Promise<void> {
    await this.rpcManager.post<void, DeleteAgentContextsRequest>(`ai/agent/deleteContexts`, {
      agentId: this.agentId,
      contextIds,
    });
  }

  /**
   * Adds or updates a single agent context.
   */
  async upsertContext(contextRequest: AgentContextRequest, file?: File): Promise<void> {
    await this.upsertContexts([contextRequest], file ? [file] : undefined);
  }

  /**
   * Adds or updates multiple agent contexts.
   */
  async upsertContexts(contextRequests: Array<AgentContextRequest>, files?: Array<File>): Promise<void> {
    for (const context of contextRequests) {
      if (context.metadata) {
        validateAiContextMetadata(context.metadata);
      }
    }
    await this.rpcManager.post<void, UpsertAgentContextsRequest>(
      `ai/agent/upsertContexts`,
      {
        agentId: this.agentId,
        contextRequests,
      },
      files,
    );
  }

  /**
   * Sends user feedback to the agent.
   */
  async provideFeedback(feedback: string): Promise<void> {
    await this.rpcManager.post<void, ProvideAgentFeedbackRequest>(`ai/agent/provideFeedback`, {
      agentId: this.agentId,
      feedback,
    });
  }

  /**
   * Resets any feedback previously provided to the agent.
   */
  async resetFeedback(): Promise<void> {
    await this.rpcManager.post<void, ResetAgentFeedbackRequest>(`ai/agent/resetFeedback`, {
      agentId: this.agentId,
    });
  }

  /**
   * Updates the agent's guardrails with the provided options.
   */
  async updateGuardrails(guardrails: GuardrailsOptions): Promise<void> {
    const agent = await this.get();
    assertTruthy(agent, 'Agent not found');
    await this.upsert({
      ...agent,
      options: {
        ...agent.options,
        guardrails: {
          ...agent.options?.guardrails,
          ...guardrails,
        },
      },
    });
  }

  /**
   * Updates the agent's custom guardrails with the provided custom guardrail.
   */
  async updateCustomGuardrails(customGuardrail: string): Promise<void> {
    await this.rpcManager.post<void, UpsertAgentCustomGuardrailsRequest>(`ai/agent/upsertCustomGuardrails`, {
      agentId: this.agentId,
      customGuardrail,
    });
  }

  /**
   * Deletes the custom guardrails for the agent.
   */
  async deleteCustomGuardrail(): Promise<void> {
    await this.rpcManager.post<void, DeleteAgentCustomGuardrailsRequest>(`ai/agent/deleteCustomGuardrails`, {
      agentId: this.agentId,
    });
  }

  /**
   * Sends a prompt to the agent and receives streamed text responses.
   */
  chat(prompt: string, options?: ChatOptionsWithoutVoice): Observable<string> {
    return this.chatInternal(prompt, options).responseStream;
  }

  /**
   * Transcribes the given file and performs a chat interaction.
   */
  async transcribeAndChat(
    fileToTranscribe: File,
    options?: ChatOptionsWithoutVoice,
  ): Promise<TranscribeAndChatResponse> {
    const resp = this.chatInternal(fileToTranscribe, options);
    const serverResponse = await truthy(resp.serverResponse, 'TRANSCRIPTION_RESPONSE_NOT_FOUND');
    return { responseStream: resp.responseStream, transcribedPrompt: serverResponse.transcribedPrompt };
  }

  /**
   * Performs a semantic search using the agent's knowledge base.
   */
  async search(options: AiSearchOptions): Promise<Array<AiSearchResultChunk>> {
    assertTruthy(this.agentId !== BUILT_IN_AGENT_ID, 'Cannot search the built-in agent');
    const request: AiSearchRequest = {
      options,
      agentId: this.agentId,
    };
    const response = await this.rpcManager.post<AiSearchResponse>('ai/chatbot/search', request);
    return response.chunks;
  }

  /**
   * Sends a prompt and receives a full string response.
   */
  async ask(prompt: string, options?: AskOptionsWithoutVoice): Promise<string> {
    const response = await this.askInternal<AiAskResponse>(prompt, false, options);
    return response.responseString;
  }

  /**
   * Observes live status messages from the agent.
   */
  observeStatusUpdates(options?: AiObserveStatusOptions): Observable<AiStatusMessage> {
    const { chatId } = options || {};
    this.createStatusSubject(chatId);
    if (chatId) {
      return this.statusUpdates[this.agentId][chatId].asObservable();
    } else {
      return merge(...Object.values(this.statusUpdates[this.agentId]));
    }
  }

  /**
   * Transcribes audio and sends it to the agent for response.
   */
  async transcribeAndAsk(
    fileToTranscribe: File,
    options?: AskOptionsWithoutVoice,
  ): Promise<AiTranscribeAndAskResponse> {
    return await this.askInternal<AiTranscribeAndAskResponse>(fileToTranscribe, false, options);
  }

  /**
   * Transcribes audio and gets both text and voice response from the agent.
   */
  async transcribeAndAskWithVoiceResponse(
    fileToTranscribe: File,
    options?: Omit<AiAgentChatOptions, 'smoothTyping'>,
  ): Promise<TranscribeAndAskWithVoiceResponse> {
    const response = await this.askInternal<AiTranscribeAndAskWithVoiceResponse>(fileToTranscribe, true, options);
    const voiceResponse = response.voiceResponse;
    return {
      responseString: response.responseString,
      transcribedPrompt: response.transcribedPrompt,
      voiceResponseFile: base64ToFile(
        voiceResponse.base64File,
        `voice.${voiceResponse.extension}`,
        voiceResponse.mimeType,
      ),
    };
  }

  /**
   * Sends a prompt and gets both text and voice response from the agent.
   */
  async askWithVoiceResponse(
    prompt: string,
    options?: Omit<AiAgentChatOptions, 'smoothTyping'>,
  ): Promise<AskWithVoiceResponse> {
    const response = await this.askInternal<AiAskWithVoiceResponse>(prompt, true, options);
    const voiceResponse = response.voiceResponse;
    return {
      responseString: response.responseString,
      voiceResponseFile: base64ToFile(
        voiceResponse.base64File,
        `voice.${voiceResponse.extension}`,
        voiceResponse.mimeType,
      ),
    };
  }

  private async askInternal<T>(
    prompt: string | File,
    isVoiceResponse: boolean,
    options?: Omit<AiAgentChatOptions, 'smoothTyping'>,
  ): Promise<T> {
    if (options?.contextMetadataFilter) {
      validateAiContextMetadataFilter(options.contextMetadataFilter);
    }
    options = { ...DEFAULT_CHAT_OPTIONS, ...(options || {}) };
    const promptIsString = typeof prompt === 'string';
    let endpoint = 'ai/chatbot/';

    if (!promptIsString && !isVoiceResponse) {
      endpoint += 'transcribeAndAsk';
    } else if (!promptIsString && isVoiceResponse) {
      endpoint += 'transcribeAndAskWithVoiceResponse';
    } else if (promptIsString && isVoiceResponse) {
      endpoint += 'askWithVoiceResponse';
    } else {
      endpoint += 'ask';
    }

    const request: AiAskRequest = {
      agentId: this.agentId,
      prompt: promptIsString ? prompt : undefined,
      options,
    };

    const files = promptIsString ? undefined : [prompt as File];
    return await this.rpcManager.post<T>(endpoint, request, files, 'file');
  }

  private createStatusSubject(chatId?: string): void {
    const chatSubjects = this.statusUpdates[this.agentId];
    if (!chatSubjects) this.statusUpdates[this.agentId] = {};

    const key = chatId || EMPTY_CHAT_ID;
    const subject = this.statusUpdates[this.agentId][key];
    if (!subject) this.statusUpdates[this.agentId][key] = new Subject<AiStatusMessage>();
  }

  private chatInternal(prompt: string | File, options?: ChatOptionsWithoutVoice): ChatInternalResponse {
    this.socketManager.notifyWebSocketIsNeeded();
    if (options?.contextMetadataFilter) {
      validateAiContextMetadataFilter(options.contextMetadataFilter);
    }
    const clientRequestId = generateId();

    options = { ...DEFAULT_CHAT_OPTIONS, ...(options || {}) };
    const smoothTyping = options.smoothTyping === undefined ? true : options.smoothTyping;
    let accumulatedValue = '';
    const subject = new Subject<string>();
    const tokenSequence = new Subject<{ value: string; complete: boolean; tokenIndex?: number }>();

    this.ongoingChatSequences[clientRequestId] = tokenSequence;
    let lastTokenIndex: number = -1;
    tokenSequence
      .pipe(
        tap(({ tokenIndex }) => {
          if (tokenIndex !== undefined && tokenIndex < lastTokenIndex) {
            console.warn('Received token index out of order', tokenIndex, lastTokenIndex);
          }
          if (tokenIndex !== undefined) {
            lastTokenIndex = tokenIndex;
          }
        }),
        concatMap(({ value, complete }) => {
          if (complete) {
            return of({ value, complete });
          }

          return of(value).pipe(
            smoothTyping ? delay(0) : tap(),
            map(str => ({ value: str, complete: false })),
          );
        }),
        takeWhile(({ complete }) => !complete, true),
      )
      .subscribe({
        next: ({ value }) => {
          accumulatedValue += value;
          subject.next(accumulatedValue);
        },
        error: e => {
          console.error(e);
        },
        complete: () => {
          subject.complete();
        },
      });

    const promptIsString = typeof prompt === 'string';

    const request: AiChatRequest = {
      agentId: this.agentId,
      prompt: promptIsString ? prompt : undefined,
      options,
      clientRequestId,
    };

    const stream = subject.pipe(
      finalize(() => {
        delete this.ongoingChatSequences[clientRequestId];
      }),
      share(),
    );
    const response: ChatInternalResponse = { responseStream: stream };
    if (promptIsString) {
      this.rpcManager.post<string>('ai/chatbot/chat', request).catch(e => {
        subject.error(e);
        subject.complete();
      });
    } else {
      response.serverResponse = this.rpcManager
        .post<AiTranscribeAndChatResponse>('ai/chatbot/transcribeAndChat', request, [prompt as File], 'file')
        .catch(e => {
          subject.error(e);
          subject.complete();
          throw e;
        });
    }

    return response;
  }
}

const DEFAULT_CHAT_OPTIONS: AiAgentChatOptions = {
  smoothTyping: true,
  responseFormat: 'text',
};

interface ChatInternalResponse {
  responseStream: Observable<string>;
  serverResponse?: Promise<AiTranscribeAndChatResponse>;
}
