<mat-dialog-content class="embed_widget_dialog">
  <dialog-close-button></dialog-close-button>
  <ng-container *ngIf="appObs | async as app">
    <doc
      [markdownFileUrl]="'assets/docs/ai/embed-widget.md'"
      [replaceableParams]="{
         appId: (app.appId | parseAppId).appId,
         region: getRegion(app),
         environmentId: (app.appId | parseAppId).environmentId,
         integrationId: AI_AGENTS_INTEGRATION_ID,
         agentId: data.agentId,
         headerTitle: 'Squid AI',
         introText: 'Hi, I am Rudder, an AI from Squid. How can I help?',
       }">
    </doc>
  </ng-container>
</mat-dialog-content>
