import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { debounceTime, Observable, Subject, take } from 'rxjs';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';
import { AiAgentAndContexts, StudioService } from '@squidcloud/console-web/app/studio/studio.service';
import {
  AGENT_ID_PARAMETER,
  getQueryParameter,
  getRequiredPageParameter,
  INTEGRATION_ID_PARAMETER,
} from '@squidcloud/console-web/app/utils/http-utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  AiAgentContext,
  AiAgentId,
  AiChatModelName,
  AiConnectedIntegrationMetadata,
  FunctionName,
  FunctionNameWithContext,
  IntegrationType,
} from '@squidcloud/client';
import { AbilityConstructorMap, AbilityFactory } from '@squidcloud/console-web/app/studio/agent/abilities/factory';
import { BaseAbility } from '@squidcloud/console-web/app/studio/agent/abilities/base.ability';
import { AiFunctionMetadata } from '@squidcloud/internal-common/types/bundle-data.types';
import { getFunctionNameFromServiceFunctionName } from '@squidcloud/internal-common/utils/bundle-utils';
import { getEntries } from '@squidcloud/console-web/app/utils/angular-utils';
import { PortalService } from '@squidcloud/console-web/app/global/portal/portal.service';
import { IntegrationService } from '@squidcloud/console-web/app/integrations/integration.service';
import { Categories, DefaultIntegrations, Integrations } from '@squidcloud/console-web/app/integrations/utils/content';
import {
  AbilityConnectOptionsMap,
  AbilityCreateOptionsMap,
  AbilityEditOptionsMap,
  AbilityType,
  AbilityTypeWithCreate,
  isAbilityType,
  KnowledgeAbilityType,
} from '@squidcloud/console-web/app/studio/agent/abilities/types';
import { CpIntegration } from '@squidcloud/console-common/types/application.types';
import { IntegrationCategory } from '@squidcloud/console-common/types/integration.types';
import { groupBy } from '@squidcloud/internal-common/utils/object';
import { truthy } from 'assertic';
import { SnackBarService } from '@squidcloud/console-web/app/global/services/snack-bar.service';
import { getLocalTimeWithTimeZone } from '@squidcloud/internal-common/utils/time-utils';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrl: './create.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AbilityFactory],
  standalone: false,
})
export class StudioCreateComponent implements OnInit, OnDestroy {
  readonly agentId: string;
  readonly agentAndContexts$: Observable<AiAgentAndContexts | undefined>;
  readonly application$ = this.applicationService.currentApplication$;

  @ViewChild('studioFlyout', { static: true }) studioFlyoutRef!: TemplateRef<unknown>;

  readonly getEntries = getEntries;
  readonly isAbilityType = isAbilityType;

  ModelNameMap: Record<AiChatModelName, string> = {
    'gpt-4o': 'GPT-4o',
    'gpt-4o-mini': 'GPT-4o Mini',
    o1: 'GPT-o1',
    'o1-mini': 'GPT-o1 Mini',
    'o3-mini': 'GPT-o3 Mini',
    'claude-3-7-sonnet-latest': 'Claude 3.7 Sonnet',
    'claude-3-5-sonnet-latest': 'Claude 3.5 Sonnet',
    'claude-3-5-haiku-latest': 'Claude 3.5 Haiku',
    'gemini-1.5-pro': 'Gemini Pro 1.5',
    'gemini-2.0-flash': 'Gemini Flash 2.0',
  };

  readonly modelOptions = Object.entries(this.ModelNameMap).map(([value, name]) => ({ name, value }));

  instructionsUpdatedDate$ = new Subject<string | undefined>();
  form!: FormGroup;
  isSearching = false;
  showManyAbilitiesCard = true;
  searchTerm: string | undefined;

  constructor(
    activatedRoute: ActivatedRoute,
    private readonly applicationService: ApplicationService,
    private readonly integrationService: IntegrationService,
    private readonly studioService: StudioService,
    private readonly formBuilder: FormBuilder,
    private readonly abilityFactory: AbilityFactory,
    private readonly portalService: PortalService,
    private readonly snackBar: SnackBarService,
    private readonly router: Router,
  ) {
    this.agentId = getRequiredPageParameter(AGENT_ID_PARAMETER, activatedRoute.snapshot);
    this.agentAndContexts$ = this.studioService.observeAgent(this.agentId);

    this.applicationService.currentApplication$.pipe(takeUntilDestroyed()).subscribe(application => {
      void this.studioService.initialize(truthy(application).appId);
    });

    this.form = this.formBuilder.group({
      model: new FormControl('', []),
      instructions: new FormControl('', []),
    });

    // Set default values for the modelName and instructions.
    this.agentAndContexts$.pipe(take(1)).subscribe(async agentAndContexts => {
      if (agentAndContexts) {
        const { agent } = agentAndContexts;
        this.form.patchValue({ model: agent.options.model, instructions: agent.options.instructions || '' });
        void this.handleIntegrationIdParam(activatedRoute.snapshot);
      }
    });

    this.form.get('model')?.valueChanges.subscribe(this.updateModel.bind(this));
    this.form.get('instructions')?.valueChanges.pipe(debounceTime(500)).subscribe(this.upsertInstructions.bind(this));
  }

  ngOnInit(): void {
    this.portalService.attach(this.studioFlyoutRef);
  }

  ngOnDestroy(): void {
    this.studioService.closeFlyout();
    this.portalService.detach();
  }

  private async handleIntegrationIdParam(snapshot: ActivatedRouteSnapshot): Promise<void> {
    const integrationId = getQueryParameter(INTEGRATION_ID_PARAMETER, snapshot);
    if (!integrationId) return;

    const integration = await this.integrationService.getIntegration(integrationId);
    if (integration && isAbilityType(integration.type)) {
      void this.onConnect(integration.type, {
        integrationId,
        integrationType: integration.type,
      });
    }
    const params = { ...snapshot.queryParams };
    delete params['integrationId'];
    void this.router.navigate([], { queryParams: params, replaceUrl: true });
  }

  async updateModel(model: AiChatModelName): Promise<void> {
    try {
      await this.studioService.updateModel(this.agentId, model);
      this.snackBar.success('Model updated');
    } catch (e) {
      console.error('Error:', e);
      this.snackBar.warning('Something went wrong. Please try again later.');
    }
  }

  async upsertInstructions(instructions: string): Promise<void> {
    await this.studioService.updateInstructions(this.agentId, instructions);
    this.instructionsUpdatedDate$.next(getLocalTimeWithTimeZone());
  }

  addAbility(): void {
    this.studioService.openFlyout();
  }

  get abilitiesCount(): number {
    const { agent, contextMap } = this.studioService.getAgentAndContextsOrFail(this.agentId);

    return (
      (agent.options.connectedAgents?.length || 0) +
      (agent.options.connectedIntegrations?.length || 0) +
      (agent.options.functions?.length || 0) +
      Object.values(contextMap).length
    );
  }

  inferContextType(context: AiAgentContext): KnowledgeAbilityType {
    const fileExtensions = /\.(jpg|jpeg|png|gif|pdf|docx?|xlsx?|pptx?|txt|js|json|xml|csv)$/i;
    if (context.type) return context.type;
    if (fileExtensions.test(context.text)) return 'file';
    return 'text';
  }

  editConnector(integrationId: string, integrationType: IntegrationType): void {
    void this.onEdit(integrationType as AbilityType, { integrationId, integrationType });
  }

  deleteConnector(integrationId: string, integrationType: IntegrationType): void {
    void this.onDelete(integrationType as AbilityType, { integrationId, integrationType });
  }

  async onCreate<T extends AbilityType>(type: T, options?: AbilityCreateOptionsMap[T]): Promise<void> {
    const ability = this.getAbility(type);
    const success = await ability.onCreate(this.agentId, options);
    if (success) {
      this.studioService.closeFlyout();
    }
  }

  async onConnect<T extends AbilityType>(type: T, options?: AbilityConnectOptionsMap[T]): Promise<void> {
    const ability = this.getAbility(type);
    const success = await ability.onConnect(this.agentId, options);
    if (success) {
      this.studioService.closeFlyout();
    }
  }

  async onEdit<T extends AbilityType>(type: T, options?: AbilityEditOptionsMap[T]): Promise<void> {
    const ability = this.getAbility(type);
    await ability.onEdit(this.agentId, options);
  }

  async onDelete<T extends AbilityType>(type: T, options?: AbilityEditOptionsMap[T]): Promise<void> {
    const ability = this.getAbility(type);
    await ability.onDelete(this.agentId, options);
  }

  private getAbility<T extends AbilityType>(
    type: T,
  ): BaseAbility<AbilityCreateOptionsMap[T], AbilityConnectOptionsMap[T], AbilityEditOptionsMap[T]> {
    return this.abilityFactory.getAbility(type) as BaseAbility<
      AbilityCreateOptionsMap[T],
      AbilityConnectOptionsMap[T],
      AbilityEditOptionsMap[T]
    >;
  }

  get connectedIntegrationsByCategory(): Record<IntegrationCategory, Array<AiConnectedIntegrationMetadata>> {
    const { agent } = this.studioService.getAgentAndContextsOrFail(this.agentId);
    return groupBy(
      (agent.options.connectedIntegrations || []).filter(i => isAbilityType(i.integrationType)),
      i => Integrations[i.integrationType].category,
    );
  }

  getCategoryName(category: string): string {
    return truthy(Categories[category as IntegrationCategory]).name;
  }

  get eligibleConnectors(): Array<AbilityTypeWithCreate> {
    const eligible = Object.entries(Integrations).filter(([type, content]) => {
      return !!AbilityConstructorMap[type as AbilityType] && content.available && !DefaultIntegrations.includes(type);
    });
    return eligible.map(([type]) => type as AbilityTypeWithCreate);
  }

  get existingConnectors(): Array<CpIntegration> {
    const integrations = this.integrationService.getAllIntegrations();
    const { agent } = this.studioService.getAgentAndContextsOrFail(this.agentId);
    return integrations.filter(integration => {
      return (
        !!AbilityConstructorMap[integration.type as AbilityType] &&
        !(agent.options.connectedIntegrations || []).find(i => i.integrationId === integration.id)
      );
    });
  }

  get eligibleAiFunctions(): Array<AiFunctionMetadata> {
    const allAiFunctions = Object.values(
      this.applicationService.getCurrentApplicationOrFail().bundleData?.aiFunctions || {},
    );
    const { agent } = this.studioService.getAgentAndContextsOrFail(this.agentId);
    const usedFunctions = agent.options.functions || [];
    return allAiFunctions.filter(
      f =>
        !usedFunctions.includes(getFunctionNameFromServiceFunctionName(f.serviceFunction)) &&
        !f.attributes?.integrationType?.length,
    );
  }

  get eligibleConnectedAgentIds(): Array<AiAgentId> {
    const agentsAndContexts = this.studioService.getAgentAndContextsMap();
    const allOtherAgentIds = Object.keys(agentsAndContexts).filter(id => id !== this.agentId);
    const agentIdsThatDoNotUseCurrentAgent: Array<AiAgentId> = [];
    const checkedAgentResults = new Map<AiAgentId, boolean>();

    for (const agentId of allOtherAgentIds) {
      const isDependencyLoop = this.checkIfAgentIdIsInConnectedAgentHierarchy(
        this.agentId,
        agentId,
        checkedAgentResults,
        agentsAndContexts,
      );
      if (!isDependencyLoop) {
        agentIdsThatDoNotUseCurrentAgent.push(agentId);
      }
    }

    const connectedAgents = agentsAndContexts[this.agentId]?.agent.options.connectedAgents || [];
    return agentIdsThatDoNotUseCurrentAgent.filter(id => !connectedAgents.some(p => p.agentId === id));
  }

  getAiFunctionDescription(name: string): string {
    const allAiFunctions = Object.values(
      this.applicationService.getCurrentApplicationOrFail().bundleData?.aiFunctions || {},
    );
    return (
      allAiFunctions.find(metadata => getFunctionNameFromServiceFunctionName(metadata.serviceFunction) === name)
        ?.description || ''
    );
  }

  private checkIfAgentIdIsInConnectedAgentHierarchy(
    agentId: AiAgentId,
    agentIdToCheck: AiAgentId,
    checkedAgentResults: Map<AiAgentId, boolean>,
    agentAndContextsMap: Record<AiAgentId, AiAgentAndContexts>,
  ): boolean {
    const checkedResult = checkedAgentResults.get(agentIdToCheck);
    if (checkedResult !== undefined) {
      return checkedResult;
    }
    const agentAndContextsToCheck = agentAndContextsMap[agentIdToCheck];
    const agent = agentAndContextsToCheck.agent;
    if (!agent?.options.connectedAgents) {
      checkedAgentResults.set(agentIdToCheck, false);
      return false;
    }
    for (const agentInfo of agent.options.connectedAgents) {
      if (
        agentInfo.agentId === agentId ||
        this.checkIfAgentIdIsInConnectedAgentHierarchy(
          agentId,
          agentInfo.agentId,
          checkedAgentResults,
          agentAndContextsMap,
        )
      ) {
        checkedAgentResults.set(agentIdToCheck, true);
        return true;
      }
    }
    checkedAgentResults.set(agentIdToCheck, false);
    return false;
  }

  protected readonly getFunctionNameFromServiceFunctionName = getFunctionNameFromServiceFunctionName;

  getFunctionName(fn: FunctionName | FunctionNameWithContext): string {
    if (!fn) return '';
    if (typeof fn === 'string') return fn;
    return (fn as FunctionNameWithContext).name;
  }

  onSearchClick(): void {
    this.isSearching = !this.isSearching;
    if (!this.isSearching) {
      this.searchTerm = undefined;
    }
  }

  searchAbilities(e: Event): void {
    const target = e.target as HTMLInputElement;
    this.searchTerm = target.value;
    console.log(this.searchTerm);
  }

  hideManyAbilitiesCard(): void {
    this.showManyAbilitiesCard = false;
  }
}
