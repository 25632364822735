import { SQUID_REGIONS, SquidRegion } from '@squidcloud/client';
import { Stage, STAGES } from '@squidcloud/internal-common/types/stage';
import { assertTruthy, fail } from 'assertic';
import { CpUser } from '@squidcloud/console-common/types/account.types';
import { Organization } from '@squidcloud/console-common/types/organization.types';
import { isE2eTestUserEmail } from '@squidcloud/console-common/utils/e2e-test-common-utils';

export const DEFAULT_EGRESS_IPS = ['3.220.162.108', '34.224.234.200'] as const;

export const SQUID_CLOUD_IDS = ['aws', 'gcp', 'azure'] as const;
export type SquidCloudId = (typeof SQUID_CLOUD_IDS)[number];

// Validate that publicly exposed Squid regions use valid cloud ids.
for (const r of SQUID_REGIONS) {
  assertTruthy(
    SQUID_CLOUD_IDS.some(c => r.endsWith(`.${c}`)),
    () => `Unknown squid region: ${r}`,
  );
}

export interface Environment {
  id: string;
  stage: Stage;
  name: string;
  /**
   * Native region name for the cloud. Example: 'us-east-1'.
   * Note: not the same as SquidRegion (which includes additional cloud info).
   */
  cloudRegion: string;
  shard: string;
  cloudId: SquidCloudId;
  egressIps: string[];
  /** If set to true the option will be exposed in the UI for the correct stage. */
  exposeInUi: boolean;
}

export interface SquidCloud {
  id: SquidCloudId;
  name: string;
  icon: string;
  environments: Array<Environment>;
  tooltip?: string;
}

export const ALL_SQUID_ENVIRONMENTS: Array<Environment> = [
  {
    id: 'local',
    stage: 'local',
    name: 'Your laptop',
    cloudRegion: 'local',
    shard: 'default',
    cloudId: 'aws',
    egressIps: [...DEFAULT_EGRESS_IPS],
    exposeInUi: true,
  },
  {
    id: 'us-east-1',
    stage: 'prod',
    name: 'US East (N. Virginia)',
    cloudRegion: 'us-east-1',
    shard: 'default',
    cloudId: 'aws',
    egressIps: [...DEFAULT_EGRESS_IPS],
    exposeInUi: true,
  },
  {
    id: 'us-east-1',
    stage: 'sandbox',
    name: 'US East (N. Virginia – Sandbox)',
    cloudRegion: 'us-east-1',
    shard: 'default',
    cloudId: 'aws',
    egressIps: ['44.221.9.58', '3.231.10.240'],
    exposeInUi: true,
  },
  {
    id: 'us-east-1-ntt',
    stage: 'prod',
    name: 'US East (N. Virginia – NTT)',
    cloudRegion: 'us-east-1',
    shard: 'ntt',
    cloudId: 'aws',
    egressIps: ['44.215.158.103', '52.71.77.250'],
    exposeInUi: false,
  },
  {
    id: 'ap-south-1',
    stage: 'prod',
    name: 'Asia-Pacific (Mumbai)',
    cloudRegion: 'ap-south-1',
    shard: 'default',
    cloudId: 'aws',
    egressIps: ['13.126.174.216', '13.234.104.215'],
    exposeInUi: true,
  },
  {
    id: 'us-east-1-sample',
    stage: 'sandbox',
    name: 'US East (N. Virginia – Sandbox Sample shard)',
    cloudRegion: 'us-east-1',
    shard: 'sample',
    cloudId: 'aws',
    egressIps: ['23.20.175.26', '44.205.174.82'],
    exposeInUi: true,
  },
  {
    id: 'us-east-2',
    stage: 'sandbox',
    name: 'US East (Ohio – Sandbox)',
    cloudRegion: 'us-east-2',
    shard: 'default',
    cloudId: 'aws',
    egressIps: ['3.23.250.39', '3.134.64.65'],
    exposeInUi: true,
  },
  {
    id: 'us-central1',
    stage: 'sandbox',
    name: 'US Central (Iowa – Sandbox)',
    cloudRegion: 'us-central1',
    shard: 'default',
    cloudId: 'gcp',
    egressIps: ['35.192.48.95', '146.148.102.113'],
    exposeInUi: true,
  },
  {
    id: 'us-central1',
    stage: 'prod',
    name: 'US Central (Iowa)',
    cloudRegion: 'us-central1',
    shard: 'default',
    cloudId: 'gcp',
    egressIps: ['35.193.112.13', '34.42.176.90'],
    exposeInUi: true,
  },
];

// Check that we do not miss a UI visible production stage region in public API.
for (const cloud of ALL_SQUID_ENVIRONMENTS) {
  if (cloud.stage === 'prod' && cloud.exposeInUi) {
    const squidRegion = convertToSquidRegion(cloud.cloudId, cloud.cloudRegion, cloud.shard, 'prod');
    assertTruthy(
      SQUID_REGIONS.includes(squidRegion),
      `User facing production region is not included into SQUID_REGION enum: ${squidRegion}`,
    );
  }
}

/**
 * Returns a filtered list of Squid clouds for the given stage.
 * The organization may be undefined for the code that wants to show Squid Clouds with no organization context (like
 * onboarding).
 */
export function getSquidClouds(stage: Stage, user: CpUser, _org: Organization | undefined): SquidCloud[] {
  const includeNonExposedInUi = isE2eTestUserEmail(user?.email);
  const visibleEnvironments = ALL_SQUID_ENVIRONMENTS.filter(
    e => e.stage === stage && (e.exposeInUi || includeNonExposedInUi),
  );

  return [
    {
      id: 'aws',
      name: 'Amazon Web Services',
      icon: 'aws_icon',
      environments: visibleEnvironments.filter(e => e.cloudId === 'aws'),
    },
    {
      id: 'gcp',
      name: 'Google Cloud Platform',
      icon: 'gcp_icon',
      environments: visibleEnvironments.filter(e => e.cloudId === 'gcp'),
    },
    {
      id: 'azure',
      name: 'Microsoft Azure',
      icon: 'azure_icon',
      tooltip: 'Microsoft Azure support is coming soon!',
      environments: visibleEnvironments.filter(e => e.cloudId === 'azure'),
    },
  ];
}

export function convertToSquidRegion(
  cloudId: SquidCloudId,
  region: string,
  shard: string | undefined,
  stage: string,
): SquidRegion {
  if (region === 'local') return 'local' as SquidRegion;
  const shardPrefix = shard && shard !== 'default' ? `${shard}.` : '';
  const stageSuffix = stage === 'prod' ? '' : `.${stage}`;
  return `${shardPrefix}${region}.${cloudId}${stageSuffix}` as SquidRegion;
}

/** Converts an extended Squid region (like 'us-east-1.aws.sandbox') to Stage. */
export function getStageBySquidRegion(squidRegion: string): Stage {
  for (const env of ALL_SQUID_ENVIRONMENTS) {
    for (const stage of STAGES) {
      const envSquidRegion = convertToSquidRegion(env.cloudId, env.cloudRegion, env.shard, stage);
      if (envSquidRegion === squidRegion) {
        return stage;
      }
    }
  }
  fail('Unsupported Squid region: ' + squidRegion);
}
