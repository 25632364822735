import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'schema-table-header',
  templateUrl: './schema-table-header.component.html',
  styleUrls: ['./schema-table-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SchemaTableHeaderComponent {
  @Input() title!: string;
  @Input() method!: string;
  @Input() icon!: string;
  @Input() isModified = false;
  @Input() isSearching = false;

  @Input() hideEdit = false;
  @Input() hideAdd = false;
  @Input() hideGenerateTypeScriptInterface = false;
  @Input() hideGenerateAiDescription = false;

  @Output() edit = new EventEmitter<void>();
  @Output() add = new EventEmitter<void>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() search = new EventEmitter<string>();
  @Output() generateTypeScriptInterface = new EventEmitter<void>();
  @Output() generateAiDescription = new EventEmitter<void>();

  searchAction(e: Event): void {
    const target = e.target as HTMLInputElement;
    this.search.emit(target.value);
  }

  onSearchClick(): void {
    this.isSearching = !this.isSearching;
    if (!this.isSearching) this.search.emit(undefined);
  }
}
