import { BaseAbility } from '@squidcloud/console-web/app/studio/agent/abilities/base.ability';
import { IntegrationType } from '@squidcloud/client';
import { truthy } from 'assertic';
import { Integrations } from '@squidcloud/console-web/app/integrations/utils/content';
import { AbilityGroup, AbilityMetadata } from '@squidcloud/console-web/app/studio/agent/abilities/types';
import {
  getIntegrationAgentDefaultFunctionDescription,
  getIntegrationAgentDefaultInstructions,
} from '@squidcloud/internal-common/ai/ai-instructions';

type CreateOptions = {
  integrationType: string;
};

type Options = {
  integrationType: string;
  integrationId: string;
};

export class ConnectorAbility extends BaseAbility<CreateOptions, Options, Options> {
  override async onCreate(agentId: string, options: CreateOptions): Promise<boolean> {
    const { integrationType } = options;
    const application = this.applicationService.getCurrentApplicationOrFail();
    await this.router.navigate(['/application', application.appId, 'integrations', 'add', integrationType], {
      queryParams: { agentId },
    });
    return true;
  }

  override async onConnect(agentId: string, options: Options): Promise<boolean> {
    await this.updateConnector(agentId, options, false);
    return true;
  }

  override async onEdit(agentId: string, options: Options): Promise<boolean> {
    return this.updateConnector(agentId, options, true);
  }

  private async updateConnector(agentId: string, options: Options, isEdit: boolean): Promise<boolean> {
    const { integrationId, integrationType } = options;
    const { agent } = this.studioService.getAgentAndContextsOrFail(agentId);
    const currentIntegrations = [...(agent.options?.connectedIntegrations || [])];
    const connectedIntegration = currentIntegrations.find(i => i.integrationId === integrationId);

    if (!isEdit && connectedIntegration) {
      return false;
    }

    const defaultDescription = getIntegrationAgentDefaultFunctionDescription(integrationType as IntegrationType);
    const defaultInstructions = getIntegrationAgentDefaultInstructions(integrationType as IntegrationType);

    return new Promise((resolve, reject) => {
      this.globalUiService.showDialogWithForm<{ description: string; instructions: string }>(
        {
          title: `${isEdit ? 'Edit' : 'Connect'} ${integrationId}`,
          textLines: [`Describe to the agent when to use this connector.`],
          submitButtonText: `${isEdit ? 'Save' : 'Add'} Connector`,
          formElements: [
            {
              type: 'textarea',
              label: 'When to Use',
              nameInForm: 'description',
              placeholder: defaultDescription,
              floatLabel: 'always',
              required: false,
              attributes: { autosize: true, minRows: 3, maxRows: 10 },
              defaultValue: connectedIntegration?.description,
            },
            {
              type: 'textarea',
              label: 'Instructions',
              nameInForm: 'instructions',
              placeholder: defaultInstructions,
              floatLabel: 'always',
              required: false,
              attributes: { autosize: true, minRows: 6, maxRows: 50 },
              defaultValue: connectedIntegration?.instructions,
            },
          ],
          onSubmit: async (data): Promise<string | void> => {
            try {
              if (!data) return;
              const { description, instructions } = data;
              const { agent } = this.studioService.getAgentAndContextsOrFail(agentId);
              const currentIntegrations = [...(agent.options?.connectedIntegrations || [])];

              if (isEdit) {
                const connectedIntegration = truthy(currentIntegrations.find(i => i.integrationId === integrationId));
                connectedIntegration.description = description;
                connectedIntegration.instructions = instructions;
              } else {
                currentIntegrations.push({
                  integrationId,
                  integrationType: integrationType as IntegrationType,
                  description,
                  instructions,
                });
              }

              agent.options.connectedIntegrations = currentIntegrations;
              await this.studioService.upsertAgent(agentId, agent);
              resolve(true);
            } catch (e) {
              reject(e);
            }
          },
        },
        true,
      );
    });
  }

  override async onDelete(agentId: string, options: Options): Promise<boolean> {
    const { integrationId } = options;

    return new Promise(async (resolve, reject) => {
      this.globalUiService.showConfirmationDialog(
        'Remove connector?',
        `Are you sure you want to remove the <b>${integrationId}</b> connector?`,
        'Remove',
        async () => {
          const { agent } = this.studioService.getAgentAndContextsOrFail(agentId);
          const currentIntegrations = [...(agent.options.connectedIntegrations || [])];
          const index = currentIntegrations.findIndex(i => i.integrationId === integrationId);
          currentIntegrations.splice(index, 1);

          agent.options.connectedIntegrations = currentIntegrations;
          try {
            await this.studioService.upsertAgent(agentId, agent);
            resolve(true);
          } catch (e) {
            reject(e);
          }
        },
      );
    });
  }

  static getAbilityMetadata(type: IntegrationType, group?: AbilityGroup): AbilityMetadata {
    return {
      name: Integrations[type].name,
      description: Integrations[type].description,
      icon: type,
      group,
    };
  }
}
