import { filter, first, firstValueFrom, interval } from 'rxjs';

/** @internal */
export class RateLimiter {
  private tokens: number;
  private lastRefillTimestamp: number;
  private readonly refillRatePerMs: number;

  /**
   * Creates a new rate limiter. It limits the number of requests using two parameters:
   * - capacity: the maximum number of tokens (actions) that can be stored at any given time
   * - seconds: the number of seconds it takes to refill the bucket to its maximum capacity
   *
   * We then can calculate the refillRatePerMs: the number of tokens (actions) that are added to the bucket every
   * millisecond
   *
   * Example:
   * Say we want to allow maximum 60 requests in a period of 5 seconds. We can create a rate limiter with:
   * - capacity: 60
   * - seconds: 5
   * And we will get refillRatePerMs: 60 / (5 * 1000) = 0.012
   *
   * To use:
   * const rateLimiter = new RateLimiter(60, 5);
   * await rateLimiter.consume();
   */
  constructor(
    private readonly capacity: number,
    private readonly seconds: number,
  ) {
    this.tokens = capacity;
    this.refillRatePerMs = capacity / (seconds * 1000);
    this.lastRefillTimestamp = Date.now();
  }

  /**
   * Consumes one token from the rate limiter.
   * If no tokens are available, waits until one is refilled.
   *
   * @returns A promise that resolves once a token is available and consumed.
   */
  async consume(): Promise<void> {
    if (this.attemptConsume()) return;

    await firstValueFrom(
      interval(10).pipe(
        filter(() => this.attemptConsume()),
        first(),
      ),
    );
  }

  private attemptConsume(): boolean {
    this.refill();
    if (this.tokens >= 1) {
      this.tokens -= 1;
      return true;
    }
    return false;
  }

  private refill(): void {
    const now = Date.now();
    const elapsedTime = now - this.lastRefillTimestamp;
    const tokensToAdd = elapsedTime * this.refillRatePerMs;
    this.tokens = Math.min(this.tokens + tokensToAdd, this.capacity);
    this.lastRefillTimestamp = now;
  }
}
