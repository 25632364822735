import { RpcManager } from './rpc.manager';
import { AiAgentId, IntegrationId } from '../../internal-common/src/public-types/communication.public-types';
import { AiContextMetadata } from '../../internal-common/src/public-types/ai-agent.public-types';
import { DocumentTextDataResponse } from '../../internal-common/src/public-types/extraction.public-types';

interface PsConfig {
  integrationId: string;
  identifier: string;
}

interface PsSaveAuthCodeRequest {
  authCode: string;
  personalStorageConfig: PsConfig;
}

interface PsGetAccessTokenRequest {
  personalStorageConfig: PsConfig;
}

/**
 * Response to a request to get the access token for a personal storage service.
 * @category Personal Storage
 */
export interface PsGetAccessTokenResponse {
  /** The access token for the personal storage service. */
  accessToken: string;
  /** The expiration time of the access token. */
  expirationTime: Date;
}

interface PsIndexDocumentOrFolderRequest {
  documentOrFolderId: string;
  metadata?: AiContextMetadata;
  personalStorageConfig: PsConfig;
  agentId: AiAgentId;
}

interface PsListIndexedDocumentsRequest {
  personalStorageConfig: PsConfig;
  agentId: AiAgentId;
  type?: PsDocumentType;
}

interface PsListIndexedDocumentsResponse {
  documents: Array<PsIndexedDocument>;
}

interface PsUnindexDocumentRequest {
  documentId: string;
  personalStorageConfig: PsConfig;
  agentId: AiAgentId;
}

/**
 * The type of a document in personal storage.
 * @category Personal Storage
 */
export type PsDocumentType = 'file' | 'folder';

/**
 * A document indexed for AI processing (as part of the personal storage connector).
 * @category Personal Storage
 */
export interface PsIndexedDocument {
  /** The ID of the document. */
  id: string;
  /** The name of the document. */
  name: string;
  /** The type of the document (file or folder). */
  type: PsDocumentType;
  /** Metadata stored about the document */
  metadata: AiContextMetadata;
  /** The ID of the parent folder of the document. */
  folderDocumentId?: string;
}

interface PsExtractDataFromDocumentRequest {
  documentId: string;
  personalStorageConfig: PsConfig;
}

/**
 * Client to handle personal storage integrations such as Google Drive or Microsoft OneDrive.
 * Provides methods for saving authentication tokens, indexing documents or folders for AI processing,
 * and unindexing documents from AI processing.
 * @category Personal Storage
 */
export class PersonalStorageClient {
  /**
   * Constructs a new PersonalStorageClient instance.
   *
   * @param integrationId - The unique ID of the integration (e.g., Google Drive, Microsoft OneDrive).
   * @param rpcManager - The RPC manager responsible for making API calls.
   * @internal
   */
  constructor(
    private readonly integrationId: IntegrationId,
    private readonly rpcManager: RpcManager,
  ) {}

  /**
   * Saves an authentication token for the personal storage service.
   *
   * @param authCode - The authorization code obtained from the OAuth flow for the personal storage service.
   * @param identifier - A user-provided identifier (usually a user ID) that will be associated with the token.
   * @returns A promise that resolves with the access token and expiration time.
   */
  async saveAuthCode(authCode: string, identifier: string): Promise<PsGetAccessTokenResponse> {
    const request: PsSaveAuthCodeRequest = {
      authCode,
      personalStorageConfig: {
        identifier,
        integrationId: this.integrationId,
      },
    };
    return await this.rpcManager.post<PsGetAccessTokenResponse>('personalStorage/saveAuthCode', request);
  }

  /**
   * Retrieves an access token for the personal storage service.
   *
   * @param identifier - A user-provided identifier (usually a user ID) that is associated with the token.
   * @returns A promise that resolves with the access token and expiration time.
   */
  async getAccessToken(identifier: string): Promise<PsGetAccessTokenResponse> {
    const request: PsGetAccessTokenRequest = {
      personalStorageConfig: {
        identifier,
        integrationId: this.integrationId,
      },
    };
    return await this.rpcManager.post<PsGetAccessTokenResponse>('personalStorage/getAccessToken', request);
  }

  /**
   * Extracts text data from a document.
   * @param documentId - The ID of the document to extract text data from.
   * @param identifier - A user-provided identifier (usually a user ID) that is associated with the document.
   * @returns A promise that resolves with the extracted text data.
   */
  async extractDataFromDocument(documentId: string, identifier: string): Promise<DocumentTextDataResponse> {
    const request: PsExtractDataFromDocumentRequest = {
      documentId,
      personalStorageConfig: {
        identifier,
        integrationId: this.integrationId,
      },
    };
    return await this.rpcManager.post<DocumentTextDataResponse>('personalStorage/extractDataFromDocument', request);
  }

  /**
   * Indexes a document or folder for AI processing.
   *
   * @param documentOrFolderId - The ID of the document or folder to be indexed.
   * @param identifier - A user-provided identifier (usually a user ID) that will be associated with the document.
   * @param agentId - The agent ID for the AI processing configuration.
   * @param metadata - Metadata to include with the index
   * @returns A promise that resolves when the document or folder is successfully indexed.
   */
  async indexDocumentOrFolder(
    documentOrFolderId: string,
    identifier: string,
    agentId: string,
    metadata?: AiContextMetadata,
  ): Promise<void> {
    const request: PsIndexDocumentOrFolderRequest = {
      documentOrFolderId,
      metadata,
      personalStorageConfig: {
        identifier,
        integrationId: this.integrationId,
      },
      agentId: agentId,
    };
    await this.rpcManager.post<void>('personalStorage/indexDocumentOrFolder', request);
  }

  /**
   * Lists all documents or folders that have been indexed for AI processing.
   *
   * @param identifier - A user-provided identifier (usually a user ID) associated with the indexed documents.
   * @param agentId - The AI agent ID used when indexing the documents.
   * @param type - (Optional) The type of items to list: `'file'` or `'folder'`. If omitted, returns both.
   * @returns A promise that resolves with an array of indexed documents or folders.
   */
  async listIndexedDocuments(
    identifier: string,
    agentId: string,
    type?: PsDocumentType,
  ): Promise<Array<PsIndexedDocument>> {
    const request: PsListIndexedDocumentsRequest = {
      personalStorageConfig: {
        identifier,
        integrationId: this.integrationId,
      },
      agentId: agentId,
      type,
    };

    const response = await this.rpcManager.post<PsListIndexedDocumentsResponse>(
      'personalStorage/listIndexedDocuments',
      request,
    );
    return response.documents;
  }

  /**
   * Unindexes a previously indexed document, removing it from AI processing.
   *
   * @param documentId - The ID of the document to be unindexed.
   * @param identifier - A user-provided identifier (usually a user ID) that was associated with the document.
   * @param agentId - The agent ID for the AI processing configuration.
   * @returns A promise that resolves when the document is successfully unindexed.
   */
  async unindexDocument(documentId: string, identifier: string, agentId: string): Promise<void> {
    const request: PsUnindexDocumentRequest = {
      documentId,
      personalStorageConfig: {
        identifier,
        integrationId: this.integrationId,
      },
      agentId: agentId,
    };
    await this.rpcManager.post<void>('personalStorage/unindexDocument', request);
  }
}
