import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject, map, Observable, switchMap } from 'rxjs';
import {
  BundleDataTableColumn,
  BundleDataTableData,
  BundleDataTableRow,
  medianColumnHeader,
  p95ColumnHeader,
} from '../../global/components/bundle-data-table/bundle-data.types';
import { BackendService } from '@squidcloud/console-web/app/backend/backend.service';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';
import { CpApplication } from '@squidcloud/console-common/types/application.types';
import { convertToSquidRegion } from '@squidcloud/console-common/clouds-and-regions';
import { environment } from '@squidcloud/console-web/environments/environment';
import { getApplicationUrl } from '@squidcloud/internal-common/utils/http';
import { BackendMetricPeriodType } from '@squidcloud/console-common/types/metrics.types';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  newErrorsColumn,
  newLinkColumn,
  newMedianColumn,
  newP95Column,
  newSuccessColumn,
  newTextColumn,
} from '@squidcloud/console-web/app/backend/backend-component-utils';

interface OpenApiUrlAndName {
  url: string;
  name: string;
}

@Component({
  templateUrl: './openapi.component.html',
  styleUrls: ['./openapi.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class OpenApiComponent {
  protected readonly bundleDataTableData$: Observable<BundleDataTableData | undefined>;
  protected readonly openApiUrls$: Observable<Array<OpenApiUrlAndName>>;
  protected readonly application$ = this.applicationService.observeCurrentApplication();
  protected readonly periodType$ = new BehaviorSubject<BackendMetricPeriodType>('last-hour');

  constructor(
    private readonly applicationService: ApplicationService,
    private readonly backendService: BackendService,
  ) {
    this.backendService.reportBackendTabHasMetrics(false);

    this.openApiUrls$ = this.application$.pipe(
      switchMap(app => {
        return this.applicationService.observeBundleData().pipe(
          map(bundleData => {
            if (!bundleData || !app) return [];
            const openApiControllersMap = bundleData.openApiControllersMap || {};
            const openApiUrls: Array<OpenApiUrlAndName> = [];
            for (const [controllerName] of Object.entries(openApiControllersMap)) {
              openApiUrls.push({ url: this.getOpenApiSpecUrl(app, controllerName), name: controllerName });
            }
            return openApiUrls;
          }),
        );
      }),
    );

    this.bundleDataTableData$ = this.backendService
      .observeMetricByPeriodType(this.periodType$, takeUntilDestroyed())
      .pipe(
        map(metricsResponse => {
          const metrics = metricsResponse.functionsExecutionCounts?.['openapi'];
          if (!metrics) return undefined;
          const rows: Array<BundleDataTableRow> = metrics.functions.map(metric => {
            const { counts, functionData } = metric;
            const { extras } = functionData;
            const httpMethod = (extras['method'] as string).toUpperCase();
            const specName = extras['name'] as string;
            const url = extras['url'] as string;
            const urlPathStartIdx = url.indexOf('/openapi/');
            const openapiPath = url.substring(urlPathStartIdx);
            const columns: Array<BundleDataTableColumn> = [
              newLinkColumn(url, openapiPath),
              newTextColumn(httpMethod),
              newTextColumn(specName),
              newSuccessColumn(counts),
              newErrorsColumn(counts),
              newMedianColumn(counts),
              newP95Column(counts),
              newTextColumn(functionData.serviceFunctionName),
            ];
            return { columns };
          });

          this.backendService.reportBackendTabHasMetrics(!!rows.length);
          if (!rows.length) return undefined;

          this.backendService.reportBackendTabHasMetrics(!!rows.length);
          if (!rows.length) return undefined;

          return {
            headerRow: [
              'URL',
              'Method',
              'Spec Name',
              { name: 'Executions', align: 'right' },
              { name: 'Errors', align: 'right' },
              medianColumnHeader,
              p95ColumnHeader,
              'Function',
            ],
            rows,
            metrics: metrics.charts,
            showPeriodSelector: true,
          };
        }),
      );
  }

  getOpenApiSpecUrl(application: CpApplication, specName: string): string {
    const regionPrefix = convertToSquidRegion(
      application.cloudId,
      application.region,
      application.shard,
      environment.stage,
    );
    const baseUrl = getApplicationUrl(regionPrefix, application.appId, 'openapi');
    return baseUrl + `/spec${specName !== 'default' ? '-' + specName : ''}.json`;
  }
}
