import { RpcManager } from './rpc.manager';
import { IntegrationId } from '../../internal-common/src/public-types/communication.public-types';

/**
 * Represents a request to upload a file to a specified directory within the storage bucket.
 * @category Storage
 */
export interface StorageFileUploadRequest {
  /** The ID of the integration where the file will be uploaded. */
  integrationId: IntegrationId;
  /** The directory path within the bucket where the file should be stored. */
  dirPathInBucket: string;
  /** Optional expiration time in seconds for the uploaded file. */
  expirationInSeconds?: number;
}

/**
 * Represents a request to retrieve metadata for a specific file stored in the bucket.
 * @category Storage
 */
export interface GetFileMetadataRequest {
  /** The ID of the integration where the file is stored. */
  integrationId: IntegrationId;
  /** The path of the file within the bucket. */
  filePathInBucket: string;
}

/**
 * Represents the metadata details of a file stored in the bucket.
 * @category Storage
 */
export interface GetFileMetadataResponse {
  /** The name of the file. */
  filename: string;
  /** The size of the file in bytes. */
  size: number;
  /** The last modified timestamp of the file. */
  lastModified: Date;
  /** Additional metadata associated with the file. */
  metadata: Record<string, string>;
}

/**
 * Represents a request to generate a temporary download URL for a file.
 * @category Storage
 */
export interface GetDownloadUrlRequest {
  /** The ID of the integration where the file is stored. */
  integrationId: IntegrationId;
  /** The path of the file within the bucket. */
  filePathInBucket: string;
  /** Optional expiration time in seconds for the generated URL. */
  urlExpirationInSeconds?: number;
}

/**
 * Represents the response containing a generated download URL for a file.
 * @category Storage
 */
export interface GetDownloadUrlResponse {
  /** The temporary URL for downloading the file. */
  url: string;
}

/**
 * Represents a request to delete multiple files from the storage bucket.
 * @category Storage
 */
export interface DeleteFilesRequest {
  /** The ID of the integration where the files are stored. */
  integrationId: IntegrationId;
  /** An array of file paths within the bucket to be deleted. */
  filePathsInBucket: Array<string>;
}

/**
 * Represents a request to list the contents of a directory within the storage bucket.
 * @internal
 */
export interface ListDirectoryContentsRequest {
  /** The ID of the integration where the directory is located. */
  integrationId: IntegrationId;
  /** The path of the directory within the bucket. */
  dirPathInBucket: string;
}

/**
 * Represents details of a file within a directory in the storage bucket.
 * @category Storage
 */
export interface FileInDirectory {
  /** The name of the file. */
  filename: string;
  /** The absolute path of the file within the bucket. */
  absoluteFilePathInBucket: string;
  /** The size of the file in bytes. */
  size: number;
  /** The last modified timestamp of the file. */
  lastModified: Date;
}

/**
 * Represents the response containing a list of directories and files within a specified directory in the storage
 * bucket.
 * @category Storage
 */
export interface ListDirectoryContentsResponse {
  /** An array of directory names present in the specified directory. */
  directories: Array<string>;
  /** An array of files present in the specified directory. */
  files: Array<FileInDirectory>;
}

/**
 * StorageClient provides methods for managing files in a storage system (like S3).
 * @category Storage
 */
export class StorageClient {
  /** @internal */
  constructor(
    private readonly integrationId: IntegrationId = 'built_in_storage',
    private readonly rpcManager: RpcManager,
  ) {}

  /**
   * Uploads a file to a specified directory within the bucket.
   *
   * @param dirPathInBucket - The directory path within the bucket where the file should be uploaded.
   * @param file - The file to upload.
   * @param expirationInSeconds - Optional expiration time in seconds for the upload.
   * @returns A promise that resolves to void when the upload is complete.
   */
  async uploadFile(dirPathInBucket: string, file: File, expirationInSeconds?: number): Promise<void> {
    const request: StorageFileUploadRequest = {
      integrationId: this.integrationId,
      dirPathInBucket,
      expirationInSeconds,
    };
    await this.rpcManager.post('storage/uploadFile', request, [file]);
  }

  /**
   * Retrieves metadata for a file located in a specified path within the bucket.
   *
   * @param filePathInBucket - The path of the file within the bucket.
   * @returns A promise that resolves to a `GetFileMetadataResponse` containing the file's metadata.
   */
  async getFileMetadata(filePathInBucket: string): Promise<GetFileMetadataResponse> {
    const request: GetFileMetadataRequest = {
      integrationId: this.integrationId,
      filePathInBucket,
    };
    return await this.rpcManager.post<GetFileMetadataResponse>('storage/getFileMetadata', request);
  }

  /**
   * Generates a URL for downloading a file from a specified path within the bucket.
   *
   * @param filePathInBucket - The path of the file within the bucket for which to generate the download URL.
   * @param urlExpirationInSeconds - Optional expiration time in seconds for the download URL.
   * @returns A promise that resolves to a `GetDownloadUrlResponse` containing the download URL.
   */
  async getDownloadUrl(filePathInBucket: string, urlExpirationInSeconds?: number): Promise<GetDownloadUrlResponse> {
    const request: GetDownloadUrlRequest = {
      integrationId: this.integrationId,
      filePathInBucket,
      urlExpirationInSeconds,
    };
    return await this.rpcManager.post<GetDownloadUrlResponse>('storage/getDownloadUrl', request);
  }

  /**
   * Lists the contents of a directory within the bucket.
   *
   * @param dirPathInBucket - The path of the directory within the bucket.
   * @returns A promise that resolves to a `ListDirectoryContentsResponse` containing the directory contents.
   */
  async listDirectoryContents(dirPathInBucket: string): Promise<ListDirectoryContentsResponse> {
    const request: ListDirectoryContentsRequest = {
      integrationId: this.integrationId,
      dirPathInBucket,
    };
    return await this.rpcManager.post<ListDirectoryContentsResponse>('storage/listDirectoryContents', request);
  }

  /**
   * Deletes a single file from a specified path within the bucket.
   *
   * @param filePathInBucket - The path of the file within the bucket to be deleted.
   * @returns A promise that resolves to void when the file has been deleted.
   */
  async deleteFile(filePathInBucket: string): Promise<void> {
    await this.deleteFiles([filePathInBucket]);
  }

  /**
   * Deletes multiple files from specified paths within the bucket.
   *
   * @param filePathsInBucket - An array of paths for the files within the bucket to be deleted.
   * @returns A promise that resolves to void when all specified files have been deleted.
   */
  async deleteFiles(filePathsInBucket: Array<string>): Promise<void> {
    const request: DeleteFilesRequest = {
      integrationId: this.integrationId,
      filePathsInBucket,
    };
    await this.rpcManager.post('storage/deleteFiles', request);
  }
}
