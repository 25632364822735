import { IntegrationClient } from './integration-client';
import { RpcManager } from './rpc.manager';
import { SecretClient } from './secret.client';
import { SquidRegion } from '../../internal-common/src/public-types/regions.public-types';
import { AppId } from '../../internal-common/src/public-types/communication.public-types';

/**
 * AdminClient provides access to application administrative operations,
 * including integrations and secrets management.
 * @category Platform
 */
export class AdminClient {
  private readonly integrationClient = new IntegrationClient(this.rpcManager, this.region, this.appId);
  private readonly secretClient = new SecretClient(this.rpcManager);

  /** @internal */
  constructor(
    private readonly rpcManager: RpcManager,
    private readonly region: SquidRegion,
    private readonly appId: AppId,
  ) {}

  /**
   * Returns the IntegrationClient for managing integrations.
   */
  integrations(): IntegrationClient {
    return this.integrationClient;
  }

  /**
   * Returns the SecretClient for managing secrets.
   */
  secrets(): SecretClient {
    return this.secretClient;
  }
}
