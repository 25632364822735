import { AiAudioCreateSpeechOptions, AiAudioTranscribeOptions } from './public-types';
import { RpcManager } from './rpc.manager';
import { base64ToFile } from './file-utils';
import {
  AiAudioCreateSpeechRequest,
  AiAudioCreateSpeechResponse,
} from '../../internal-common/src/types/ai-agent.types';

/**
 * AiAudioClient provides methods for AI-driven audio processing,
 * including speech transcription and text-to-speech synthesis.
 * @category AI
 */
export class AiAudioClient {
  /** @internal */
  constructor(private readonly rpcManager: RpcManager) {}

  /**
   * Transcribes the spoken content of an audio file into text.
   *
   * @param file - The audio file to transcribe.
   * @param options - Options specifying the transcription model and behavior.
   * @returns A Promise that resolves to the transcribed text.
   */
  async transcribe(file: File, options: AiAudioTranscribeOptions = { modelName: 'whisper-1' }): Promise<string> {
    return this.rpcManager.post<string>('ai/audio/transcribe', options, [file]);
  }

  /**
   * Converts a text input into a spoken audio file using AI-generated speech.
   *
   * @param input - The text to convert to speech.
   * @param options - Options for customizing speech synthesis (e.g., voice, speed).
   * @returns A Promise that resolves to an audio File containing the synthesized speech.
   */
  async createSpeech(input: string, options: AiAudioCreateSpeechOptions): Promise<File> {
    const request: AiAudioCreateSpeechRequest = { input, options };
    const resp = await this.rpcManager.post<AiAudioCreateSpeechResponse>('ai/audio/createSpeech', request);
    const base64File = resp.base64File;
    return base64ToFile(base64File, `audio.${resp.extension}`, resp.mimeType);
  }
}
