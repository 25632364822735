import { BaseAbility } from '@squidcloud/console-web/app/studio/agent/abilities/base.ability';
import { AbilityConstructorMap, AbilityMap } from '@squidcloud/console-web/app/studio/agent/abilities/factory';
import { assertTruthy } from 'assertic';

const ABILITY_GROUPS = {
  database: {
    name: 'Database',
    description: 'Interact with your database using natural language.',
    icon: 'built_in_db',
  },
  connected_agents: {
    name: 'Your Agents',
    description: 'Connect to your existing AI agents.',
    icon: 'ai_agents',
  },
} as const;

export type AbilityGroup = keyof typeof ABILITY_GROUPS;

export type AbilityMetadata = {
  name?: string;
  description?: string;
  icon: string;
  group?: AbilityGroup;
};

export type AbilityType = keyof AbilityMap;

export const KNOWLEDGE_ABILITIES = ['file', 'text'] as const;
export type KnowledgeAbilityType = (typeof KNOWLEDGE_ABILITIES)[number];

export type AbilityCreateOptionsMap = {
  [K in keyof AbilityMap]: AbilityMap[K] extends BaseAbility<infer TCreateOptions, unknown, unknown>
    ? TCreateOptions
    : never;
};

export type AbilityConnectOptionsMap = {
  [K in keyof AbilityMap]: AbilityMap[K] extends BaseAbility<unknown, infer TConnectOptions, unknown>
    ? TConnectOptions
    : never;
};

export type AbilityEditOptionsMap = {
  [K in keyof AbilityMap]: AbilityMap[K] extends BaseAbility<unknown, unknown, infer TDeleteOptions>
    ? TDeleteOptions
    : never;
};

type KeysWithDefinedOptions<T> = {
  [K in keyof T]: T[K] extends undefined ? never : K;
}[keyof T];

export type AbilityTypeWithCreate = KeysWithDefinedOptions<AbilityCreateOptionsMap>;
export type AbilityTypeWithConnect = KeysWithDefinedOptions<AbilityConnectOptionsMap>;
export type AbilityTypeWithEdit = KeysWithDefinedOptions<AbilityEditOptionsMap>;

export function getAbilityMetadata(type: AbilityType): AbilityMetadata {
  return AbilityConstructorMap[type].metadata;
}

export function isAbilityType(type: string): type is AbilityType {
  return type in AbilityConstructorMap;
}

export function getAbilityType(type: string): AbilityType {
  assertTruthy(isAbilityType(type), `${type} is not an AbilityType`);
  return type as AbilityType;
}

export function getAbilityGroupMetadata(group: AbilityGroup): AbilityMetadata {
  return ABILITY_GROUPS[group];
}

export function isAbilityGroup(group: string): group is AbilityGroup {
  return group in ABILITY_GROUPS;
}

export function getAbilityGroup(type: string): AbilityGroup {
  assertTruthy(isAbilityGroup(type), `${type} is not an AbilityGroup`);
  return type as AbilityGroup;
}
