export enum IntegrationCategory {
  'database' = 'database',
  'storage' = 'storage',
  'queue' = 'queue',
  'api' = 'api',
  'observability' = 'observability',
  'crm' = 'crm',
  'auth' = 'auth',
  'ai' = 'ai',
  'saas' = 'saas',
  'communication' = 'communication',
}
