import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { Alias, ClientRequestId, JoinCondition, Query, SquidDocument } from '../public-types';

/**
 * Defines the state and behavior of an ongoing query, including
 * query dependencies, data streaming, join conditions, and limit
 * underflow handling for dynamic data fetching.
 * @internal
 */
export interface OngoingQuery {
  /** Unique client request identifier for the query. */
  clientRequestId: ClientRequestId;

  /** Query definition specifying filters, conditions, and requested data. */
  query: Query;

  /** All the queries that depend on this query. A.join(B) ==> B depends on A. */
  supportedQueries: Array<OngoingQuery>;

  /** The query this query supports. A.join(B) ==> B supporting A. */
  supportingOngoingQuery?: OngoingQuery;

  /** Whether the first response has arrived. */
  gotInitialResponse: boolean;

  /** Whether the query is ready - relevant for joins. */
  activated: boolean;

  /** The join condition with the supporting ongoing query. */
  joinCondition?: JoinCondition;

  /** Alias used for identifying this query in joins. */
  alias: Alias;

  /** Subject emitting the documents retrieved by the query. */
  dataSubject: BehaviorSubject<Array<SquidDocument> | null>;

  /** Tracks whether the query has been registered with the system. */
  queryRegistered: BehaviorSubject<boolean>;

  /**
   * In case that this query is a parent of another query (that is, the other query is a subset of this query),
   * this query should not be unsubscribed from the server until we registered the child query in the server.
   */
  unsubscribeBlockerCount: BehaviorSubject<number>;

  /** Whether the query should be subscribed to and actively fetch data. */
  subscribe: boolean;

  /**
   * In case of joins, and if this ongoing query is the root, this field emits all the supported observables.
   *
   * For example:
   * `A.join(B, {...some join condition...}).join(C, {...some join condition})`
   * This field will emit `[A.subject.pipe(), B.subject.pipe(), C.subject.pipe()]`. Any new supported queries
   * will be added here.
   */
  allObservables?: ReplaySubject<Array<Observable<DocsAndAlias>>>;

  /** Indicates whether this query has an empty result set in the context of a join. */
  isEmptyForJoin: boolean;

  /**
   * If the query is a supporting query (right side of the join) and the query has '==' condition on the join column,
   * there is no need to emit a new query when additional values are added to the left side of the join
   * since the right side will not change.
   */
  canExpandForJoin: boolean;

  /** Whether this query has completed execution. */
  done: boolean;

  /** Indicates if the query is currently in-flight (waiting for a response). */
  isInFlight: boolean;

  /** Forces fetching fresh results from the server instead of using cached data. */
  forceFetchFromServer: boolean;

  /**
   * If there's a limit, we request `limit + FETCH_BEYOND_LIMIT` documents from the server.
   *
   * - If we got that many documents, that means there may be even more.
   * - If our result set goes below `limit + LIMIT_UNDERFLOW_TRIGGER` (due to local or remote deletions),
   *   we need to resend the query to the server to potentially get more documents.
   * - If the number of documents is less than `limit + FETCH_BEYOND_LIMIT`, that means there are
   *   not that many documents on the server, so we don't need to resend the query regardless of
   *   how small our result size is or becomes.
   */
  limitUnderflowState: LimitUnderflowState;
}

/**
 * Represents the state of a query when handling limit underflows.
 * Determines whether the client should re-fetch data when the number
 * of stored documents drops below a certain threshold.
 * @internal
 */
export enum LimitUnderflowState {
  /**
   * The state is unknown, and the system should determine whether to re-fetch
   * data based on observed conditions.
   */
  UNKNOWN,
  /**
   * Limit underflow handling is disabled. The query will not trigger
   * a re-fetch even if the number of results falls below the threshold.
   */
  DISABLED,
  /**
   * Limit underflow handling is enabled. If the number of documents
   * falls below the defined threshold, the system will attempt to fetch
   * additional results from the server.
   */
  ENABLED,
}

/**
 * Represents a collection of documents associated with a specific alias
 * in a query, typically used in joined query results.
 * @category Database
 */
export interface DocsAndAlias {
  /**
   * An array of documents retrieved as part of the query.
   */
  docs: Array<SquidDocument>;

  /**
   * The alias associated with the documents in a joined query context.
   */
  alias: Alias;
}
