import { assertTruthy, truthy } from 'assertic';
import { cloneDeep, deleteInPath, getInPath, setInPath } from '../utils/object';
import { IntegrationId, SquidDocId } from '../public-types/communication.public-types';
import { DocTimestamp, FieldName, FieldType, SquidDocument } from '../public-types/document.public-types';
import {
  ApplyNumericFnPropertyMutation,
  ApplyStringFnPropertyMutation,
  Mutation,
  PropertyMutation,
  UpdateMutation,
} from '../public-types-backend/mutation.public-context';

/** @internal */
export interface MutateRequest<T = any> {
  integrationId: IntegrationId;
  mutations: Array<Mutation<T>>;
}

/** @internal */
export interface ExecuteMutationsResponse {
  timestamp: DocTimestamp;
  idResolutionMap?: IdResolutionMap;
  afterDocs: Record<SquidDocId, SquidDocument>;
}

/** @internal */
export interface MutateResponse extends Omit<ExecuteMutationsResponse, 'afterDocs'> {
  refreshList: Array<SquidDocId>;
}

/** @internal */
export type IdResolutionMap = Record<SquidDocId, SquidDocId>;

function applyStringFn(
  initialValue: string | undefined,
  propertyMutation: ApplyStringFnPropertyMutation,
): string | undefined {
  switch (propertyMutation.fn) {
    case 'trim':
      if (typeof initialValue !== 'string') return initialValue;
      return initialValue.trim();
    case 'extendString':
      if (initialValue === null || initialValue === undefined) return propertyMutation.value;
      return initialValue + propertyMutation.value;
    default:
      throw new Error('Unknown string function: ' + JSON.stringify(propertyMutation));
  }
}

function applyNumericFn(initialValue: number, propertyMutation: ApplyNumericFnPropertyMutation): number {
  switch (propertyMutation.fn) {
    case 'increment':
      if (initialValue === null || initialValue === undefined) return propertyMutation.value;
      return initialValue + propertyMutation.value;
    default:
      throw new Error('Unknown numeric function: ' + JSON.stringify(propertyMutation));
  }
}

function applyPropertyMutation(property: FieldType, propertyMutation: PropertyMutation): FieldType | undefined {
  switch (propertyMutation.type) {
    case 'applyNumericFn':
      return applyNumericFn(property as number, propertyMutation);
    case 'applyStringFn':
      return applyStringFn(property as string, propertyMutation);
    case 'update':
      return typeof propertyMutation.value === 'object' ? cloneDeep(propertyMutation.value) : propertyMutation.value;
    case 'removeProperty':
      return undefined;
    default:
      throw new Error('Unknown property mutation type: ' + JSON.stringify(propertyMutation));
  }
}

/**
 * Sorts the update mutation properties in the order the properties should be applied. If the update mutation has
 * updates for both 'a' and 'a.b', the update should be applied first to 'a' and then to 'a.b'.
 * @internal
 */
export function sortUpdateMutationProperties(
  updateMutation: UpdateMutation,
): Array<[FieldName, Array<PropertyMutation>]> {
  return Object.entries(updateMutation.properties).sort(([propA], [propB]) => {
    const propADots = propA.split('.').length;
    const propBDots = propB.split('.').length;
    return propADots - propBDots;
  }) as Array<[FieldName, Array<PropertyMutation>]>;
}

/** @internal */
export function mergeMutations(mutationA: Mutation, mutationB: Mutation): Mutation {
  if (mutationB.type === 'insert') return mutationB;
  if (mutationB.type === 'delete') return mutationB;
  // At this point mutationB.type has to be 'update'
  if (mutationA.type === 'delete') return mutationA;
  assertTruthy(mutationB.type === 'update', 'Invalid mutation type');

  if (mutationA.type === 'update') return mergeUpdateMutations(mutationA, mutationB);

  const result = cloneDeep(mutationA);
  for (const [fieldName, propertyMutationsAr] of sortUpdateMutationProperties(mutationB)) {
    const propertyMutations = propertyMutationsAr as Array<PropertyMutation>;
    for (const propertyMutation of propertyMutations) {
      const value = applyPropertyMutation(getInPath(result.properties, fieldName) as FieldType, propertyMutation);
      if (value === undefined) {
        deleteInPath(result.properties, fieldName);
      } else {
        setInPath(result.properties, fieldName, value);
      }
    }
  }

  return result;
}

function mergeUpdateMutations(mutationA: UpdateMutation, mutationB: UpdateMutation): UpdateMutation {
  const result = cloneDeep(mutationA);
  mutationB = cloneDeep(mutationB);

  for (const [aPropName] of sortUpdateMutationProperties(result)) {
    const aPropNameDots = aPropName.split('.').length;
    const isOverriddenByMutationB = Object.entries(mutationB.properties).some(([bPropName]) => {
      return aPropName.startsWith(bPropName + '.') && aPropNameDots > bPropName.split('.').length;
    });
    if (isOverriddenByMutationB) {
      delete result.properties[aPropName];
    }
  }

  for (const [bPropName, bPropValues] of sortUpdateMutationProperties(mutationB)) {
    result.properties[bPropName] = reducePropertyMutations([...(result.properties[bPropName] || []), ...bPropValues]);
  }
  return result;
}

/** @internal */
function reducePropertyMutations(mutations: PropertyMutation[]): PropertyMutation[] {
  let i = 0;
  while (i + 1 < mutations.length) {
    const mergedMutation = mergePropertyMutations(mutations[i], mutations[i + 1]);
    if (mergedMutation) {
      mutations.splice(i, 2, mergedMutation);
    } else {
      ++i;
    }
  }
  return mutations;
}

/**
 * Returns a single PropertyMutation object which is a reduced form of 2 PropertyMutation objects. If they cannot be
 * reduced, then return null. This happens when one of the inputs is a string trim mutation.
 * @internal
 */
function mergePropertyMutations(mutationA: PropertyMutation, mutationB: PropertyMutation): PropertyMutation | null {
  if (mutationB.type === 'removeProperty' || mutationB.type === 'update') return mutationB;
  if (mutationB.type === 'applyNumericFn') {
    assertTruthy(mutationB.fn === 'increment', 'Unrecognized applyNumericFn');
    if (mutationA.type === 'applyNumericFn') {
      assertTruthy(mutationA.fn === 'increment', 'Unrecognized applyNumericFn');
      return {
        type: 'applyNumericFn',
        fn: 'increment',
        value: mutationA.value + mutationB.value,
      };
    }
    if (mutationA.type === 'update') {
      return {
        type: 'update',
        value: mutationA.value + mutationB.value,
      };
    }
    return mutationB;
  }
  if (mutationB.fn === 'extendString') {
    if (mutationA.type === 'update') {
      return {
        type: 'update',
        value: mutationA.value + mutationB.value,
      };
    }
    if (mutationA.type === 'applyStringFn') {
      return mutationA.fn === 'trim'
        ? null
        : {
            type: 'applyStringFn',
            fn: 'extendString',
            value: mutationA.value + mutationB.value,
          };
    }
    return mutationB;
  }
  return null;
}

/** @internal */
export function applyUpdateMutation<T extends SquidDocument>(doc: T, updateMutation: UpdateMutation<T>): T | undefined {
  if (!doc) return undefined;
  const result = { ...doc };
  const entries = sortUpdateMutationProperties(updateMutation);
  for (const [fieldName, propertyMutationsAr] of entries) {
    const propertyMutations = propertyMutationsAr as Array<PropertyMutation>;
    for (const propertyMutation of propertyMutations) {
      const value = applyPropertyMutation(getInPath(result, fieldName) as FieldType, propertyMutation);
      if (value === undefined) {
        deleteInPath(result, fieldName);
      } else {
        setInPath(result, fieldName, value);
      }
    }
  }
  return result;
}

/**
 * Reduces the list of mutations such that each document will have a single mutation. If for example there are multiple
 * updates to the same document, those will be merged and a single update will be returned.
 * @internal
 */
export function reduceMutations(mutations: Array<Mutation<unknown>>): Array<Mutation<unknown>> {
  // Group mutations by integrationId, collectionName, and docId
  const groupedMutations: { [key: string]: Mutation<unknown>[] } = {};

  for (const mutation of mutations) {
    const key = `${mutation.squidDocIdObj.integrationId}/${mutation.squidDocIdObj.collectionName}/${mutation.squidDocIdObj.docId}`;
    if (!groupedMutations[key]) {
      groupedMutations[key] = [];
    }
    groupedMutations[key].push(mutation);
  }

  // Reduce each group of mutations
  const result: Array<Mutation<unknown>> = [];

  for (const key in groupedMutations) {
    const mergedMutation = groupedMutations[key].reduce((mutationA, mutationB) => {
      return truthy(mergeMutations(mutationA, mutationB), 'Merge result cannot be null');
    });
    result.push(mergedMutation);
  }

  return result;
}
