@let isExpanded = expandedObs | async;
@let agent = agentAndContexts$ | async;

<div class="flyout">
  @if (isExpanded) {
    <div class="overlay" (click)="handleClose()"></div>
  }
  <div class="outer" [ngClass]="{ expanded: isExpanded }">
    <div class="inner">
      <div class="header">
        <h2>Add Agent Abilities</h2>
        <button class="close" (click)="handleClose()" [attr.data-testid]="'agent-flyout-close-button'">
          <mat-icon class="close_icon" svgIcon="close_icon" />
        </button>
      </div>
      @if (agent) {
        <div class="categories">
          @for (section of list; track section.name) {
            <abilities-category [title]="section.name" [tooltip]="section.tooltip">
              <div class="cards">
                @for (ability of section.abilities; track ability) {
                  <abilities-card
                    [type]="ability"
                    (add)="connect.emit({ type: ability, options: undefined })"
                    [attr.data-testid]="'ability-section-' + ability"
                    addText="Add Knowledge"
                  />
                }
              </div>
            </abilities-category>
          }
          @for (entry of getEntries(eligibleConnectorsByCategory); track entry[0]) {
            <abilities-category [title]="getCategoryName(entry[0])" [tooltip]="getTooltipByCategoryName(entry[0])">
              <div class="cards">
                @for (section of getEntries(entry[1]); track section[0]) {
                  @if (isAbilityGroup(section[0])) {
                    @let existingCount = getExistingConnectorsByType(section[0]).length;

                    <abilities-card [type]="section[0]" [count]="existingCount">
                      @if (existingCount) {
                        <abilities-category alt="existing" title="Existing Connectors">
                          @for (integration of getExistingConnectorsByType(section[0]); track integration.id) {
                            @if (isAbilityType(integration.type)) {
                              <abilities-card
                                [type]="integration.type"
                                [name]="integration.id"
                                alt="existing"
                                (add)="
                                  connect.emit({
                                    type: integration.type,
                                    options: { integrationType: integration.type, integrationId: integration.id },
                                  })
                                "
                                [addText]="'Add ' + getAbilityGroupMetadata(section[0]).name"
                              />
                            }
                          }
                        </abilities-category>
                      }
                      <abilities-category
                        alt="new"
                        [title]="'Add New ' + getAbilityGroupMetadata(section[0]).name"
                        [expanded]="!existingCount"
                      >
                        <div class="cards">
                          @for (connector of section[1]; track connector) {
                            <abilities-card
                              [type]="connector"
                              alt="new"
                              (add)="create.emit({ type: connector, options: { integrationType: connector } })"
                              [addText]="
                                'Add ' + (existingCount ? 'New ' : '') + getAbilityGroupMetadata(section[0]).name
                              "
                            >
                            </abilities-card>
                          }
                        </div>
                      </abilities-category>
                    </abilities-card>
                  } @else if (isAbilityType(section[0])) {
                    @for (connector of section[1]; track connector) {
                      @let existingCount = getExistingConnectorsByType(connector).length;

                      <abilities-card
                        [type]="connector"
                        (add)="create.emit({ type: connector, options: { integrationType: connector } })"
                        [count]="existingCount"
                        [addText]="'Add ' + (existingCount ? 'New ' : '') + 'Connector'"
                      >
                        @if (getExistingConnectorsByType(connector).length) {
                          <abilities-category alt="existing" title="Existing Connectors">
                            <div class="cards">
                              @for (integration of getExistingConnectorsByType(section[0]); track integration.id) {
                                @if (isAbilityType(integration.type)) {
                                  <abilities-card
                                    [type]="integration.type"
                                    [name]="integration.id"
                                    alt="existing"
                                    (add)="
                                      connect.emit({
                                        type: integration.type,
                                        options: {
                                          integrationType: integration.type,
                                          integrationId: integration.id,
                                        },
                                      })
                                    "
                                    addText="Add Connector"
                                  />
                                }
                              }
                            </div>
                          </abilities-category>
                        }
                      </abilities-card>
                    }
                  }
                }
              </div>
            </abilities-category>
          }
          @if (eligibleAiFunctions.length) {
            <abilities-category title="AI Functions"
                                tooltip="AI Functions are built-in tools and actions that augment your agent’s capabilities.">
              <div class="cards">
                @for (metadata of eligibleAiFunctions; track metadata.serviceFunction) {
                  <abilities-card
                    type="ai_function"
                    [name]="getFunctionNameFromServiceFunctionName(metadata.serviceFunction)"
                    [description]="metadata.description"
                    (add)="
                      connect.emit({
                        type: 'ai_function',
                        options: { serviceFunction: metadata.serviceFunction },
                      })
                    "
                    addText="Add AI Function"
                  />
                }
              </div>
            </abilities-category>
          }
          @if (eligibleConnectedAgentIds.length) {
            <abilities-category title="Agents"
                                tooltip="Connect to your other agents to handle specialized or domain-specific tasks for sophisticated solutions.">
              <abilities-card type="connected_agents" title="Your Agents" [count]="eligibleConnectedAgentIds.length">
                <abilities-category alt="existing" title="Existing Agents">
                  <div class="cards">
                    @for (agentId of eligibleConnectedAgentIds; track agentId) {
                      <abilities-card
                        type="connected_agent"
                        alt="existing"
                        [name]="agentId"
                        [description]="getAgentDescription(agentId)"
                        (add)="connect.emit({ type: 'connected_agent', options: { connectedAgentId: agentId } })"
                        addText="Connect Agent"
                      />
                    }
                  </div>
                </abilities-category>
              </abilities-card>
            </abilities-category>
          }
        </div>
      }
    </div>
  </div>
</div>
