import { Injectable } from '@angular/core';
import { DocumentReference, Squid } from '@squidcloud/client';
import { BehaviorSubject, filter, firstValueFrom, NEVER, Observable, of, shareReplay, switchMap, take } from 'rxjs';
import { AccountService } from '../account/account.service';
import { Router } from '@angular/router';
import { OrganizationService } from '../organization/organization.service';
import { ApplicationService } from '../application/application.service';
import { CpApplication, QuickStartProgress } from '@squidcloud/console-common/types/application.types';
import { CpUser } from '@squidcloud/console-common/types/account.types';
import { isAuthIntegration } from '@squidcloud/internal-common/types/integrations/schemas';

export interface QuickStartTask {
  id: TaskId;
  title: string;
  description: string;
  documentationLink?: string;
  completionDate?: Date;
  buttonText: string;
  onClick: () => void | Promise<void>;
}

export type TaskId =
  | 'createOrganization'
  | 'createApplication'
  | 'backendInitialized'
  | 'connectedAuthenticationProvider'
  | 'secureYourData';

@Injectable({ providedIn: 'root' })
export class QuickStartService {
  private expandedSubject = new BehaviorSubject<boolean>(false);
  private readonly quickStartCollection = this.squid.collection<QuickStartProgress>('quick-start');
  private readonly tasks: Array<QuickStartTask> = [
    {
      id: `createOrganization`,
      title: `Create Organization`,
      description: `Create your first Squid organization`,
      buttonText: `Create Organization`,
      onClick: (): void => {
        this.router.navigateByUrl('/onboarding/organizations/add').then();
      },
    },
    {
      id: `createApplication`,
      title: `Create Application`,
      description: `Create your first Squid application`,
      buttonText: `Create Application`,
      onClick: (): void => {
        const organizationId = this.organizationService.getCurrentOrganizationOrFail().id;
        this.router.navigateByUrl(`/onboarding/organization/${organizationId}/applications/add`).then();
      },
    },
    {
      id: `backendInitialized`,
      title: `Initialize Backend Project`,
      description: `Create a backend project for your application then enhance it with backend functions.`,
      buttonText: `Initialize Backend`,
      documentationLink: 'https://docs.getsquid.ai/docs/backend/',
      onClick: (): void => {
        this.applicationService.showInitializeBackendDialog();
      },
    },
    {
      id: `connectedAuthenticationProvider`,
      title: `Connect Auth Provider`,
      description: `Connect an authentication provider to your application to enable authentication in Squid.`,
      buttonText: `Connect Auth Provider`,
      documentationLink: 'https://docs.getsquid.ai/docs/security/authentication/',
      onClick: (): void => {
        this.router.navigateByUrl('/application/dialog/auth').then();
      },
    },

    {
      id: `secureYourData`,
      title: `Secure Your Data`,
      description: `Create security rules to secure your data`,
      buttonText: `Secure Data`,
      documentationLink: 'https://docs.getsquid.ai/docs/security/security-rules/',
      onClick: (): void => {
        this.router.navigateByUrl('/application/backend/securityRules').then();
      },
    },
  ];

  private readonly tasksObs: Observable<Array<QuickStartTask>> = this.accountService.observeUser().pipe(
    switchMap((user: CpUser | undefined): Observable<Array<DocumentReference<QuickStartProgress>>> => {
      if (!user) return NEVER;
      return this.quickStartCollection.query().eq('userId', user.id).snapshots();
    }),
    switchMap((progress): Observable<Array<QuickStartTask>> => {
      const progressMap: Record<string, QuickStartProgress> = {};
      for (const item of progress.map(p => p.data)) {
        progressMap[item.taskId] = item;
      }

      return of(
        this.tasks.map(task => {
          const progress = progressMap[task.id];
          return {
            ...task,
            completionDate: progress?.completionDate,
          };
        }),
      );
    }),
    shareReplay(1),
  );

  constructor(
    private readonly squid: Squid,
    private readonly accountService: AccountService,
    private readonly router: Router,
    private readonly organizationService: OrganizationService,
    private readonly applicationService: ApplicationService,
  ) {
    this.handleSquidStartTasksCompletion();
  }

  observeTasks(): Observable<Array<QuickStartTask>> {
    return this.tasksObs;
  }

  toggleExpanded(): void {
    this.expandedSubject.next(!this.expandedSubject.value);
  }

  observeExpanded(): Observable<boolean> {
    return this.expandedSubject.asObservable();
  }

  async markTaskComplete(taskId: TaskId): Promise<void> {
    // Check if already marked as complete
    const tasks = await firstValueFrom(this.observeTasks());
    if (tasks.find(t => t.id === taskId)?.completionDate) return;

    const userId = this.accountService.getUserOrFail().id;
    const id = `${userId}_${taskId}`;
    const taskProgress: QuickStartProgress = {
      id,
      taskId,
      completionDate: new Date(),
      userId,
    };
    await this.quickStartCollection.doc(id).insert(taskProgress);
  }

  taskClicked(task: QuickStartTask): void {
    task.onClick();
    this.toggleExpanded();
  }

  private handleSquidStartTasksCompletion(): void {
    this.maybeMarkCreateOrgTaskAsComplete();
    this.maybeMarkCreateAppTaskAsComplete();
    this.maybeMarkInitializeBackendTaskAsComplete();
    this.maybeMarkAuthProviderTaskAsComplete();
    this.maybeMarkSecurityRulesTaskAsComplete();
  }

  private maybeMarkCreateOrgTaskAsComplete(): void {
    this.organizationService
      .observeCurrentOrganization()
      .pipe(filter(Boolean), take(1))
      .subscribe(() => void this.markTaskComplete('createOrganization'));
  }

  private maybeMarkCreateAppTaskAsComplete(): void {
    this.applicationService
      .observeCurrentApplication()
      .pipe(filter(Boolean), take(1))
      .subscribe(() => void this.markTaskComplete('createApplication'));
  }

  private maybeMarkInitializeBackendTaskAsComplete(): void {
    this.observeAllApplications().subscribe(apps => {
      const app = apps.find(app => app.backendInitialized);
      if (app) {
        void this.markTaskComplete('backendInitialized');
      }
    });
  }

  private maybeMarkAuthProviderTaskAsComplete(): void {
    this.observeAllApplications().subscribe(apps => {
      const appWithAuthIntegration = apps.find(app =>
        Object.values(app.integrations || {}).some(i => isAuthIntegration(i)),
      );

      if (appWithAuthIntegration) {
        void this.markTaskComplete('connectedAuthenticationProvider');
      }
    });
  }

  private maybeMarkSecurityRulesTaskAsComplete(): void {
    this.observeAllApplications().subscribe(apps => {
      const app = apps.find(app => {
        const bundleData = app.bundleData;
        if (!bundleData) return false;
        return Object.values(bundleData.databases || {}).some(db => {
          const topLevelSecurityRules = Object.values(db.security || {}).length > 0;
          const collectionLevelSecurityRules = Object.values(db.collections || {}).some(collection => {
            const collectionSecurityRules = Object.values(collection.security || {}).length > 0;
            return topLevelSecurityRules || collectionSecurityRules;
          });
          return topLevelSecurityRules || collectionLevelSecurityRules;
        });
      });

      if (app) {
        void this.markTaskComplete('secureYourData');
      }
    });
  }

  private observeAllApplications(): Observable<CpApplication[]> {
    return this.applicationService.observeAllApplications().pipe(filter(apps => apps.length > 0));
  }
}
