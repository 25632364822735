import { AiGenerateImageOptions } from './public-types';
import { RpcManager } from './rpc.manager';
import { AiGenerateImageRequest } from '../../internal-common/src/types/ai-agent.types';

/**
 * AiImageClient provides methods for AI-powered image processing,
 * such as generating images from text prompts and removing backgrounds.
 * @category AI
 */
export class AiImageClient {
  /** @internal */
  constructor(private readonly rpcManager: RpcManager) {}

  /**
   * Generates an image based on a given text prompt and options.
   *
   * @param prompt - A natural language description of the desired image.
   * @param options - Configuration options for image generation (e.g., size, style).
   * @returns A Promise that resolves to a base64-encoded image string.
   */
  async generate(prompt: string, options: AiGenerateImageOptions): Promise<string> {
    const request: AiGenerateImageRequest = {
      prompt,
      options,
    };
    return await this.rpcManager.post<string>('ai/image/generate', request);
  }

  /**
   * Removes the background from an image file.
   *
   * @param file - The image file to process.
   * @returns A Promise that resolves to a base64-encoded image string with the background removed.
   */
  async removeBackground(file: File): Promise<string> {
    return await this.rpcManager.post<string>('ai/image/removeBackground', {}, [file]);
  }
}
