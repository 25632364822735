import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppId, ENVIRONMENT_IDS, EnvironmentId, Squid } from '@squidcloud/client';
import { convertToSquidRegion, Environment } from '@squidcloud/console-common/clouds-and-regions';
import { CpApplication } from '@squidcloud/console-common/types/application.types';
import { OrganizationId } from '@squidcloud/console-common/types/organization.types';
import { callBackendExecutable } from '@squidcloud/console-common/utils/console-backend-executable';
import { ShowEnvVarsDocComponent } from '@squidcloud/console-web/app/backend/show-env-vars-doc/show-env-vars-doc.component';
import { replaceInUrl } from '@squidcloud/console-web/app/global/utils/url';
import { waitForAsyncUpdate } from '@squidcloud/console-web/app/utils/squid-utils';
import { environment } from '@squidcloud/console-web/environments/environment';
import { ApplicationBundleData } from '@squidcloud/internal-common/types/bundle-data.types';
import { appIdWithEnvironmentId, parseAppId } from '@squidcloud/internal-common/types/communication.types';
import { TestDataConnectionResponse } from '@squidcloud/internal-common/types/integrations/database.types';
import {
  BaseIntegrationConfig,
  GraphQLIntegrationConfig,
} from '@squidcloud/internal-common/types/integrations/schemas';
import { isEqual } from '@squidcloud/internal-common/utils/object';
import { assertTruthy, truthy } from 'assertic';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { CreateEnvFileDocComponent } from '../backend/create-env-file-doc/create-env-file-doc.component';
import { DeployBackendDocComponent } from '../backend/deploy-backend-doc/deploy-backend-doc.component';
import { InitializeBackendTutorialComponent } from '../backend/initialize-backend-tutorial/initialize-backend-tutorial.component';
import { GlobalUiService } from '../global/services/global-ui.service';
import { LocalStorageService } from '../global/services/local-storage.service';
import { OrganizationService } from '../organization/organization.service';

@Injectable({ providedIn: 'root' })
export class ApplicationService {
  // 'undefined' means we don't know if there's a current app yet, null means there's no app.
  private readonly currentApplicationSubject = new BehaviorSubject<CpApplication | undefined | null>(undefined);
  private readonly currentEnvironmentObs = this.currentApplicationSubject.pipe(
    map(app => {
      if (!app) return 'dev';
      return parseAppId(app.appId).environmentId;
    }),
  );

  private readonly applicationCollection = this.squid.collection<CpApplication>('application');

  private isLoadingApplications$ = new BehaviorSubject<boolean>(false);

  /** List of all user applications from all user organizations. */
  private allApplicationsObs: Observable<Array<CpApplication>> = this.organizationService.observeOrganizations().pipe(
    map((organizations): string[] => organizations?.map(o => o.id).sort() || []),
    distinctUntilChanged((prev, current) => isEqual(prev, current)),
    switchMap(organizationIds => {
      if (!organizationIds?.length) return of([]);
      this.isLoadingApplications$.next(true);
      return this.applicationCollection
        .query()
        .in(`organizationId`, organizationIds)
        .dereference()
        .snapshots()
        .pipe(
          tap({
            next: () => this.isLoadingApplications$.next(false),
            error: () => this.isLoadingApplications$.next(false),
          }),
          map(appList =>
            appList
              .map(app => {
                return this.transformBundleDataInApp(app);
              })
              .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
          ),
        );
    }),
    shareReplay(1),
  );

  constructor(
    private readonly organizationService: OrganizationService,
    private readonly squid: Squid,
    private readonly router: Router,
    private readonly localStorageService: LocalStorageService,
    private readonly globalUiService: GlobalUiService,
  ) {
    // Listen for updates to the user's current application.
    this.allApplicationsObs.subscribe(async applications => {
      const currentApplication = this.currentApplicationSubject.value;
      if (!currentApplication) return;

      const updatedApplication = applications.find(a => a.appId === currentApplication.appId);
      if (!updatedApplication) {
        // If the current application is deleted, navigate back to the index route.
        await this.router.navigate(['']);
      } else {
        this.currentApplicationSubject.next(updatedApplication || null);
      }
    });
  }

  observeApplicationsForCurrentOrganization(): Observable<Array<CpApplication> | undefined> {
    return combineLatest([this.allApplicationsObs, this.organizationService.observeCurrentOrganization()]).pipe(
      map(([applications, organization]) => {
        if (!organization) return undefined;
        return applications.filter(app => app.organizationId === organization.id);
      }),
    );
  }

  /** Returns list of all user applications from all user organizations. */
  async getAllApplications(): Promise<Array<CpApplication>> {
    await firstValueFrom(this.isLoadingApplications$.pipe(filter(l => !l)));
    return firstValueFrom(this.allApplicationsObs);
  }

  /** Returns an array of all applications of all organizations the current user is member of. */
  observeAllApplications(): Observable<Array<CpApplication>> {
    return this.allApplicationsObs;
  }

  observeCurrentEnvironmentId(): Observable<EnvironmentId> {
    return this.currentEnvironmentObs;
  }

  observeApplicationInitialized(): Observable<boolean> {
    return this.currentApplicationSubject.pipe(map(app => !!app || app === null));
  }

  observeBundleData(): Observable<ApplicationBundleData | undefined | null> {
    return this.observeCurrentApplication().pipe(map(() => this.bundleData));
  }

  get bundleData(): ApplicationBundleData | undefined | null {
    const application = this.getCurrentApplication();
    if (!application) return undefined;
    return application.bundleData ?? null;
  }

  async switchApplication(appId: AppId | undefined): Promise<void> {
    if (appId && this.currentApplicationSubject.value?.appId === appId) return;
    const application = appId ? await this.refreshApplication(appId) : null;
    this.localStorageService.setItem('currentApplicationId', appId);
    if (application) {
      this.transformBundleDataInApp(application);
    }
    this.currentApplicationSubject.next(application);
  }

  async refreshApplication(appId: string): Promise<CpApplication | null> {
    return (await this.applicationCollection.doc(appId).snapshot()) || null;
  }

  getCurrentApplication(): CpApplication | undefined | null {
    return this.currentApplicationSubject.value;
  }

  getCurrentApplicationOrFail(): CpApplication {
    return truthy(this.currentApplicationSubject.value, 'NO_APPLICATION_SELECTED');
  }

  observeCurrentApplication(): Observable<CpApplication | undefined> {
    return this.currentApplication$;
  }

  get currentApplication$(): Observable<CpApplication | undefined> {
    return this.currentApplicationSubject.asObservable().pipe(
      filter(app => app !== undefined),
      map(app => app || undefined),
    );
  }

  async getApiKey(): Promise<string> {
    const application = this.getCurrentApplicationOrFail();
    return this.getApiKeyByAppId(application.appId);
  }

  async getApiKeyByAppId(appId: AppId): Promise<string> {
    return truthy(await callBackendExecutable(this.squid, 'getAppApiKey', appId), 'API_KEY_NOT_FOUND');
  }

  async regenerateApiKey(): Promise<string> {
    const { appId } = this.getCurrentApplicationOrFail();
    return await this.squid.executeFunction<string>('generateApiKey', appId);
  }

  async testDataConnection(config: BaseIntegrationConfig): Promise<TestDataConnectionResponse> {
    const { appId } = this.getCurrentApplicationOrFail();
    return await callBackendExecutable(this.squid, 'testDataConnection', { applicationId: appId, config });
  }

  async testGraphQLConnection(config: GraphQLIntegrationConfig): Promise<TestDataConnectionResponse> {
    const { appId } = this.getCurrentApplicationOrFail();
    return await callBackendExecutable(this.squid, 'testGraphQLConnection', { applicationId: appId, config });
  }

  async createApplication(name: string, squidEnvironment: Environment): Promise<string> {
    const org = this.organizationService.getCurrentOrganizationOrFail();

    // Ensure locally created applications use the `local` region.
    if (environment.stage === 'local') {
      squidEnvironment.cloudRegion = 'local';
    }

    const { appId } = await callBackendExecutable(this.squid, 'createApplication', {
      organizationId: org.id,
      name,
      cloudId: squidEnvironment.cloudId,
      region: squidEnvironment.cloudRegion,
      shard: squidEnvironment.shard,
    });

    const awaitedAppIds = [appId, appIdWithEnvironmentId(appId, 'dev')];
    await waitForAsyncUpdate(
      this.observeAllApplications().pipe(
        filter(apps => awaitedAppIds.every(appId => apps.some(app => app.appId === appId))),
      ),
      'createApplication',
    );
    return appId;
  }

  async updateApplicationName(appId: AppId, name: string): Promise<void> {
    const nameToSearch = name.toLowerCase().trim();
    const currentApp = this.getCurrentApplicationOrFail();
    if (currentApp.name.toLowerCase().trim() === nameToSearch) return;
    const allApplications = await firstValueFrom(this.observeApplicationsForCurrentOrganization());
    const foundApp = allApplications?.find(app => app.name.toLowerCase().trim() === nameToSearch);
    assertTruthy(!foundApp, 'APP_ALREADY_EXISTS');

    await this.squid.runInTransaction(async txId => {
      for (const environmentId of ENVIRONMENT_IDS) {
        const appIdToUse = appIdWithEnvironmentId(appId, environmentId);
        await this.applicationCollection.doc(appIdToUse).update({ name }, txId);
      }
    });
  }

  async setKeepTenantAwaken(applicationId: AppId, keepTenantAwaken: boolean): Promise<void> {
    await callBackendExecutable(this.squid, 'setKeepTenantAwaken', { applicationId, keepTenantAwaken });
  }

  async isKeepTenantAwaken(applicationId: AppId): Promise<boolean> {
    return await callBackendExecutable(this.squid, 'isKeepTenantAwaken', applicationId);
  }

  async deleteApplication(appId: AppId): Promise<void> {
    this.verifyAdminPermissions();
    await callBackendExecutable(this.squid, 'deleteApplication', appId);
  }

  async getApplicationSquid(): Promise<Squid> {
    const currentApp = this.getCurrentApplicationOrFail();
    const { cloudId, region, shard } = currentApp;
    const apiKey = await this.getApiKey();
    const parsedAppId = parseAppId(currentApp.appId);
    return Squid.getInstance({
      appId: parsedAppId.appId,
      environmentId: parsedAppId.environmentId,
      region: convertToSquidRegion(cloudId, region, shard, environment.stage),
      apiKey,
    });
  }

  async getApplicationSquidByAppId(appId: AppId): Promise<Squid> {
    const app = truthy(await this.applicationCollection.doc(appId).snapshot(), 'APP_NOT_FOUND');
    const apiKey = await this.getApiKeyByAppId(appId);
    const { cloudId, region, shard } = app;
    const parsedAppId = parseAppId(app.appId);
    return Squid.getInstance({
      appId: parsedAppId.appId,
      environmentId: parsedAppId.environmentId,
      region: convertToSquidRegion(cloudId, region, shard, environment.stage),
      apiKey,
    });
  }

  async switchApplicationUrl(appId: AppId): Promise<void> {
    if (appId === this.getCurrentApplication()?.appId) return;
    const currentUrl = this.router.url;

    if (!currentUrl.includes('/application/')) {
      await this.router.navigate(['/application', appId]);
    } else {
      const { url, queryParams } = replaceInUrl(currentUrl, { application: appId });
      await this.router.navigate([url], { queryParams });
    }
  }

  async switchEnvironmentUrl(appId: AppId, environmentId: EnvironmentId): Promise<void> {
    const appIdToUse = appIdWithEnvironmentId(appId, environmentId);
    await this.switchApplicationUrl(appIdToUse);
  }

  showInitializeBackendDialog(): void {
    this.showEnvironmentRelatedDialog(
      [
        `To initialize your backend you need to switch to your dev environment.`,
        `A detailed explanation of environments can be found <a class="link" target="_blank" href="https://docs.getsquid.ai/docs/console/environments/">here</a>.`,
      ],
      () => {
        this.globalUiService.showDocWithComponentDialog({
          title: 'Initialize Backend Project',
          component: InitializeBackendTutorialComponent,
        });
      },
    );
  }

  showCreateEnvFileDialog(): void {
    this.showEnvironmentRelatedDialog(
      [
        `To create a .env file you need to switch to your dev environment.`,
        `A detailed explanation of environments can be found <a class="link" target="_blank" href="https://docs.getsquid.ai/docs/console/environments/">here</a>.`,
      ],
      () => {
        this.globalUiService.showDocWithComponentDialog({
          title: 'Create .env file',
          component: CreateEnvFileDocComponent,
        });
      },
    );
  }

  showEnvironmentVariablesDialog(): void {
    this.globalUiService.showDocWithComponentDialog({
      title: 'Environment variables',
      component: ShowEnvVarsDocComponent,
    });
  }

  showDeployBackendDialog(): void {
    this.globalUiService.showDocWithComponentDialog({
      title: 'Deploy your backend',
      component: DeployBackendDocComponent,
    });
  }

  private transformBundleDataInApp(app: CpApplication): CpApplication {
    if (app.bundleData?.openApiControllers) {
      const bundleData = app.bundleData;
      bundleData.openApiControllersMap = { default: app.bundleData.openApiControllers };
      app.bundleData.openApiControllers = undefined;
    }
    return app;
  }

  private verifyAdminPermissions(): void {
    const role = this.organizationService.getMyRoleInCurrentOrg();
    assertTruthy(role === 'ADMIN', 'PERMISSION_DENIED');
  }

  private showEnvironmentRelatedDialog(textLines: Array<string>, onSubmit: () => void): void {
    const app = this.getCurrentApplicationOrFail();
    const parsedAppId = parseAppId(app.appId);
    if (parsedAppId.environmentId !== 'dev') {
      this.globalUiService
        .showDialogWithForm({
          title: 'Arrrr... ',
          textLines,
          submitButtonText: 'Switch to dev',
          submitButtonTestId: 'switch-to-dev-button',
          autoFocus: false,
          onSubmit: async () => {
            await this.switchEnvironmentUrl(parsedAppId.appId, 'dev');
            onSubmit();
          },
          formElements: [],
        })
        .then();
      return;
    }
    onSubmit();
  }

  async getApplicationsForOrganizationId(organizationId: OrganizationId): Promise<Array<CpApplication>> {
    const applications = await this.getAllApplications();
    return applications.filter(a => a.organizationId === organizationId);
  }
}
