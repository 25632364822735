<div class="studio-home">
  <div class="header">
    <h1 class="title">Squid AI Agent Studio</h1>
    <button
      mat-flat-button
      data-testid="add-ai-agent-button"
      class="primary_button large_button"
      (click)="createAgent()"
    >
      Create New Agent
    </button>
  </div>

  @let agents = agents$ | async;
  @let application = application$ | async;

  <div class="agents">
    @if (application) {
      @if (agents) {
        @if (hasAgents) {
          @for (agentId of getSortedKeys(agents); track agentId) {
            @let agentAndContext = agents[agentId];
            @let agent = agentAndContext.agent;
            @let topAbilities = getTopAbilities(agentId);

            <a class="agent"
               [routerLink]="navigateToStudio ? ['/application', application.appId, 'studio', agentId] : null"
               (click)="onAnchorClick($event)">
              <div class="top_and_description">
                <div class="top_part">
                  <span class="id">{{ agentId }}</span>
                  <button
                    class="secondary_icon_button"
                    (click)="openMenu($event)"
                    [matMenuTriggerFor]="agentMenu"
                    [matMenuTriggerData]="{agentId}"
                    mat-flat-button
                  >
                    <mat-icon class="menu_icon" svgIcon="menu_dots_icon"></mat-icon>
                  </button>
                </div>
                <p class="description">
                  {{ agent.description }}
                </p>
              </div>
              <div class="badge_and_icons">
                @if (agent.isPublic) {
                  <div class="outline_badge public">Public</div>
                } @else {
                  <div class="outline_badge private">Private</div>
                }
                <div class="ability_icons">
                  @for (type of topAbilities.top; track type) {
                    <img [src]="getImage(type)" class="ability_icon" />
                  }
                  @if (topAbilities.count) {
                    <div class="ability_icon count_icon">+{{ topAbilities.count }}</div>
                  }
                </div>
              </div>
            </a>
          }
        }
      }
    }
  </div>

  @if (agents) {
    @if (!hasAgents) {
      <knowledge-card [hideClose]="true">
        <ng-container content>
          <div class="education">
            <h2>{{ EDUCATION.title }}</h2>
            <div class="steps">
              @for (step of EDUCATION.steps; track step.number) {
                <div class="step">
                  <span class="number">{{ step.number }}</span>
                  <p>{{ step.text }}</p>
                </div>
              }
            </div>
            <button
              mat-flat-button
              data-testid="add-first-ai-agent-button"
              class="primary_button large_button"
              (click)="createAgent()"
            >
              Create your first Agent
            </button>
          </div>
        </ng-container>
      </knowledge-card>
    }
  }

  <mat-menu #agentMenu="matMenu" class="integration_menu" xPosition="before" (closed)="onCloseMenu()">
    <ng-template let-element="agentId" matMenuContent>
      <button
        class="menu_item" mat-menu-item
        (click)="shareAgent(element, application!.appId)"
      >
        <mat-icon class="icon" svgIcon="ai_icon"></mat-icon>
        Share Agent
      </button>
      <div class="hr"></div>
      <button
        class="menu_item red_menu_item" mat-menu-item
        (click)="deleteAgent(element)"
      >
        <mat-icon class="icon" svgIcon="trash_icon"></mat-icon>
        Delete Agent
      </button>
    </ng-template>
  </mat-menu>
</div>
