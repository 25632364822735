import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AiAgentAndContexts, StudioService } from '@squidcloud/console-web/app/studio/studio.service';
import { Observable } from 'rxjs';
import { AiFunctionMetadata } from '@squidcloud/internal-common/types/bundle-data.types';
import {
  AbilityConnectOptionsMap,
  AbilityCreateOptionsMap,
  AbilityGroup,
  AbilityType,
  AbilityTypeWithConnect,
  AbilityTypeWithCreate,
  getAbilityGroupMetadata,
  getAbilityMetadata,
  getAbilityType,
  isAbilityGroup,
  isAbilityType,
  KNOWLEDGE_ABILITIES,
} from '@squidcloud/console-web/app/studio/agent/abilities/types';
import { CpIntegration } from '@squidcloud/console-common/types/application.types';
import { Categories, getIntegration, Integrations } from '@squidcloud/console-web/app/integrations/utils/content';
import { groupBy } from '@squidcloud/internal-common/utils/object';
import { getEntries } from '@squidcloud/console-web/app/utils/angular-utils';
import { IntegrationCategory } from '@squidcloud/console-common/types/integration.types';
import { truthy } from 'assertic';
import { getFunctionNameFromServiceFunctionName } from '@squidcloud/internal-common/utils/bundle-utils';

type CreateEvent = {
  [K in AbilityTypeWithCreate]: { type: K; options: AbilityCreateOptionsMap[K] };
}[AbilityTypeWithCreate];

type ConnectEvent = {
  [K in AbilityTypeWithConnect]: { type: K; options: AbilityConnectOptionsMap[K] };
}[keyof AbilityConnectOptionsMap];

type AbilitySection = Partial<Record<AbilityGroup | AbilityType, Array<AbilityTypeWithCreate>>>;

@Component({
  selector: 'studio-flyout',
  templateUrl: './flyout.component.html',
  styleUrl: './flyout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class StudioFlyoutComponent implements OnChanges {
  readonly list = [
    {
      name: 'Knowledge Base',
      abilities: KNOWLEDGE_ABILITIES,
      tooltip:
        'Provide background information that provides context to your agent so it understands and responds to queries effectively.',
    },
  ];
  readonly expandedObs = this.studioService.observeFlyout();

  readonly isAbilityType = isAbilityType;
  readonly isAbilityGroup = isAbilityGroup;

  readonly getAbilityGroupMetadata = getAbilityGroupMetadata;
  readonly getEntries = getEntries;
  readonly Categories = Categories;
  readonly getFunctionNameFromServiceFunctionName = getFunctionNameFromServiceFunctionName;

  @Output() create = new EventEmitter<CreateEvent>();
  @Output() connect = new EventEmitter<ConnectEvent>();

  agentAndContexts$: Observable<AiAgentAndContexts | undefined>;
  @Input({ required: true }) agentId!: string;
  @Input({ required: true }) eligibleAiFunctions!: Array<AiFunctionMetadata>;
  @Input({ required: true }) eligibleConnectedAgentIds!: Array<string>;
  @Input({ required: true }) eligibleConnectors!: Array<AbilityTypeWithCreate>;
  @Input({ required: true }) existingConnectors!: Array<CpIntegration>;

  constructor(private readonly studioService: StudioService) {
    this.agentAndContexts$ = this.studioService.observeAgent(this.agentId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['agentId']) {
      this.agentAndContexts$ = this.studioService.observeAgent(this.agentId);
    }
  }

  handleClose(): void {
    this.studioService.closeFlyout();
  }

  get eligibleConnectorsByCategory(): Partial<Record<IntegrationCategory, AbilitySection>> {
    let result: Partial<Record<IntegrationCategory, AbilitySection>> = {};
    this.eligibleConnectors.forEach(type => {
      const group = getAbilityMetadata(type).group;
      const category = getIntegration(type).category;
      if (!result[category]) {
        result[category] = {};
      }
      if (group) {
        const currentGroup = result[category][group] || [];
        currentGroup.push(type);
        result[category][group] = currentGroup;
      } else {
        result[category][type] = [type];
      }
    });
    return result;
  }

  getExistingConnectorsByType(type: AbilityType | AbilityGroup): Array<CpIntegration> {
    const isGroup = isAbilityGroup(type);
    return this.existingConnectors.filter(integration => {
      const metadata = getAbilityMetadata(getAbilityType(integration.type));
      return isGroup ? metadata.group === type : type === getAbilityType(integration.type);
    });
  }

  get existingConnectorsByCategory(): Record<IntegrationCategory, Array<CpIntegration>> {
    return groupBy(this.existingConnectors, i => Integrations[i.type].category);
  }

  getAgentDescription(agentId: string): string | undefined {
    const agentAndContexts = this.studioService.getAgentAndContextsOrFail(agentId);
    return agentAndContexts.agent.description;
  }

  getCategoryName(category: string): string {
    return truthy(Categories[category as IntegrationCategory]).name;
  }

  getTooltipByCategoryName(category: string): string | undefined {
    switch (category.toLowerCase()) {
      case 'api':
        return 'Lets your agent interact with specific endpoints for targeted data retrieval or action execution.';
      case 'database':
        return 'Securely connect to structured data to allow your agent access real-time information beyond its built-in knowledge.';
      case 'storage':
        return "Access to cloud-based storage that house diverse data types to complement your agent's knowledge.";
      case 'saas':
        return 'Connect your agent to your software services to give it specialized capabilities beyond its core functions.';
      case 'crm':
        return "Provide your agent secure access to customer profiles and data to automate sales workflows or personalize responses and actions based on a customer's specific situation.";
      default:
        return undefined;
    }
  }

  get hasExisting(): boolean {
    return (
      !!this.eligibleAiFunctions.length || !!this.existingConnectors.length || !!this.eligibleConnectedAgentIds.length
    );
  }
}
