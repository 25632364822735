import { CdkRowDef } from '@angular/cdk/table';
import { Directive, Input } from '@angular/core';
import { MatRowDef, MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';

/**
 * [matRowDef] from Angular uses `any` as a table element type by default.
 * This extension adds type safety to the element.
 * Usage: <td *matRowDef="let element; typedBy: elementsDataSource">
 */
@Directive({
  selector: '[matRowDef]',
  providers: [{ provide: CdkRowDef, useExisting: MatRowDefTypedByDirective }],
  standalone: false,
})
export class MatRowDefTypedByDirective<T> extends MatRowDef<T> {
  @Input() matRowDefTypedBy!: Array<T> | Observable<Array<T>> | MatTableDataSource<T>;

  static ngTemplateContextGuard<T>(
    _: MatRowDefTypedByDirective<T>,
    _ctx: unknown,
  ): _ctx is {
    $implicit: T;
    index: number;
  } {
    return true;
  }
}
