/* eslint-disable jsdoc/require-jsdoc */
import { DocumentReference } from '../document-reference';
import { DocumentReferenceFactory } from '../document-reference.factory';
import { DocumentStore } from '../document-store';
import { QuerySubscriptionManager } from './query-subscription.manager';
import { DocumentData, Query, SquidDocId } from '../public-types';
import { getSquidDocId } from '../../../internal-common/src/types/document.types';
import { QueryContext } from '../../../internal-common/src/public-types-backend/query.public-context';

/** @internal */
export class LocalQueryManager {
  constructor(
    private readonly documentStore: DocumentStore,
    private readonly documentReferenceFactory: DocumentReferenceFactory,
    private readonly querySubscriptionManager: QuerySubscriptionManager,
  ) {}

  peek<T extends DocumentData>(query: Query<T>): Array<DocumentReference<T>> {
    if (!this.querySubscriptionManager.findValidParentOfQuery(query)) return [];

    const { integrationId, collectionName } = query;
    const queryContext = new QueryContext(query);
    const references = this.documentReferenceFactory
      .getDocumentsForCollection(integrationId, collectionName)
      .filter(doc => queryContext.documentMatchesQuery(doc.data));

    const refMap: Record<SquidDocId, DocumentReference> = {};
    references.forEach(reference => {
      refMap[reference.squidDocId] = reference;
    });

    const documents = this.documentStore.sortAndLimitDocs(new Set(Object.keys(refMap)), query);
    return documents.map(doc => refMap[getSquidDocId(doc.__docId__, collectionName, integrationId)]);
  }
}
