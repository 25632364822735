<div class="test">
  <div class="left">
    @if (widgetParams) {
      <div class="widget">
        <squid-chat-widget
          class="squid_chat_widget"
          [header-title]="agentId"
          [squid-app-id]="widgetParams.appId"
          [squid-region]="widgetParams.region"
          [squid-environment-id]="widgetParams.environmentId"
          [squid-api-key]="widgetParams.apiKey"
          [squid-ai-agent-id]="widgetParams.agentId"
          [intro-text]="widgetParams.introText"
          [enable-transcription]="true"
          [observe-status]="true"
          [include-reference]="true"
          (change)="onChange($event)"
        >
        </squid-chat-widget>
      </div>
    }
  </div>
  <div class="right">
    <div class="statuses">
      @for (status of statuses; track status) {
        <status-card [status]="status" />
      }
    </div>
  </div>
</div>
