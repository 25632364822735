import { ClientIdService } from './client-id.service';
import { SocketManager } from './socket.manager';
import { Observable } from 'rxjs';

import { ClientId } from '../../internal-common/src/public-types/communication.public-types';

/**
 * Provides information about the connection to the Squid Server.
 * @category Platform
 */
export class ConnectionDetails {
  private isConnected = false;

  /** @internal */
  constructor(
    private readonly clientIdService: ClientIdService,
    private readonly socketManager: SocketManager,
  ) {
    this.socketManager.observeConnectionReady().subscribe(isConnected => {
      this.isConnected = isConnected;
    });
  }

  /** Whether the Squid Client SDK is currently connected to the Squid Server. */
  get connected(): boolean {
    return this.isConnected;
  }

  /**
   * A unique client ID that is assigned to the client. This client ID is also available to the different backend
   * function in the context object.
   * Note: The client ID may change after a long disconnect.
   */
  get clientId(): ClientId {
    return this.clientIdService.getClientId();
  }

  /**
   * Returns an observable that emits true when the client is connected to the server and false when the client is
   * disconnected from the server.
   */
  observeConnected(): Observable<boolean> {
    return this.socketManager.observeConnectionReady();
  }
}
