import { CollectionReference } from './collection-reference';
import { DocumentReferenceFactory } from './document-reference.factory';
import { QueryBuilderFactory } from './query/query-builder.factory';
import { QuerySubscriptionManager } from './query/query-subscription.manager';
import { CollectionName, DocumentData, IntegrationId } from './public-types';
import { DataManager } from './data.manager';

/** @internal */
export class CollectionReferenceFactory {
  private readonly collections = new Map<IntegrationId, Map<CollectionName, CollectionReference<DocumentData>>>();

  /** @internal */
  constructor(
    private readonly documentReferenceFactory: DocumentReferenceFactory,
    private readonly queryBuilderFactory: QueryBuilderFactory,
    private readonly querySubscriptionManager: QuerySubscriptionManager,
    private readonly dataManager: DataManager,
  ) {}

  /**
   * Retrieves a reference to a collection within a specific integration.
   * If a reference for the given collection and integration doesn't exist yet, it will be created and cached.
   *
   * @param collectionName - The name of the collection.
   * @param integrationId - The ID of the integration the collection belongs to.
   * @returns A {@link CollectionReference} for the specified collection and integration.
   */
  get<T extends DocumentData>(collectionName: CollectionName, integrationId: IntegrationId): CollectionReference<T> {
    let integrationCollectionMap = this.collections.get(integrationId);
    if (!integrationCollectionMap) {
      integrationCollectionMap = new Map();
      this.collections.set(integrationId, integrationCollectionMap);
    }
    let collectionRef = integrationCollectionMap.get(collectionName);
    if (!collectionRef) {
      collectionRef = new CollectionReference(
        collectionName,
        integrationId,
        this.documentReferenceFactory,
        this.queryBuilderFactory,
        this.querySubscriptionManager,
        this.dataManager,
      );
      integrationCollectionMap.set(collectionName, collectionRef);
    }
    return collectionRef as CollectionReference<T>;
  }
}
