import { BaseAbility } from '@squidcloud/console-web/app/studio/agent/abilities/base.ability';
import { getFunctionNameFromServiceFunctionName } from '@squidcloud/internal-common/utils/bundle-utils';
import { FunctionName, ServiceFunctionName } from '@squidcloud/client';
import { AbilityMetadata } from '@squidcloud/console-web/app/studio/agent/abilities/types';

type ConnectionOptions = {
  serviceFunction: ServiceFunctionName;
};

type DeleteOptions = {
  fn: FunctionName;
};

export class AiFunctionAbility extends BaseAbility<undefined, ConnectionOptions, DeleteOptions> {
  static override metadata: AbilityMetadata = {
    description: 'Testing',
    icon: 'ai_function',
  };

  override async onConnect(agentId: string, options: ConnectionOptions): Promise<boolean> {
    const { serviceFunction } = options;

    return new Promise(async (resolve, reject) => {
      const functionName = getFunctionNameFromServiceFunctionName(serviceFunction);
      const { agent } = this.studioService.getAgentAndContextsOrFail(agentId);
      const currentFunctions = (agent.options.functions || [])?.filter(f => f !== functionName);
      currentFunctions.push(functionName);
      agent.options.functions = currentFunctions;

      try {
        await this.studioService.upsertAgent(agentId, agent);
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  }

  override async onDelete(agentId: string, options: DeleteOptions): Promise<boolean> {
    const { fn } = options;

    return new Promise(async (resolve, reject) => {
      this.globalUiService.showConfirmationDialog(
        'Remove AI function?',
        `Are you sure you want to remove the <b>${fn}</b> AI function?`,
        'Remove',
        async () => {
          const { agent } = this.studioService.getAgentAndContextsOrFail(agentId);
          agent.options.functions = (agent.options.functions || []).filter(f => f !== fn);

          try {
            await this.studioService.upsertAgent(agentId, agent);
            resolve(true);
          } catch (e) {
            reject(e);
          }
        },
      );
    });
  }
}
