/**
 * Waits given number of milliseconds.
 * Can be used by tests and scripts for rate limiting.
 * */
export function sleep(timeoutMillis: number): Promise<void> {
  if (timeoutMillis <= 0) {
    return Promise.resolve();
  }
  return new Promise(resolve => setTimeout(async () => resolve(), timeoutMillis));
}

/** Waits until up to `timeoutMillis` for the promise to resolve or throws an error. */
export async function awaitWithTimeout<T = unknown>(promise: Promise<T>, timeoutMillis: number): Promise<T> {
  return Promise.race([
    promise,
    new Promise<T>((_, reject) => setTimeout(() => reject(new Error('Timeout')), timeoutMillis)),
  ]);
}

export function getLocalTimeWithTimeZone(): string {
  return new Date()
    .toLocaleString('en-US', {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timeZoneName: 'short',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })
    .replace(/\//g, '.');
}
