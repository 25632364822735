import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApplicationService } from '../../../application/application.service';
import { FeedbackDialogComponent } from '@squidcloud/console-web/app/global/components/feedback-dialog/feedback-dialog.component';
import { OrganizationService } from '../../../organization/organization.service';
import { ThemeService } from '../../services/theme.service';
import { SidebarMenuItem } from './sidebar-menu-item/sidebar-menu-item.component';
import { QuickStartService } from '../../../quick-start/quick-start.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Organization } from '@squidcloud/console-common/types/organization.types';
import { CpApplication } from '@squidcloud/console-common/types/application.types';
import { AccountService } from '@squidcloud/console-web/app/account/account.service';
import { isSysadminUserEmail } from '@squidcloud/console-common/utils/sysadmin-utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { checkApplicationHasQuotasInBillingPlan } from '@squidcloud/console-web/app/usage/quotas/quotas.component';
import { AI_AGENTS_INTEGRATION_ID } from '@squidcloud/client';
import { AGENT_ID_PARAMETER } from '@squidcloud/console-web/app/utils/http-utils';

interface QuickStartSidebarProgress {
  total: number;
  completed: number;
  percentCompleted: number;
}

const USAGE_MENU_ITEM_ID = 'usage';
const STUDIO_MENU_ITEM_ID = 'studio';

@Component({
  templateUrl: './sidebar-layout.component.html',
  styleUrls: ['./sidebar-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SidebarLayoutComponent {
  readonly applicationInitializedObs = this.applicationService.observeApplicationInitialized();
  readonly isExpandedObs = this.accountService.observeUser().pipe(map(user => !user?.uiPreferences?.sideBarCollapsed));
  isApplicationUsageMenuItemVisible = false;
  isApplicationAgentsMenuItemVisible = false;
  readonly themeObs = this.themeService.theme$;
  readonly isSysadminUserEmail = isSysadminUserEmail;

  // Nested array of menu items. Outer array is used to add "wave" breaks between sections.
  sidebarMenuItems: Array<Array<SidebarMenuItem>> = [];
  organizationMenuItem: SidebarMenuItem | undefined = undefined;

  readonly feedbackMenuItem: SidebarMenuItem = {
    id: 'feedback',
    iconName: 'feedback_icon',
    text: 'Send Feedback',
    clickFn: () => this.showFeedbackDialog(),
  };

  readonly documentationMenuItem: SidebarMenuItem = {
    id: 'documentation',
    iconName: 'documentation_icon',
    text: 'Documentation',
    link: 'https://docs.squid.cloud',
  };

  readonly adminMenuItem: SidebarMenuItem = {
    id: 'admin',
    iconName: 'wheel',
    text: 'Admin',
    clickFn: () => this.router.navigate(['admin']).then(),
  };

  readonly onboardingMenuItem: SidebarMenuItem = {
    id: 'onboarding',
    iconName: 'ship',
    text: 'Onboarding',
    clickFn: () =>
      this.router
        .navigate(['/onboarding', 'cards'], {
          queryParams: { oid: this.organizationService.getCurrentOrganizationOrFail().id, type: 'return' },
        })
        .then(),
  };

  quickStartProgressObs: Observable<QuickStartSidebarProgress> = this.quickStartService.observeTasks().pipe(
    map(tasks => {
      return {
        total: tasks.length,
        completed: tasks.filter(t => t.completionDate).length,
        percentCompleted:
          tasks.length > 0 ? Math.round((tasks.filter(t => t.completionDate).length / tasks.length) * 100) : 0,
      };
    }),
  );

  quickStartExpandedObs = this.quickStartService.observeExpanded();

  constructor(
    private readonly themeService: ThemeService,
    private readonly router: Router,
    private readonly applicationService: ApplicationService,
    private readonly organizationService: OrganizationService,
    private readonly dialog: MatDialog,
    private readonly quickStartService: QuickStartService,
    readonly accountService: AccountService,
    cdr: ChangeDetectorRef,
  ) {
    this.applicationService
      .observeCurrentApplication()
      .pipe(takeUntilDestroyed())
      .subscribe(application => {
        this.updateSidebarMenuItems(application);
        cdr.markForCheck();
      });
    this.organizationService
      .observeCurrentOrganization()
      .pipe(takeUntilDestroyed())
      .subscribe(organization => {
        if (organization) {
          this.updateOrganizationMenuItem(organization);
          cdr.markForCheck();
        }
      });
  }

  updateOrganizationMenuItem(organization: Organization): void {
    const organizationRoute = `/organization/${organization.id}`;

    this.organizationMenuItem = {
      id: 'organization',
      iconName: 'organization_icon',
      text: 'Organization',
      link: `${organizationRoute}`,
    };
  }

  isMenuItemActive(menuItem: SidebarMenuItem): boolean {
    const url = this.router.url;
    const application = this.applicationService.getCurrentApplication();
    switch (menuItem.id) {
      case 'application':
        return application ? !!url.match(`/(application\/[^\/]*$)`) : !!url.match(`/application$`);
      case 'integrations':
        return (
          !!url.match(`/(${menuItem.id}|integration/)`) &&
          !url.includes('integration/ai_agents/') &&
          !url.includes(`${AGENT_ID_PARAMETER}=`)
        );
      case 'studio':
        return !!url.match(`/${menuItem.id}`) || url.includes(`${AGENT_ID_PARAMETER}=`);
      default:
        return !!url.match(`/${menuItem.id}`);
    }
  }

  updateSidebarMenuItems(application: CpApplication | undefined): void {
    const applicationRoute = application ? `/application/${application.appId}` : `/application`;

    this.sidebarMenuItems = [
      [
        {
          id: STUDIO_MENU_ITEM_ID,
          iconName: 'studio',
          text: 'Agent Studio',
          link: `${applicationRoute}/studio/home`,
        },
      ],
      [
        { id: 'application', iconName: 'settings_icon', text: 'Application', link: applicationRoute },
        {
          id: 'integrations',
          iconName: 'disconnect_icon',
          text: 'Connectors',
          link: `${applicationRoute}/integrations`,
        },
        { id: 'secrets', iconName: 'secrets_icon', text: 'Secrets', link: `${applicationRoute}/secrets` },
        { id: 'backend', iconName: 'backend_icon', text: 'Backend', link: `${applicationRoute}/backend` },
        { id: USAGE_MENU_ITEM_ID, iconName: 'usage', text: 'Usage', link: `${applicationRoute}/usage` },
        { id: 'logs', iconName: 'logs_icon', text: 'Logs', link: `${applicationRoute}/logs` },
      ],
    ];
    const organization = this.organizationService.getCurrentOrganization();
    this.isApplicationUsageMenuItemVisible =
      !!organization && !!application && checkApplicationHasQuotasInBillingPlan(organization.billingPlan);
    this.isApplicationAgentsMenuItemVisible = !!application?.integrations?.[AI_AGENTS_INTEGRATION_ID];
  }

  showFeedbackDialog(): void {
    const config: MatDialogConfig = {
      maxWidth: '688px',
      width: '100%',
      autoFocus: true,
      restoreFocus: false,
      panelClass: 'modal',
    };
    this.dialog.open(FeedbackDialogComponent, config);
  }

  toggleQuickStartExpanded(): void {
    this.quickStartService.toggleExpanded();
  }

  getMaxFillWidth(fillPath: HTMLElement): number {
    return -(fillPath as unknown as SVGPathElement).getTotalLength();
  }

  isMenuItemVisible(item: SidebarMenuItem): boolean {
    switch (item.id) {
      case USAGE_MENU_ITEM_ID: {
        return this.isApplicationUsageMenuItemVisible;
      }
      default: {
        return true;
      }
    }
  }

  setIsCollapsed(isCollapsed: boolean): void {
    this.accountService.setSideBarCollapseState(isCollapsed);
  }
}
