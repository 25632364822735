import { AssistantToolType, QueryAssistantOptions } from './public-types';
import { RpcManager } from './rpc.manager';
import {
  AddFileToAssistantRequest,
  AddFileToAssistantResponse,
  AddFileToThreadRequest,
  AddFileToThreadResponse,
  CreateAssistantRequest,
  CreateAssistantResponse,
  CreateAssistantThreadRequest,
  CreateAssistantThreadResponse,
  DeleteAssistantRequest,
  DeleteThreadRequest,
  QueryAssistantRequest,
  QueryAssistantResponse,
  RemoveFileFromAssistantRequest,
} from '../../internal-common/src/types/ai-assistant.types';

/**
 * Client class for interacting with an AI Assistant server.
 * Provides functionalities like creating and deleting assistants and threads,
 * querying assistants, and managing files associated with assistants and threads.
 * @category AI
 */
export class AiAssistantClient {
  /** @internal */
  constructor(private readonly rpcManager: RpcManager) {}

  /**
   * Creates a new AI assistant with specified characteristics.
   * @param name - The name of the assistant.
   * @param instructions - Instructions for the assistant.
   * @param functions - Array of function names annotated with "@aiFunction" in your Squid backend that will be
   *   available to the assistant.
   * @param toolTypes - Optional array of tool types. If you want to use files for retrieval, you must add them using
   *   the addFileToAssistant method.
   * @returns A promise that resolves to the created assistant's ID.
   */
  async createAssistant(
    name: string,
    instructions: string,
    functions: Array<string>,
    toolTypes?: Array<AssistantToolType>,
  ): Promise<string> {
    const request: CreateAssistantRequest = {
      name,
      instructions,
      functions,
      toolTypes,
    };
    const response = await this.rpcManager.post<CreateAssistantResponse>('ai/assistant/createAssistant', request);
    return response.assistantId;
  }

  /**
   * Deletes an AI assistant.
   * @param assistantId - The ID of the assistant to be deleted.
   * @returns A promise that resolves when the assistant is deleted.
   */
  async deleteAssistant(assistantId: string): Promise<void> {
    const request: DeleteAssistantRequest = {
      assistantId,
    };
    await this.rpcManager.post('ai/assistant/deleteAssistant', request);
  }

  /**
   * Creates a new thread for an AI assistant. A thread is a long-lived conversation with the assistant that you can
   * always send questions to.
   * @param assistantId - The ID of the assistant for which the thread is created.
   * @returns A promise that resolves to the created thread's ID.
   */
  async createThread(assistantId: string): Promise<string> {
    const request: CreateAssistantThreadRequest = {
      assistantId,
    };
    const response = await this.rpcManager.post<CreateAssistantThreadResponse>('ai/assistant/createThread', request);
    return response.threadId;
  }

  /**
   * Deletes a thread of an AI assistant.
   * @param threadId - The ID of the thread to be deleted.
   * @returns A promise that resolves when the thread is deleted.
   */
  async deleteThread(threadId: string): Promise<void> {
    const request: DeleteThreadRequest = {
      threadId,
    };
    await this.rpcManager.post('ai/assistant/deleteThread', request);
  }

  /**
   * Queries an AI assistant within a specific thread.
   * @param assistantId - The ID of the assistant.
   * @param threadId - The ID of the thread.
   * @param prompt - The query prompt.
   * @param fileIds - Optional array of file IDs to include in the query. These file IDs need to be added using the
   *   addFileToThread method.
   * @param options - Optional query options.
   * @returns A promise that resolves to the assistant's response.
   */
  async queryAssistant(
    assistantId: string,
    threadId: string,
    prompt: string,
    fileIds?: string[],
    options?: QueryAssistantOptions,
  ): Promise<string> {
    const request: QueryAssistantRequest = {
      assistantId,
      threadId,
      prompt,
      fileIds,
      options,
    };
    const response = await this.rpcManager.post<QueryAssistantResponse>('ai/assistant/queryAssistant', request);
    return response.answer;
  }

  /**
   * Adds a file to an AI assistant that can be available for retrieval or code analyzer.
   * @param assistantId - The ID of the assistant.
   * @param file - The file to be added.
   * @returns A promise that resolves to the ID of the added file.
   */
  async addFileToAssistant(assistantId: string, file: File): Promise<string> {
    const request: AddFileToAssistantRequest = { assistantId };
    const response = await this.rpcManager.post<AddFileToAssistantResponse>(
      `ai/assistant/addFileToAssistant`,
      request,
      [file],
      'file',
    );
    return response.fileId;
  }

  /**
   * Removes a file from an AI assistant.
   * @param assistantId - The ID of the assistant.
   * @param fileId - The ID of the file to be removed.
   * @returns A promise that resolves when the file is removed.
   */
  async removeFileFromAssistant(assistantId: string, fileId: string): Promise<void> {
    const request: RemoveFileFromAssistantRequest = {
      assistantId,
      fileId,
    };
    await this.rpcManager.post('ai/assistant/removeFileFromAssistant', request);
  }

  /**
   * Adds a file to a specific thread of an AI assistant. These files can be used when asking a question in the thread.
   * @param threadId - The ID of the thread.
   * @param file - The file to be added.
   * @returns A promise that resolves to the ID of the added file.
   */
  async addFileToThread(threadId: string, file: File): Promise<string> {
    const request: AddFileToThreadRequest = { threadId };
    const response = await this.rpcManager.post<AddFileToThreadResponse>(
      `ai/assistant/addFileToThread`,
      request,
      [file],
      'file',
    );
    return response.fileId;
  }
}
