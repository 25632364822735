import { RpcManager } from './rpc.manager';
import { SchedulerInfo, UpdateSchedulerOptions, UpdateSchedulersRequest } from './public-types';

interface ListSchedulersResponse {
  schedulers: SchedulerInfo[];
}

/**
 * SchedulerClient provides methods to manage schedulers,
 * including listing, enabling, and disabling scheduled tasks.
 * @category Platform
 */
export class SchedulerClient {
  /** @internal */
  constructor(private readonly rpcManager: RpcManager) {}

  /**
   * Lists all configured schedulers.
   *
   * @returns A promise resolving to an array of scheduler metadata.
   */
  async list(): Promise<SchedulerInfo[]> {
    const response = await this.rpcManager.post<ListSchedulersResponse>('/scheduler/listSchedulers', {});
    return response.schedulers;
  }

  /**
   * Enables one or more schedulers by their IDs.
   *
   * @param schedulerId A single scheduler ID or an array of scheduler IDs to enable.
   * @returns A promise that resolves once the update is complete.
   */
  async enable(schedulerId: string | string[]): Promise<void> {
    await this.update(this.createUpdateSchedulerOptions(schedulerId, true));
  }

  /**
   * Disables one or more schedulers by their IDs.
   *
   * @param schedulerId A single scheduler ID or an array of scheduler IDs to disable.
   * @returns A promise that resolves once the update is complete.
   */
  async disable(schedulerId: string | string[]): Promise<void> {
    await this.update(this.createUpdateSchedulerOptions(schedulerId, false));
  }

  private async update(updateSchedulerOptions: UpdateSchedulerOptions[]): Promise<void> {
    const request = { schedulers: updateSchedulerOptions } as UpdateSchedulersRequest;
    await this.rpcManager.post('scheduler/updateSchedulers', request);
  }

  private createUpdateSchedulerOptions(
    schedulerId: string | string[],
    enabled: boolean | undefined,
  ): UpdateSchedulerOptions[] {
    return Array.isArray(schedulerId)
      ? schedulerId.map(id => ({ schedulerId: id, enabled }))
      : [{ schedulerId, enabled }];
  }
}
