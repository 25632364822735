<div class="category" [ngClass]="alt">
  <button class="header" (click)="handleToggle()" [ngClass]="{ collapsed: !expanded }">
    <div class="title">
      {{ title }}
      @if (tooltip) {
        <mat-icon svgIcon="info" class="info_icon" matTooltipClass="tooltip_above"
                  [matTooltip]="tooltip"
                  matTooltipPosition="above" />
      }
    </div>
    <div class="toggle_icon_wrapper">
      <mat-icon class="toggle_icon" svgIcon="chevron_right_icon"></mat-icon>
    </div>
  </button>
  <div [ngClass]="{ collapsed: !expanded }" class="content_wrapper">
    <div class="content">
      <div class="spacer"></div>
      <ng-content />
    </div>
  </div>
</div>
