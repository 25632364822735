import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'abilities-category',
  templateUrl: './abilities-category.component.html',
  styleUrl: './abilities-category.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AbilitiesCategoryComponent {
  @Input({ required: true }) title!: string;
  @Input({ required: false }) tooltip?: string;
  @Input() alt = 'base';
  @Input() expanded = true;

  handleToggle(): void {
    this.expanded = !this.expanded;
  }
}
