import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuditLogEvent } from '@squidcloud/internal-common/types/observability.types';
import { Squid } from '@squidcloud/client';
import { Observable, of } from 'rxjs';
import { AuditLogEntryInDb } from '@squidcloud/console-common/types/application.types';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface AuditLogEntryDialogData {
  logEntry: AuditLogEvent;
}

@Component({
  templateUrl: './audit-log-entry-dialog.component.html',
  styleUrls: ['./audit-log-entry-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AuditLogEntryDialogComponent {
  protected readonly logEntry: AuditLogEvent;
  protected readonly promptEntries$: Observable<Array<AuditLogEntryInDb>>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AuditLogEntryDialogData,
    private readonly squid: Squid,
  ) {
    this.logEntry = data.logEntry;
    const traceId = this.logEntry.tags['traceId'];
    this.promptEntries$ = traceId
      ? this.squid
          .collection<AuditLogEntryInDb>('audit_logs', 'clickhouse')
          .query()
          .eq('tags.traceId', traceId)
          .sortBy('timestamp', true)
          .dereference()
          .snapshots()
          .pipe(takeUntilDestroyed())
      : of([]);
  }
}
