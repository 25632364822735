import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';
import { StudioService } from '@squidcloud/console-web/app/studio/studio.service';
import { AGENT_ID_PARAMETER, getRequiredPageParameter } from '@squidcloud/console-web/app/utils/http-utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { parseAppId } from '@squidcloud/internal-common/types/communication.types';
import { convertToSquidRegion } from '@squidcloud/console-common/clouds-and-regions';
import { environment } from '@squidcloud/console-web/environments/environment';
import {
  AiTestChatWidgetParams,
  ChatMessage,
} from '@squidcloud/console-web/app/integrations/schema/ai-agents/ai-test-chat-fly-out/ai-test-chat-fly-out.service';
import { AiStatusMessage } from '@squidcloud/client';

// TODO: Make these types common.
type StatusDetail = { type: 'status'; status: Array<AiStatusMessage> };
type History = { type: 'history'; history: Array<ChatMessage> };

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrl: './test.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class StudioTestComponent {
  readonly agentId: string;
  statuses: Array<AiStatusMessage> = [];
  readonly JSON = JSON;
  widgetParams: AiTestChatWidgetParams | undefined;

  constructor(
    activatedRoute: ActivatedRoute,
    private readonly applicationService: ApplicationService,
    private readonly studioService: StudioService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.agentId = getRequiredPageParameter(AGENT_ID_PARAMETER, activatedRoute.snapshot);

    this.applicationService.currentApplication$.pipe(takeUntilDestroyed()).subscribe(async application => {
      if (!application) return;

      void this.studioService.initialize(application.appId);

      const apiKey = await this.applicationService.getApiKey();
      const parsedAppId = parseAppId(application.appId);

      this.widgetParams = {
        agentId: this.agentId,
        appId: parsedAppId.appId,
        environmentId: parsedAppId.environmentId,
        region: convertToSquidRegion(application.cloudId, application.region, application.shard, environment.stage),
        apiKey,
        aiQuery: false,
        introText: 'Hi, I am Rudder, an AI from Squid. How may I assist you?',
      };
      this.cdr.detectChanges();
    });
  }

  onChange(event: Event): void {
    const detail = (event as Event & { detail: StatusDetail | History }).detail;
    if (detail.type === 'history') {
      const lastMessage = detail.history[detail.history.length - 1];
      if (lastMessage.type === 'user') {
        this.statuses = [];
      }
    } else if (detail.type === 'status') {
      this.statuses = detail.status;
    }
  }
}
