import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ApplicationService } from '../../application/application.service';
import { StudioService } from '@squidcloud/console-web/app/studio/studio.service';

@Injectable({
  providedIn: 'root',
})
export class StudioGuard implements CanActivate {
  constructor(
    private readonly applicationService: ApplicationService,
    private readonly studioService: StudioService,
    private readonly router: Router,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const { agentId } = route.params;
    const application = await firstValueFrom(this.applicationService.observeCurrentApplication());
    if (!application) {
      return this.router.createUrlTree(['/application']);
    }

    await this.studioService.initialize(application.appId);
    const agents = await firstValueFrom(this.studioService.observeAgents());

    const agentAndContexts = agents?.[agentId];
    return agentAndContexts ? true : this.router.createUrlTree(['/application', application.appId, 'studio']);
  }
}
