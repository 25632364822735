@let promptEntries = promptEntries$ | async;

<div mat-dialog-title>Log entry</div>

<mat-dialog-content class="log_entry_dialog">
  <dialog-maximize-button></dialog-maximize-button>
  <dialog-close-button></dialog-close-button>

  @if (promptEntries?.length) {
    <div class="logs_table info_table">
      <div class="row header_row">
        <div class="cell">Agent ID</div>
        <div class="cell">User ID</div>
      </div>

      <div class="row">
        <div class="cell timestamp_cell">
          {{ logEntry.tags['agentId'] }}
        </div>
        <div class="cell">
          {{ logEntry.context.userId }}
        </div>
      </div>
    </div>

    <div class="logs_table trace_table">
      <div class="row header_row">
        <div class="cell">Timestamp</div>
        <div class="cell">Type</div>
        <div class="cell">Value</div>
      </div>
      @for (logEntry of promptEntries; track logEntry.id) {
        <div class="row">
          <div class="cell timestamp_cell">
            {{ logEntry.timestamp | date: 'medium' }}
          </div>
          <div class="cell">{{ logEntry.tags['type'] }}</div>
          <div class="cell">{{ logEntry.tags['value'] }}</div>
        </div>
      }
    </div>
  }
</mat-dialog-content>
