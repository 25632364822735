import { BaseAbility } from '@squidcloud/console-web/app/studio/agent/abilities/base.ability';
import { truthy } from 'assertic';
import { AbilityMetadata } from '@squidcloud/console-web/app/studio/agent/abilities/types';

type Options = {
  connectedAgentId: string;
};

export class ConnectedAgentAbility extends BaseAbility<undefined, Options, Options> {
  static override metadata: AbilityMetadata = {
    description: 'Testing',
    icon: 'ai_agents',
    group: 'connected_agents',
  };

  override async onConnect(agentId: string, options: Options): Promise<boolean> {
    return this.updateConnectedAgent(agentId, options, false);
  }

  override async onEdit(agentId: string, options: Options): Promise<boolean> {
    return this.updateConnectedAgent(agentId, options, true);
  }

  private async updateConnectedAgent(agentId: string, options: Options, isEdit: boolean): Promise<boolean> {
    const { connectedAgentId } = options;
    const { agent } = this.studioService.getAgentAndContextsOrFail(agentId);
    const currentConnectedAgents = [...(agent.options.connectedAgents || [])];
    const connectedAgent = currentConnectedAgents.find(i => i.agentId === connectedAgentId);

    if (!isEdit && connectedAgent) {
      return false;
    }

    return new Promise((resolve, reject) => {
      this.globalUiService.showDialogWithForm<{ description: string }>(
        {
          title: `Connect ${connectedAgentId}`,
          textLines: [`Describe to the agent when to use this connected agent.`],
          submitButtonText: 'Connect Agent',
          formElements: [
            {
              type: 'textarea',
              label: 'Description',
              nameInForm: 'description',
              required: true,
              placeholder: 'Call this when a user asks for shipping status updates.',
              floatLabel: 'always',
              attributes: { autosize: true, minRows: 3, maxRows: 50 },
              defaultValue: connectedAgent?.description,
            },
          ],
          onSubmit: async (data): Promise<string | void> => {
            try {
              if (!data) return;
              const description = data.description;
              const { agent } = this.studioService.getAgentAndContextsOrFail(agentId);
              const currentConnectedAgents = [...(agent.options.connectedAgents || [])];

              if (isEdit) {
                const connectedAgent = truthy(currentConnectedAgents.find(i => i.agentId === connectedAgentId));
                connectedAgent.description = description;
              } else {
                currentConnectedAgents.push({
                  agentId: connectedAgentId,
                  description,
                });
              }

              agent.options.connectedAgents = currentConnectedAgents;
              await this.studioService.upsertAgent(agentId, agent);
              resolve(true);
            } catch (e) {
              reject(e);
            }
          },
        },
        true,
      );
    });
  }

  override onDelete(agentId: string, options: Options): Promise<boolean> {
    const { connectedAgentId } = options;

    return new Promise(async (resolve, reject) => {
      this.globalUiService.showConfirmationDialog(
        'Remove connected agent?',
        `Are you sure you want to remove the connected agent <b>${connectedAgentId}</b>?`,
        'Remove',
        async () => {
          const { agent } = this.studioService.getAgentAndContextsOrFail(agentId);
          agent.options.connectedAgents = (agent.options.connectedAgents || []).filter(
            f => f.agentId !== connectedAgentId,
          );

          try {
            await this.studioService.upsertAgent(agentId, agent);
            resolve(true);
          } catch (e) {
            reject(e);
          }
        },
      );
    });
  }
}
