import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  AbilityGroup,
  AbilityMetadata,
  AbilityType,
  getAbilityGroup,
  getAbilityGroupMetadata,
  getAbilityMetadata,
  getAbilityType,
  isAbilityGroup,
} from '@squidcloud/console-web/app/studio/agent/abilities/types';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'abilities-card',
  templateUrl: './abilities-card.component.html',
  styleUrl: './abilities-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AbilitiesCardComponent {
  @Input({ required: true }) type!: AbilityType | AbilityGroup;
  @Input() name: string | undefined = undefined;
  @Input() description: string | undefined = undefined;
  @Input() expanded = false;
  @Input() alt = 'base';
  @Input() addText?: string;
  @Input() count?: number;

  @Output() add = new EventEmitter<void>();
  @Output() edit = new EventEmitter<void>();
  @Output() delete = new EventEmitter<void>();

  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger;

  get isGroup(): boolean {
    return isAbilityGroup(this.type);
  }

  get metadata(): AbilityMetadata {
    if (this.isGroup) {
      return getAbilityGroupMetadata(getAbilityGroup(this.type));
    } else {
      return getAbilityMetadata(getAbilityType(this.type));
    }
  }

  getImage(): string {
    return `/assets/integrations/${this.metadata.icon}.svg`;
  }

  getName(): string | undefined {
    return this.name || this.metadata.name;
  }

  getDescription(): string | undefined {
    return this.description || this.metadata.description;
  }

  handleToggle(): void {
    this.expanded = !this.expanded;
  }

  handlePlus(event: Event): void {
    event.stopPropagation();
    this.add.emit();
  }

  get isMenuOpen(): boolean {
    return this.menuTrigger?.menuOpen ?? false;
  }
}
