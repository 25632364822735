import { ConnectorAbility } from './connector.ability';
import { AbilityMetadata } from '@squidcloud/console-web/app/studio/agent/abilities/types';

export class ApiAbility extends ConnectorAbility {
  static override metadata: AbilityMetadata = {
    name: 'API',
    description: 'Interact with your API using natural language.',
    icon: 'api',
  };
}
