/* eslint-disable jsdoc/require-jsdoc */

import { RpcManager } from './rpc.manager';
import { NativeQueryContext } from '../../internal-common/src/public-types-backend/native-query.public-context';

/** @internal */
export class NativeQueryManager {
  constructor(private readonly rpcManager: RpcManager) {}

  async executeNativeQuery<T>(request: NativeQueryContext): Promise<T> {
    return this.rpcManager.post('native-query/execute', request);
  }
}
