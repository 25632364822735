import {
  MmCreateMatchMakerRequest,
  MmDeleteEntityRequest,
  MmDeleteMatchMakerRequest,
  MmFindMatchesForEntityRequest,
  MmFindMatchesForEntityResponse,
  MmFindMatchesRequest,
  MmFindMatchesResponse,
  MmGetEntityRequest,
  MmGetEntityResponse,
  MmGetMatchMakerRequest,
  MmGetMatchMakerResponse,
  MmInsertEntitiesRequest,
  MmInsertEntityRequest,
  MmListEntitiesRequest,
} from '../../internal-common/src/types/ai-matchmaking.types';
import { RpcManager } from './rpc.manager';
import {
  MmEntity,
  MmEntityMatch,
  MmFindMatchesOptions,
  MmListEntitiesOptions,
  MmMatchMaker,
} from '../../internal-common/src/public-types/ai-matchmaking.types';

/**
 * Client for the AI Matchmaking service. This service allows you to create matchmakers and insert entities into them.
 * The service will then find matches for the entities.
 * @category AI
 */
export class AiMatchMakingClient {
  /** @internal */
  constructor(private readonly rpcManager: RpcManager) {}

  /**
   * Creates a new matchmaker with the given metadata.
   *
   * @param matchMaker - Object describing the matchmaker including ID, description (used as AI instructions), and categories.
   * @returns A MatchMaker instance to perform entity-level operations.
   */
  async createMatchMaker(matchMaker: MmMatchMaker): Promise<MatchMaker> {
    const createdMatchMaker = await this.rpcManager.post<MmMatchMaker, MmCreateMatchMakerRequest>(
      'matchMaking/createMatchMaker',
      {
        matchMaker,
      },
    );
    return new MatchMaker(createdMatchMaker, this.rpcManager);
  }

  /**
   * Retrieves an existing matchmaker by its ID.
   *
   * @param matchMakerId - ID of the matchmaker to retrieve.
   * @returns A MatchMaker instance if found, otherwise undefined.
   */
  async getMatchMaker(matchMakerId: string): Promise<MatchMaker | undefined> {
    const matchMakerResponse = await this.rpcManager.post<MmGetMatchMakerResponse, MmGetMatchMakerRequest>(
      'matchMaking/getMatchMaker',
      {
        matchMakerId,
      },
    );
    const matchMaker = matchMakerResponse.matchMaker;
    if (!matchMaker) {
      return undefined;
    }
    return new MatchMaker(matchMaker, this.rpcManager);
  }

  /**
   * Lists all existing matchmakers.
   */
  async listMatchMakers(): Promise<Array<MmMatchMaker>> {
    return await this.rpcManager.post<Array<MmMatchMaker>, unknown>('matchMaking/listMatchMakers', {});
  }
}

/**
 * Represents a matchmaker. You can insert entities into the matchmaker and find matches for them.
 *
 * A matchmaker has one or more categories. When inserting an entity, it is assigned to a category.
 * When finding matches, you can specify which categories to use for source and target.
 *
 * The matchmaker description is used as a prompt or instruction to the AI to guide the matchmaking behavior.
 * @category AI
 */
export class MatchMaker {
  public readonly id: string;

  /** @internal */
  constructor(
    private readonly matchMaker: MmMatchMaker,
    private readonly rpcManager: RpcManager,
  ) {
    this.id = matchMaker.id;
  }

  /**
   * Adds a new entity to the matchmaker, replacing existing entity with the same ID if it exists.
   *
   * @param entity - The entity to insert.
   */
  async insertEntity(entity: MmEntity): Promise<void> {
    await this.rpcManager.post<void, MmInsertEntityRequest>('matchMaking/insertEntity', {
      matchMakerId: this.id,
      entity,
    });
  }

  /**
   * Inserts multiple entities into the matchmaker. Replaces existing entities with matching IDs.
   *
   * @param entities - Array of entities to insert.
   */
  async insertManyEntities(entities: Array<MmEntity>): Promise<void> {
    await this.rpcManager.post<void, MmInsertEntitiesRequest>('matchMaking/insertEntities', {
      matchMakerId: this.id,
      entities,
    });
  }

  /**
   * Deletes the matchmaker and all its associated data.
   */
  async delete(): Promise<void> {
    await this.rpcManager.post<void, MmDeleteMatchMakerRequest>('matchMaking/deleteMatchMaker', {
      matchMakerId: this.id,
    });
  }

  /**
   * Deletes a specific entity from the matchmaker.
   *
   * @param entityId - The ID of the entity to delete.
   */
  async deleteEntity(entityId: string): Promise<void> {
    await this.rpcManager.post<void, MmDeleteEntityRequest>('matchMaking/deleteEntity', {
      entityId,
      matchMakerId: this.id,
    });
  }

  /**
   * Finds matches for an entity already inserted into the matchmaker.
   *
   * @param entityId - ID of the entity to match.
   * @param options - Optional filters and controls for matching.
   * @returns Array of entity matches.
   */
  async findMatches(entityId: string, options: MmFindMatchesOptions = {}): Promise<Array<MmEntityMatch>> {
    const response = await this.rpcManager.post<MmFindMatchesResponse, MmFindMatchesRequest>(
      'matchMaking/findMatches',
      {
        matchMakerId: this.id,
        entityId,
        options,
      },
    );
    return response.matches;
  }

  /**
   * Finds matches for an entity not yet inserted into the matchmaker.
   *
   * @param entity - The entity object (excluding ID and metadata).
   * @param options - Optional filters and controls for matching.
   * @returns Array of entity matches.
   */
  async findMatchesForEntity(
    entity: Omit<MmEntity, 'metadata' | 'id'>,
    options: MmFindMatchesOptions = {},
  ): Promise<Array<MmEntityMatch>> {
    const response = await this.rpcManager.post<MmFindMatchesForEntityResponse, MmFindMatchesForEntityRequest>(
      'matchMaking/findMatchesForEntity',
      {
        matchMakerId: this.id,
        entity,
        options,
      },
    );
    return response.matches;
  }

  /**
   * Lists entities currently in the matchmaker that belong to the specified category.
   *
   * @param categoryId - The category to filter entities by.
   * @param options - Pagination and filtering options.
   * @returns Array of entities in the category.
   */
  async listEntities(categoryId: string, options: MmListEntitiesOptions = {}): Promise<Array<MmEntity>> {
    return await this.rpcManager.post<Array<MmEntity>, MmListEntitiesRequest>('matchMaking/listEntities', {
      categoryId,
      options,
      matchMakerId: this.id,
    });
  }

  /**
   * Retrieves a single entity from the matchmaker by its ID.
   *
   * @param entityId - ID of the entity to retrieve.
   * @returns The entity if found, or undefined.
   */
  async getEntity(entityId: string): Promise<MmEntity | undefined> {
    const entityResponse = await this.rpcManager.post<MmGetEntityResponse, MmGetEntityRequest>(
      'matchMaking/getEntity',
      {
        matchMakerId: this.id,
        entityId,
      },
    );
    return entityResponse.entity;
  }

  /**
   * Returns the matchmaker metadata including description, categories, and ID.
   */
  getMatchMakerDetails(): MmMatchMaker {
    return this.matchMaker;
  }
}
