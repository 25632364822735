export const CONTACT_LIST_COLLECTION = 'contactList';

export type CpContact = {
  email: string;
  name?: string;
  phone?: string;
  message?: string;
  referralSource?: string;
  captcha: string;
  location?: ContactLocation;
  reason?: ContactReason;
  submissionDate: Date;
};

export type ContactReason = 'demo' | 'contact_developers' | 'billing_or_quota';

export type ContactLocation = 'ai-chat' | 'welcome' | 'contact-page' | 'contact-us-dialog' | 'registration-page';

export const ZOOM_CLIENT_ID = '3pLZuwemTM6zfrvJBsMA';

export const ZOOM_HOST_EMAILS = ['leslie@getsquid.ai', 'etai@squid.cloud'];
