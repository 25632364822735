import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';
import { AiAgentAndContexts, StudioService } from '@squidcloud/console-web/app/studio/studio.service';
import { GlobalUiService } from '@squidcloud/console-web/app/global/services/global-ui.service';
import { SnackBarService } from '@squidcloud/console-web/app/global/services/snack-bar.service';
import {
  AGENT_ID_PARAMETER,
  getPageParameter,
  getRequiredPageParameter,
  TAB_ID_PARAMETER,
} from '@squidcloud/console-web/app/utils/http-utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StudioCreateComponent } from '@squidcloud/console-web/app/studio/agent/create/create.component';
import { StudioTestComponent } from '@squidcloud/console-web/app/studio/agent/test/test.component';
import { isNonNullable, truthy } from 'assertic';
import { AuditComponent } from '@squidcloud/console-web/app/studio/agent/audit/audit.component';
import { AiEmbeddedChatWidgetDialogComponent } from '@squidcloud/console-web/app/integrations/schema/ai-agents/ai-embedded-chat-widget-dialog/ai-embedded-chat-widget-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AgentSettingsComponent } from '@squidcloud/console-web/app/studio/agent/agent-settings/agent-settings.component';
import { OVERVIEW_TAB_ID } from '@squidcloud/console-web/app/studio/studio.utils';

@Component({
  selector: 'app-agent',
  templateUrl: './agent.component.html',
  styleUrl: './agent.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class StudioAgentComponent {
  selectedIndex = 0;
  readonly tabs = [
    {
      id: OVERVIEW_TAB_ID,
      name: 'Overview',
      component: StudioCreateComponent,
    },
    {
      id: 'test',
      name: 'Test Agent',
      component: StudioTestComponent,
    },
    // {
    //   id: 'how-to-use',
    //   name: 'How to Use',
    //   component: StudioHowToUseComponent,
    // },
    {
      id: 'audit-log',
      name: 'Audit Log',
      component: AuditComponent,
    },
    {
      id: 'settings',
      name: 'Agent Settings',
      component: AgentSettingsComponent,
    },
  ];

  private readonly tabIdToIndexMap: Record<string, number> = this.tabs.reduce(
    (acc, tab, index) => {
      acc[tab.id] = index;
      return acc;
    },
    {} as Record<string, number>,
  );

  private readonly indexToTabIdMap: Record<number, string> = Object.entries(this.tabIdToIndexMap).reduce(
    (acc, [key, value]) => {
      acc[value] = key;
      return acc;
    },
    {} as Record<number, string>,
  );

  readonly agentId: string;
  readonly agentAndContexts$: Observable<AiAgentAndContexts | undefined>;
  readonly application$ = this.applicationService.currentApplication$;

  constructor(
    activatedRoute: ActivatedRoute,
    private readonly applicationService: ApplicationService,
    private readonly studioService: StudioService,
    private readonly globalUiService: GlobalUiService,
    private readonly snackBar: SnackBarService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.agentId = getRequiredPageParameter(AGENT_ID_PARAMETER, activatedRoute.snapshot);
    this.agentAndContexts$ = this.studioService.observeAgent(this.agentId);

    this.applicationService.currentApplication$.pipe(takeUntilDestroyed()).subscribe(application => {
      void this.studioService.initialize(truthy(application).appId);
    });

    activatedRoute.params.pipe(takeUntilDestroyed()).subscribe(() => {
      const tabId = getPageParameter(TAB_ID_PARAMETER, activatedRoute.snapshot) || this.indexToTabIdMap[0];
      this.selectedIndex = this.tabIdToIndexMap[tabId];
      this.cdr.markForCheck();
    });
  }

  showEditAgentDialog(): void {
    const { agent } = this.studioService.getAgentAndContextsOrFail(this.agentId);

    void this.globalUiService.showDialogWithForm<{
      id: string;
      description: string;
      isPublic: boolean;
      auditLog: boolean;
    }>({
      title: `Edit agent?`,
      minRole: 'ADMIN',
      textLines: [],
      submitButtonText: 'Update',
      onDelete: () => {
        this.showDeleteProfileDialog();
      },
      formElements: [
        {
          type: 'input',
          required: true,
          nameInForm: 'id',
          label: 'Agent ID',
          defaultValue: this.agentId,
          readonly: true,
        },
        {
          type: 'textarea',
          required: false,
          nameInForm: 'description',
          label: 'Description',
          attributes: {
            autosize: true,
            minRows: 5,
            maxRows: 10,
          },
          defaultValue: agent.description,
        },
        {
          type: 'boolean',
          required: true,
          nameInForm: 'isPublic',
          label: 'Make agent public',
          description:
            'Public agents are accessible without an API key. View the <a href="https://docs.getsquid.ai/docs/security/security-rules/secure-ai-agents" target="_blank">documentation</a> to learn more about securing public agents.',
          defaultValue: isNonNullable(agent?.isPublic) ? agent?.isPublic : false,
        },
        {
          type: 'boolean',
          required: true,
          nameInForm: 'auditLog',
          label: 'Enable audit logs',
          // description: // 'Enable this setting to maintain a detailed log of the agent’s actions and interactions
          // for auditing purposes.',
          defaultValue: isNonNullable(agent?.auditLog) ? agent?.auditLog : false,
        },
      ],
      onSubmit: async (data): Promise<string | void> => {
        if (!data) return;
        const { id, description, isPublic, auditLog } = data;

        try {
          await this.studioService.upsertAgent(id, {
            description,
            isPublic,
            auditLog,
          });
          this.snackBar.success('Agent updated');
        } catch (error: unknown) {
          const message = error instanceof Error ? error.message : 'Unable to update profile';
          this.snackBar.warning(message);
        }
      },
    });
  }

  showDeleteProfileDialog(): void {
    void this.globalUiService.showDeleteDialog(
      `Deleting this agent cannot be undone or recovered.`,
      async () => {
        try {
          await this.studioService.deleteAgent(this.agentId);
          void this.router.navigate([
            '/application',
            this.applicationService.getCurrentApplicationOrFail().appId,
            'studio',
          ]);
          this.snackBar.success('Agent deleted');
        } catch {
          this.snackBar.warning('Unable to delete');
        }
      },
      `Arrrrr you sure?`,
    );
  }

  showEmbedWidgetDialog(): void {
    AiEmbeddedChatWidgetDialogComponent.show(this.dialog, { agentId: this.agentId });
  }

  async changeUrl(index: number | null): Promise<void> {
    if (index === null) index = 0;
    await this.router.navigate([
      '/application',
      this.applicationService.getCurrentApplicationOrFail().appId,
      'studio',
      this.agentId,
      this.indexToTabIdMap[index],
    ]);
  }
}
