import { AiAgentChatOptions, AiStatusMessage } from '../../../internal-common/src/public-types/ai-agent.public-types';
import { Observable, Subject } from 'rxjs';
import { ClientRequestId } from '../../../internal-common/src/public-types/communication.public-types';

/**
 * Response format for transcribing audio and generating a chat response.
 * Contains the transcribed text and a stream of AI-generated responses.
 * @category AI
 */
export interface TranscribeAndChatResponse {
  /** Transcribed text from the audio input. */
  transcribedPrompt: string;
  /** Stream of AI-generated responses. */
  responseStream: Observable<string>;
}

/**
 * Response format for transcribing audio and generating a text and voice response.
 * Includes the transcribed prompt, the AI-generated response as text, and an audio file.
 * @category AI
 */
export interface TranscribeAndAskWithVoiceResponse {
  /** Transcribed text from the audio input. */
  transcribedPrompt: string;
  /** AI-generated response as a string. */
  responseString: string;
  /** AI-generated voice response as an audio file. */
  voiceResponseFile: File;
}

/**
 * Response format for AI-generated voice responses.
 * Contains the AI-generated text response and the corresponding audio file.
 * @category AI
 */
export interface AskWithVoiceResponse {
  /** AI-generated response as a string. */
  responseString: string;
  /** AI-generated voice response as an audio file. */
  voiceResponseFile: File;
}

/**
 * Chat options that exclude voice-specific configurations.
 * @category AI
 */
export type ChatOptionsWithoutVoice = Omit<AiAgentChatOptions, 'voiceOptions'>;

/**
 * Options for AI-generated responses that exclude voice-specific settings and smooth typing behavior.
 * @category AI
 */
export type AskOptionsWithoutVoice = Omit<AiAgentChatOptions, 'voiceOptions' | 'smoothTyping'>;

/** @internal */
export const EMPTY_CHAT_ID = `__squid_empty_chat_id`;

/** @internal */
export type AiOngoingChatSequencesMap = Record<
  ClientRequestId,
  Subject<{
    /** @internal */
    value: string;
    /** @internal */
    complete: boolean;
    /** @internal */
    tokenIndex?: number;
  }>
>;

/** @internal */
export type AiStatusUpdatesMap = Record<string, Record<string, Subject<AiStatusMessage>>>;
