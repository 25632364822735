import { assertTruthy } from 'assertic';
import { BehaviorSubject, filter, firstValueFrom, map, Observable, race, Subject, switchAll, take } from 'rxjs';
import { compareValues, getInPath, isNil } from '../../../internal-common/src/utils/object';
import { SnapshotEmitter } from './snapshot-emitter';

/**
 * The state of a pagination.
 * @category Database
 */
export interface PaginationState<ReturnType> {
  /** The page data. */
  data: Array<ReturnType>;
  /** Whether there is a next page. */
  hasNext: boolean;
  /** Whether there is a previous page. */
  hasPrev: boolean;
}

/**
 * Pagination options.
 * @category Database
 */
export interface PaginationOptions {
  /** Whether to show real-time updates. Defaults to true. */
  subscribe: boolean;

  /** The number of items in a page. Defaults to 100. */
  pageSize: number;
}

interface InternalState<ReturnType> {
  data: ReturnType[];
  extractedData: any[];
  numBefore: number;
  numAfter: number;
}

/**
 * Pagination provides a paginated view over a dataset, supporting navigation
 * through pages, real-time updates, and sorting based on predefined criteria.
 * @category Database
 */
export class Pagination<ReturnType> {
  private readonly paginateOptions: PaginationOptions;

  /* Invariants:
  internalStateObserver.value.data
  - always contains the pageSize elements that are on the page (fewer if we're on the last page)
  - always contains some elements before the first one on the page and some elements after
    the last one on the page, unless at the beginning/end.
  Note: it is possible that all the elements before/after the page get deleted. Our current strategy is to grab
  a full page of them and hope they don't all get deleted. An alternative strategy would be to check in dataReceived
  if numBefore and numAfter are getting low, and rerun the query in that case. However, in order to do that we would need to be
  able to check if we are at the next-to-last page, because numAfter can be as low as 1 in that case, and similarly for numBefore.

  firstElement
  - is an extracted document that may or may not be part of the data
  - the page consists of the first pageSize elements of data that are >= firstElement
  - firstElement === null refers to a hypothetical document that is ordered before all other documents.
  */
  private internalStateObserver = new BehaviorSubject<InternalState<ReturnType> | null>(null);
  private firstElement: any = null;

  private readonly isDestroyed = new BehaviorSubject(false);

  private templateSnapshotEmitter: SnapshotEmitter<ReturnType>;
  private snapshotSubject = new Subject<Observable<ReturnType[]>>();
  private onFirstPage = true;

  /* Used during reverse navigation:
  - When navigating to the last page, we set navigatingToLastPage to true and run a reverse query starting at the end of the query.
  - When navigating to a previous page, we set lastElement to the last element that should be on that page.
  In both situations, the variables are reset during the next call to dataReceived.
   */
  private navigatingToLastPage = false;
  private lastElement: any = null;

  /** @internal */
  constructor(snapshotEmitter: SnapshotEmitter<ReturnType>, options: Partial<PaginationOptions> = {}) {
    assertTruthy(
      snapshotEmitter.getSortOrders().length > 0,
      'Unable to paginate results. Please specify a sort order.',
    );

    this.snapshotSubject.pipe(switchAll()).subscribe(data => this.dataReceived(data));

    this.templateSnapshotEmitter = snapshotEmitter.clone();
    this.paginateOptions = { pageSize: 100, subscribe: true, ...options };
    this.goToFirstPage();
  }

  private goToFirstPage(): void {
    this.onFirstPage = true;
    const firstPageSnapshot = this.templateSnapshotEmitter
      .clone()
      .limit(this.paginateOptions.pageSize * 3)
      .snapshots(this.paginateOptions.subscribe);
    this.snapshotSubject.next(firstPageSnapshot);
  }

  private compareObjects(doc1: any, doc2: any): number {
    if (doc1 === doc2 || (isNil(doc1) && isNil(doc2))) {
      return 0;
    } else if (isNil(doc1)) {
      return -1;
    } else if (isNil(doc2)) {
      return 1;
    }
    const sortOrders = this.templateSnapshotEmitter.getSortOrders();
    for (const { fieldName, asc } of sortOrders) {
      const value1 = getInPath(doc1, fieldName);
      const value2 = getInPath(doc2, fieldName);
      const rc = compareValues(value1, value2);
      if (rc !== 0) {
        return asc ? rc : -rc;
      }
    }
    return 0;
  }

  private async dataReceived(data: Array<ReturnType>): Promise<void> {
    // Because documents might be deleted by the time we need them (if we are not subscribed), we save the extracted
    // data here. This is the only place we're allowed to call extractData.
    const extractedData = data.map(s => this.templateSnapshotEmitter.extractData(s));

    if (data.length === 0) {
      if (this.onFirstPage) {
        this.internalStateObserver.next({ numBefore: 0, numAfter: 0, data, extractedData });
      } else {
        this.goToFirstPage();
      }
      return;
    }

    if (this.firstElement === null) {
      if (this.lastElement !== null) {
        // We just executed a `prev` and we know what the last element is on the page but not the first.
        // We need to find the first element on the page instead, because that's our anchor.
        const numAfter = extractedData.filter(s => this.compareObjects(s, this.lastElement) === 1).length;
        this.firstElement = extractedData[data.length - numAfter - this.paginateOptions.pageSize];
        this.lastElement = null;
      } else if (this.navigatingToLastPage) {
        // We just executed a `last`.
        this.firstElement = extractedData[data.length - this.paginateOptions.pageSize];
        this.navigatingToLastPage = false;
      }
    }

    const numBefore = extractedData.filter(s => this.compareObjects(s, this.firstElement) === -1).length;
    const numAfter = Math.max(0, data.length - numBefore - this.paginateOptions.pageSize);

    // Current page is empty, go to previous page
    if (numBefore === data.length) {
      this.prevInternal({ numBefore, numAfter, data, extractedData });
      return;
    }

    this.internalStateObserver.next({ numBefore, numAfter, data, extractedData });
  }

  private doNewQuery(startingDoc: any, reverseOrder: boolean): void {
    this.onFirstPage = false;

    if (reverseOrder) {
      const newSnapshotEmitter = this.templateSnapshotEmitter
        .clone()
        .limit(this.paginateOptions.pageSize * 3)
        .flipSortOrder();
      if (startingDoc) {
        newSnapshotEmitter.addCompositeCondition(
          this.templateSnapshotEmitter.getSortOrders().map(sortOrder => {
            return {
              fieldName: sortOrder.fieldName,
              operator: sortOrder.asc ? '<=' : '>=',
              value: getInPath(startingDoc, sortOrder.fieldName) || null,
            };
          }),
        );
      }
      this.snapshotSubject.next(
        newSnapshotEmitter.snapshots(this.paginateOptions.subscribe).pipe(map(s => s.reverse())),
      );
    } else {
      const newSnapshotEmitter = this.templateSnapshotEmitter.clone().limit(this.paginateOptions.pageSize * 3);
      if (startingDoc) {
        newSnapshotEmitter.addCompositeCondition(
          this.templateSnapshotEmitter.getSortOrders().map(sortOrder => {
            return {
              fieldName: sortOrder.fieldName,
              operator: sortOrder.asc ? '>=' : '<=',
              value: getInPath(startingDoc, sortOrder.fieldName) || null,
            };
          }),
        );
      }
      this.snapshotSubject.next(newSnapshotEmitter.snapshots(this.paginateOptions.subscribe));
    }
  }

  private async waitForInternalState(): Promise<InternalState<ReturnType>> {
    const internalState = this.internalStateObserver.value;
    if (internalState !== null) {
      return internalState;
    }
    return await firstValueFrom(
      race(
        // It is possible that we get here and unsubscribe() was called. In that case we might not get any new state,
        // so to avoid stalling we return an empty page in that situation. (We can't return the last page we saw
        // because that has already been deleted, and also because it's possible that we've never seen any data.)
        this.isDestroyed.pipe(
          filter(Boolean),
          map(() => ({
            data: [],
            extractedData: [],
            numBefore: 0,
            numAfter: 0,
          })),
        ),
        this.internalStateObserver.pipe(
          filter((state): state is InternalState<ReturnType> => state !== null),
          take(1),
        ),
      ),
    );
  }

  private internalStateToState(internalState: InternalState<ReturnType>): PaginationState<ReturnType> {
    const { data, numBefore, numAfter, extractedData } = internalState;
    return {
      data: data
        .filter((_, i) => this.compareObjects(extractedData[i], this.firstElement) !== -1)
        .slice(0, this.paginateOptions.pageSize),
      hasNext: numAfter > 0,
      hasPrev: numBefore > 0,
    };
  }

  /** Unsubscribes from the pagination. */
  unsubscribe(): void {
    this.isDestroyed.next(true);
    this.isDestroyed.complete();

    this.internalStateObserver.complete();
    this.snapshotSubject.complete();
  }

  private prevInternal(internalState: InternalState<ReturnType>): void {
    const { numBefore, numAfter, extractedData } = internalState;

    this.firstElement = null;
    this.lastElement = extractedData[numBefore - 1];
    this.internalStateObserver.next(null);
    this.doNewQuery(extractedData[extractedData.length - numAfter - 1], true);
  }

  /** Returns a promise that resolves when the previous page of data is available. */
  async prev(): Promise<PaginationState<ReturnType>> {
    this.prevInternal(await this.waitForInternalState());
    return await this.waitForData();
  }

  /** Returns a promise that resolves when the next page of data is available. */
  async next(): Promise<PaginationState<ReturnType>> {
    const { numBefore, extractedData } = await this.waitForInternalState();

    // Setting the firstElement implicitly moves us to the next page. If we are on the last page, we don't change
    // firstElement because we want next() to be a no-op.
    if (numBefore + this.paginateOptions.pageSize < extractedData.length) {
      this.firstElement = extractedData[numBefore + this.paginateOptions.pageSize];
    }
    this.internalStateObserver.next(null);
    this.doNewQuery(extractedData[numBefore], false);

    return await this.waitForData();
  }

  /** Returns a promise that resolves when the page data is available. */
  async waitForData(): Promise<PaginationState<ReturnType>> {
    return this.internalStateToState(await this.waitForInternalState());
  }

  /** Returns an observable that emits the current state of the pagination. */
  observeState(): Observable<PaginationState<ReturnType>> {
    return this.internalStateObserver.pipe(
      filter((state): state is InternalState<ReturnType> => state !== null),
      map(state => {
        return this.internalStateToState(state);
      }),
    );
  }

  /** Jumps to the first page of the pagination. */
  async first(): Promise<PaginationState<ReturnType>> {
    await this.waitForInternalState();
    this.internalStateObserver.next(null);
    this.firstElement = null;
    this.lastElement = null;
    this.goToFirstPage();
    return await this.waitForData();
  }

  /** Refreshes the current page of the pagination. */
  async refreshPage(): Promise<PaginationState<ReturnType>> {
    const { extractedData } = await this.waitForInternalState();
    this.internalStateObserver.next(null);
    if (this.onFirstPage) {
      this.goToFirstPage();
    } else {
      this.doNewQuery(extractedData[0], false);
    }
    return await this.waitForData();
  }

  /**
   * Jumps to the last page of the pagination. This page will always have the last `pageSize` elements of the
   * underlying query, regardless of whether the total document count is evenly divisible by the page size.
   */
  async last(): Promise<PaginationState<ReturnType>> {
    await this.waitForInternalState();
    this.internalStateObserver.next(null);
    this.firstElement = null;
    this.lastElement = null;
    this.navigatingToLastPage = true;

    const lastPageSnapshot = this.templateSnapshotEmitter
      .clone()
      .limit(this.paginateOptions.pageSize * 3)
      .flipSortOrder()
      .snapshots(this.paginateOptions.subscribe)
      .pipe(map(s => s.reverse()));
    this.snapshotSubject.next(lastPageSnapshot);

    return await this.waitForData();
  }
}
