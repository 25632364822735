import { ChangeDetectorRef } from '@angular/core';
import { AppId, Pagination, Squid } from '@squidcloud/client';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  filter,
  firstValueFrom,
  interval,
  NEVER,
  of,
  ReplaySubject,
  switchMap,
} from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IntegrationService } from '@squidcloud/console-web/app/integrations/integration.service';
import { ActivatedRoute } from '@angular/router';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';
import { CpApplication } from '@squidcloud/console-common/types/application.types';

interface LogsTimestampOption {
  label: string;
  value: number | null;
}

export abstract class LogsTable<T, U extends { id: string; timestamp: Date }> {
  private readonly initialized$ = new ReplaySubject<boolean>(1);

  readonly allTimestampOptions: Array<LogsTimestampOption> = [
    { label: 'All time', value: 0 },
    { label: 'Last 5 minutes', value: 5 },
    { label: 'Last 15 minutes', value: 15 },
    { label: 'Last 30 minutes', value: 30 },
    { label: 'Last 1 hour', value: 60 },
    { label: 'Last 3 hours', value: 180 },
    { label: 'Last day', value: 1440 },
  ];

  /** The period in minutes from Date.now() to display log messages. */
  logDisplayPeriodInMinutes: number | null | undefined;

  receivedDataFromServer = false;
  hasNextPage = false;
  hasPreviousPage = false;
  protected serverRequestInProgress$ = new BehaviorSubject(false);

  readonly logEntriesObs = new ReplaySubject<Array<U>>(1);
  private readonly paginationSubject = new BehaviorSubject<Pagination<T> | undefined>(undefined);

  constructor(
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly applicationService: ApplicationService,
    protected readonly integrationService: IntegrationService,
    protected readonly squid: Squid,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    combineLatest([this.initialized$, this.applicationService.currentApplication$])
      .pipe(takeUntilDestroyed())
      .subscribe(async ([_, application]) => {
        this.handleApplicationChange(application);
        void this.executeQuery();
      });

    combineLatest([this.initialized$, this.paginationSubject])
      .pipe(
        switchMap(([_, pagination]) => pagination?.observeState() || of(undefined)),
        catchError(() => {
          this.serverRequestInProgress$.next(false);
          this.cdr.markForCheck();
          return NEVER;
        }),
        takeUntilDestroyed(),
      )
      .subscribe(paginationState => {
        this.serverRequestInProgress$.next(false);

        if (!paginationState) {
          this.hasNextPage = false;
          this.hasPreviousPage = false;
          this.logEntriesObs.next([]);
          this.cdr.markForCheck();
          return;
        }
        this.hasNextPage = paginationState.hasNext;
        this.hasPreviousPage = paginationState.hasPrev;
        this.logEntriesObs.next(paginationState.data.map(this.parseLogEntry));

        this.receivedDataFromServer = true;
        this.cdr.markForCheck();
      });

    combineLatest([this.initialized$, interval(10_000)])
      .pipe(takeUntilDestroyed())
      .subscribe(async () => {
        if (!this.paginationSubject.value || this.serverRequestInProgress$.value) {
          return;
        }
        this.serverRequestInProgress$.next(true);
        await this.paginationSubject.value.refreshPage();
      });
  }

  initialize(): void {
    this.handleApplicationChange(this.applicationService.getCurrentApplication());
    this.initialized$.next(true);
  }

  abstract handleApplicationChange(application: CpApplication | undefined | null): void;

  abstract getPagination(appId: AppId): Pagination<T>;

  abstract parseLogEntry(_: T): U;

  abstract showLogEntryDialog(logEntry: U): void;

  protected async executeQuery(): Promise<void> {
    const application = this.applicationService.getCurrentApplication();
    if (!application) {
      return;
    }
    const { appId } = application;
    const pagination = this.getPagination(appId);
    this.paginationSubject.next(pagination);
    this.serverRequestInProgress$.next(true);

    this.cdr.markForCheck();
  }

  handleLogDisplayPeriodInMinutesChanged(value: number): void {
    this.logDisplayPeriodInMinutes = value;
    void this.executeQuery();
  }

  async previousPage(): Promise<void> {
    await this.waitForRefreshToFinish();
    const { value } = this.paginationSubject;
    if (value) {
      this.serverRequestInProgress$.next(true);
      void value.prev();
    }
  }

  async nextPage(): Promise<void> {
    await this.waitForRefreshToFinish();
    const { value } = this.paginationSubject;
    if (value) {
      this.serverRequestInProgress$.next(true);
      void value.next();
    }
  }

  protected async waitForRefreshToFinish(): Promise<void> {
    await firstValueFrom(this.serverRequestInProgress$.pipe(filter(value => !value)));
  }
}
