@let application = application$ | async;
@let bundleDataTableData = bundleDataTableData$ | async;
@let openApiSpecUrls = openApiUrls$ | async;
@if (bundleDataTableData && application) {
  <div class="table_container">
    <bundle-data-table
      [defaultSortColumnIndex]="2"
      [bundleDataTableData]="bundleDataTableData"
      stateKey="openapi"
      (selectedPeriodTypeChange)="periodType$.next($event)"
    />
    <div class="spec_link" *ngFor="let openApiSpecUrl of openApiSpecUrls">
      The {{ openApiSpecUrl.name }} OpenAPI specification is available
      <a [href]="openApiSpecUrl.url" target="_blank">here</a>
    </div>
  </div>
} @else if (bundleDataTableData === null || application === null) {
  <loading-indicator></loading-indicator>
} @else {
  <div class="functions_doc_wrapper">
    <openapi-tutorial />
  </div>
}
