import {
  DeleteApiKeyRequest,
  DeleteSecretRequest,
  GenerateNewApiKeyRequest,
  GetApiKeyRequest,
  GetCustomSecretRequest,
  SetSecretRequest,
} from '../../internal-common/src/types/secret.types';
import { SecretEntry, SecretKey, SecretValue, SetSecretRequestEntry } from './public-types';
import { RpcManager } from './rpc.manager';

/**
 * SecretClient provides methods for managing secrets, including retrieval,
 * update, and deletion of stored secrets and API keys.
 * @category Secret
 */
export class SecretClient {
  /** @internal */
  constructor(private readonly rpcManager: RpcManager) {}

  /**
   * Retrieves a stored custom secret by its key.
   *
   * @param key The key of the secret to retrieve.
   * @returns A promise resolving to the secret entry if found, or `undefined` if not found.
   */
  async get(key: SecretKey): Promise<SecretEntry | undefined> {
    const request: GetCustomSecretRequest = { key };
    return (await this.rpcManager.post('secret/get', request)) || undefined;
  }

  /**
   * Retrieves all stored custom secrets.
   *
   * @returns A promise resolving to a record of secret entries keyed by their secret keys.
   */
  getAll(): Promise<Record<SecretKey, SecretEntry>> {
    return this.rpcManager.post('secret/getAll', {});
  }

  /**
   * Creates or updates a single secret entry.
   *
   * @param key The secret key to upsert.
   * @param value The value associated with the secret.
   * @returns A promise resolving to the created or updated secret entry.
   */
  async upsert(key: SecretKey, value: SecretValue): Promise<SecretEntry> {
    return this.upsertMany([{ key, value }]).then(entries => entries[0]);
  }

  /**
   * Creates or updates multiple secret entries at once.
   *
   * @param entries An array of secret entries to set, each containing a key and value.
   * @returns A promise resolving to an array of created or updated secret entries.
   */
  async upsertMany(entries: Array<SetSecretRequestEntry>): Promise<Array<SecretEntry>> {
    if (entries.length === 0) {
      return [];
    }
    const request: SetSecretRequest = { entries };
    return this.rpcManager.post('secret/upsert', request);
  }

  /**
   * Deletes a specified secret key.
   * If the secret is missing, it will be ignored, and the operation will proceed without failure.
   *
   * @param key - The secret key to delete.
   * @param force - If set to true, forces deletion regardless of the secret's usage status across any enabled
   *   integrations. Default is false.
   * @returns A promise that resolves when the deletion request is complete.
   */
  delete(key: SecretKey, force = false): Promise<void> {
    const request: DeleteSecretRequest = { keys: [key], force };
    return this.rpcManager.post('secret/delete', request);
  }

  /**
   * Deletes a list of specified secret keys.
   * If any secret in the list is missing, it will be ignored, and the operation will proceed without failure.
   *
   * @param keys - An array of secret keys to delete.
   * @param force - If set to true, forces deletion regardless of the secret's usage status across any enabled
   *   integrations. Default is false.
   * @returns A promise that resolves when the deletion request is complete.
   */
  async deleteMany(keys: Array<SecretKey>, force = false): Promise<void> {
    if (keys.length === 0) {
      return;
    }
    const request: DeleteSecretRequest = { keys, force };
    return this.rpcManager.post('secret/delete', request);
  }

  /**
   * Provides access to API key secret management operations.
   *
   * @returns An instance of {@link ApiKeysSecretClient} for managing API keys.
   */
  get apiKeys(): ApiKeysSecretClient {
    return new ApiKeysSecretClient(this.rpcManager);
  }
}

/**
 * ApiKeysSecretClient manages API key secrets, providing methods
 * for retrieval, creation, and deletion of API keys.
 * @category Secret
 */
export class ApiKeysSecretClient {
  /** @internal */
  constructor(private readonly rpcManager: RpcManager) {}

  /**
   * Retrieves a stored API key by its key.
   *
   * @param key The API key to retrieve.
   * @returns A promise resolving to the secret entry or undefined if not found.
   */
  get(key: SecretKey): Promise<SecretEntry | undefined> {
    const request: GetApiKeyRequest = { key };
    return this.rpcManager.post('secret/api-key/get', request);
  }

  /**
   * Retrieves all secrets.
   *
   * @returns A promise resolving to a record of all stored secret entries.
   */
  getAll(): Promise<Record<SecretKey, SecretEntry>> {
    return this.rpcManager.post('secret/api-key/getAll', {});
  }

  // eslint-disable-next-line jsdoc/require-jsdoc
  upsert(key: SecretKey): Promise<SecretEntry> {
    const request: GenerateNewApiKeyRequest = { key };
    return this.rpcManager.post('secret/api-key/upsert', request);
  }

  /**
   * Deletes a specified API key.
   *
   * @param key The key of the API key to delete.
   * @returns A promise resolving once deletion is complete.
   */
  delete(key: SecretKey): Promise<void> {
    const request: DeleteApiKeyRequest = { key };
    return this.rpcManager.post('secret/api-key/delete', request);
  }
}
