import { Directive, Input, ViewContainerRef } from '@angular/core';

/**
 * Asserts the argument type passed to the template.
 * Without this guard the template parameter is interpreted as 'any'.
 * Example: <ng-template let-element="element" [typeGuard]="{element: string}">
 */
@Directive({
  selector: '[typeGuard]',
  standalone: true,
})
export class TemplateTypeGuardDirective<T> {
  @Input()
  typeGuard?: T;

  // This eslint suppression is needed to avoid IDEA bug:
  // the directive is highlighted red when used in templates without it.
  constructor(_: ViewContainerRef) {}

  static ngTemplateContextGuard<T>(_: TemplateTypeGuardDirective<T>, _ctx: unknown): _ctx is T {
    return true;
  }
}

/**
 * Returns an empty object typed as T.
 * The returned object should never be used by value, but only by type.
 * This helper method is designed to be used with `TemplateTypeGuardDirective`.
 */
export function typeGuard<T>(): T {
  return {} as unknown as T;
}
