import { IntegrationType } from '../public-types/integration.public-types';

/**
 * Returns a description used by the user (top-level) agent to call the integration's built-in AI agent.
 * Used as an AI function description that represents the integration agent in the parent (user) agent context.
 */
export function getIntegrationAgentDefaultFunctionDescription(integrationType: IntegrationType): string {
  const getAction = (type: IntegrationType): string => {
    switch (type) {
      case 'active_directory':
        return 'query or manage Active Directory users and groups.';
      case 'ai_agents':
        return 'interact with AI agents for advanced automation.';
      case 'ai_chatbot':
        return 'operate AI-powered chatbots for conversational tasks.';
      case 'algolia':
        return 'perform searches or manage indexing in Algolia.';
      case 'alloydb':
        return 'query or manage data in Google AlloyDB.';
      case 'api':
        return 'execute API requests or integrate with RESTful services.';
      case 'auth0':
        return 'manage authentication or users in Auth0.';
      case 'azure_cosmosdb':
        return 'query or manage data in Azure Cosmos DB.';
      case 'azure_postgresql':
        return 'query or update schemas in Azure PostgreSQL.';
      case 'azure_sql':
        return 'query or manage Azure SQL databases.';
      case 'bigquery':
        return 'run analytics queries or manage data in Google BigQuery.';
      case 'built_in_db':
        return 'query or manage the built-in database.';
      case 'built_in_gcs':
        return 'store or retrieve files in built-in Google Cloud Storage.';
      case 'built_in_queue':
        return 'process or manage tasks in the built-in queue.';
      case 'built_in_s3':
        return 'store or retrieve files in built-in Amazon S3 storage.';
      case 'cassandra':
        return 'query or manage data in Cassandra.';
      case 'clickhouse':
        return 'execute queries or manage data in ClickHouse analytics database.';
      case 'cloudsql':
        return 'query or manage Google Cloud SQL databases.';
      case 'cockroach':
        return 'query or update schemas in CockroachDB.';
      case 'cognito':
        return 'manage users or authentication in AWS Cognito.';
      case 'confluence':
        return 'retrieve or update documents in Confluence.';
      case 'confluent':
        return 'manage or interact with Kafka instances in Confluent.';
      case 'datadog':
        return 'monitor or analyze data in Datadog.';
      case 'db2':
        return 'query or manage IBM Db2 databases.';
      case 'descope':
        return 'handle authentication or user management in Descope.';
      case 'documentdb':
        return 'query or manage AWS DocumentDB.';
      case 'dynamodb':
        return 'retrieve or update data in DynamoDB.';
      case 'elastic_enterprise_search':
        return 'perform searches or manage indexing in Elastic Enterprise Search.';
      case 'elastic_observability':
        return 'monitor or analyze systems using Elastic Observability.';
      case 'elastic_search':
        return 'run searches or manage indexes in Elasticsearch.';
      case 'firebase_auth':
        return 'authenticate users or manage authentication in Firebase Auth.';
      case 'firestore':
        return 'query or update data in Firestore.';
      case 'gcs':
        return 'upload or retrieve files from Google Cloud Storage.';
      case 'google_docs':
        return 'retrieve or modify documents in Google Docs.';
      case 'google_drive':
        return 'store or manage files in Google Drive.';
      case 'graphql':
        return 'execute GraphQL queries or manage schemas.';
      case 'hubspot':
        return 'manage contacts or deals in HubSpot CRM.';
      case 'jira':
        return 'manage or update issues in Jira.';
      case 'jwt_hmac':
        return 'sign or validate JWTs with HMAC.';
      case 'jwt_rsa':
        return 'sign or validate JWTs with RSA.';
      case 'kafka':
        return 'stream messages or manage topics in Kafka.';
      case 'linear':
        return 'manage or track tasks in Linear.';
      case 'mariadb':
        return 'query or manage MariaDB databases.';
      case 'monday':
        return 'manage projects or tasks in Monday.com.';
      case 'mongo':
        return 'query or update MongoDB collections.';
      case 'mssql':
        return 'query or manage Microsoft SQL Server databases.';
      case 'mysql':
        return 'query or update schemas in MySQL.';
      case 'newrelic':
        return 'monitor or analyze applications using New Relic.';
      case 'okta':
        return 'manage authentication or identity in Okta.';
      case 'onedrive':
        return 'store or retrieve files from OneDrive.';
      case 'oracledb':
        return 'query or manage Oracle databases.';
      case 'pinecone':
        return 'perform or manage vector searches in Pinecone.';
      case 'postgres':
        return 'query or update schemas in PostgreSQL.';
      case 'redis':
        return 'store or retrieve key-value data in Redis.';
      case 's3':
        return 'upload or retrieve files from Amazon S3.';
      case 'salesforce_crm':
        return 'manage contacts, leads, or deals in Salesforce.';
      case 'sap_hana':
        return 'query or manage SAP HANA databases.';
      case 'sentry':
        return 'track or monitor errors in Sentry.';
      case 'servicenow':
        return 'retrieve, update, or summarize data in ServiceNow.';
      case 'snowflake':
        return 'run queries or manage data in Snowflake.';
      case 'spanner':
        return 'query or manage data in Google Cloud Spanner.';
      case 'xata':
        return 'query or manage data in Xata.';
      case 'zendesk':
        return 'manage tickets or customer service tasks in Zendesk.';
      case 'mail':
        return 'send emails via SMTP.';
      case 'slack':
        return 'send messages or manage channels in Slack.';
    }
  };
  return 'Call this connector when asked to ' + getAction(integrationType);
}

/** Returns instructions used by the integration agent to call the connected AI functions. */
export function getIntegrationAgentDefaultInstructions(integrationType: IntegrationType): string {
  switch (integrationType) {
    default:
      return `Your primary goal is to provide accurate and context-specific answers to any questions or queries.`;
  }
}

export const INTEGRATION_AGENT_FUNCTION_INSTRUCTIONS =
  'Use the attached AI functions for efficient and precise responses.';
