import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import deepDiff from 'deep-diff';

import { ValidationError } from '../utils/validation';
import { assertTruthy, truthy } from 'assertic';
import { HttpStatus } from '../public-types/http-status.enum';
import { CollectionSchema, PropertySchema } from '../public-types/schema.public-types';

const ajv = new Ajv({ allErrors: true, allowUnionTypes: false, useDefaults: false });
addFormats(ajv);

ajv.addKeyword({
  keyword: 'isDate',
  type: 'object',
  validate: (isDate: boolean, value: unknown): boolean => {
    return isDate === value instanceof Date;
  },
});

ajv.addKeyword({
  keyword: 'isJSON',
  type: 'object',
  validate: (_isJSON: boolean, value: unknown): boolean => {
    try {
      const jsonString = JSON.stringify(value);
      JSON.parse(jsonString);
      return true;
    } catch {
      return false;
    }
  },
});

ajv.addKeyword({
  keyword: 'isComputed',
  validate: (isComputed: boolean, value: unknown): boolean => {
    return isComputed ? value !== null : true;
  },
});

ajv.addKeyword({
  keyword: 'isDefaultComputed',
  validate: (isDefaultComputed: boolean, value: unknown): boolean => {
    return isDefaultComputed ? value !== null : true;
  },
});

ajv.addKeyword({
  keyword: 'primaryKey',
  validate: (isPrimaryKey: boolean, value: unknown): boolean => {
    return isPrimaryKey ? value !== null : true;
  },
});

ajv.addKeyword({
  keyword: 'insertable',
  validate: (insertable: boolean, value: unknown): boolean => {
    return insertable ? value !== null : true;
  },
});

ajv.addKeyword({
  keyword: 'deletable',
  validate: (deletable: boolean, value: unknown): boolean => {
    return deletable ? value !== null : true;
  },
});

ajv.addKeyword({
  keyword: 'applyDefaultValueOn',
  validate: (applyDefaultValueOn: string): boolean => {
    return applyDefaultValueOn ? ['always', 'empty', 'updateOrEmpty'].includes(applyDefaultValueOn) : true;
  },
});

ajv.addKeyword({
  keyword: 'dataType',
  validate: (): boolean => {
    return true;
  },
});

interface MatchingProperties {
  exactMatch: Array<PropertySchema>;
  parentsMatch: Array<PropertySchema>;
}

export type FieldPlaceholder =
  | '__SQUID_SERVER_TIMESTAMP__'
  | '__SQUID_CLIENT_IP__'
  | '__SQUID_USER_ID__'
  | '__SQUID_API_KEY__';

export const BASIC_FIELD_TYPES = ['string', 'integer', 'number', 'boolean', 'map', 'array', 'any'] as const;
export const CUSTOM_FIELD_TYPES = ['date', 'json', 'objectId'] as const;
export const DATA_SCHEMA_FIELD_TYPES = [...BASIC_FIELD_TYPES, ...CUSTOM_FIELD_TYPES] as const;

export type DataSchemaFieldType = (typeof DATA_SCHEMA_FIELD_TYPES)[number];

export function compileSchema<S extends CollectionSchema>(schema: S): any {
  return ajv.compile(schema);
}

export function validateSchema<S extends CollectionSchema>(
  schema: S,
  data: unknown,
  updatedPaths: Array<string> = [],
  dataBefore: any = {},
): void {
  const validator = compileSchema(schema);
  const isValid = validator(data);
  if (!updatedPaths.length && !isValid) {
    throw new ValidationError(`The data does not conform with the collection schema.`, HttpStatus.BAD_REQUEST, {
      errors: validator.errors,
    });
  }
  if (!isValid && updatedPaths.length) {
    for (const schemaError of truthy(validator.errors)) {
      let fieldPath = schemaError.instancePath;
      if (schemaError.params['missingProperty']) {
        fieldPath = `${fieldPath}/${schemaError.params['missingProperty']}`;
      }
      fieldPath = fieldPath.slice(1).replace(/\//g, '.') + '.';
      if (updatedPaths.some(updatedPath => fieldPath.startsWith(updatedPath + '.'))) {
        throw new ValidationError(`${fieldPath} does not conform with the collection schema.`, HttpStatus.BAD_REQUEST);
      }
    }
  }
  const diff = truthy(deepDiff(dataBefore, data));
  validateRestrictedFieldsForDiff(diff, schema, !!updatedPaths.length);
}

function validateRestrictedFieldsForDiff<S extends CollectionSchema | PropertySchema>(
  diffs: Array<deepDiff.Diff<any, any>>,
  schema: S,
  isUpdateMutation: boolean,
): void {
  /** Check the top level change */
  if ((isUpdateMutation && schema.readOnly) || (schema.insertable === false && !isUpdateMutation)) {
    throw new ValidationError(`The schema does not allow this action`, HttpStatus.BAD_REQUEST);
  }
  for (const diff of diffs) {
    const path = truthy(diff.path).join('.');
    const relevantProperties = getMatchingProperties(truthy(diff.path), schema);
    for (const property of relevantProperties.exactMatch) {
      if (property.readOnly && diff.kind === 'E') {
        throw new ValidationError(`${path} is readonly`, HttpStatus.BAD_REQUEST);
      }
      if (isUpdateMutation && property.deletable === false && diff.kind === 'D') {
        throw new ValidationError(`${path} is not deletable`, HttpStatus.BAD_REQUEST);
      }
      if (property.insertable === false && diff.kind === 'N') {
        throw new ValidationError(`${path} is not insertable`, HttpStatus.BAD_REQUEST);
      }
    }
    /** If a.b is readOnly and the update is done on a.b.c - The update should be rejected. */
    for (const property of relevantProperties.parentsMatch) {
      if (isUpdateMutation && property.readOnly) {
        throw new ValidationError(`${path} is readonly`, HttpStatus.BAD_REQUEST);
      }
    }
  }
}

function getMatchingProperties<S extends CollectionSchema | PropertySchema>(
  path: string[],
  schema: S,
): MatchingProperties {
  const result: MatchingProperties = {
    exactMatch: [],
    parentsMatch: [],
  };
  if (!path.length) {
    result.exactMatch.push(schema);
    return result;
  }
  result.parentsMatch.push(schema);

  const pathToUse = [...path];
  while (pathToUse.length) {
    const key = pathToUse.shift();
    assertTruthy(key !== undefined);
    const matchingPropertiesForKey = findMatchingPropertiesForKey(schema, key);
    for (const property of matchingPropertiesForKey) {
      const subMatchingProperties = getMatchingProperties(pathToUse, property);
      result.parentsMatch.push(...subMatchingProperties.parentsMatch);
      result.exactMatch.push(...subMatchingProperties.exactMatch);
    }
  }
  return result;
}

export function findMatchingPropertiesForKey<S extends CollectionSchema | PropertySchema>(
  schema: S,
  key: string,
): Array<PropertySchema> {
  const matchingProperties: Array<PropertySchema> = schema.properties?.[key] ? [schema.properties?.[key]] : [];
  if (schema.patternProperties) {
    matchingProperties.push(
      ...Object.entries(schema.patternProperties)
        .filter(([pattern]) => {
          return new RegExp(pattern).test(key);
        })
        .map(([, matchedProperty]) => matchedProperty),
    );
  }
  return matchingProperties;
}
