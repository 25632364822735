import { MetricName } from '../metric-name';
import { AppId } from '../public-types/communication.public-types';

export interface ObservableNameMetrics {
  count: MetricName;
  time: MetricName;
}

export const ObservableNames = {
  functionExecution: <ObservableNameMetrics>{
    count: 'squid_functionExecution_count',
    time: 'squid_functionExecution_time',
  },
  codeInitialization: <ObservableNameMetrics>{
    count: 'squid_codeInitialization_count',
    time: 'squid_codeInitialization_time',
  },
  getAiChatbotProfiles: <ObservableNameMetrics>{
    count: 'squid_getAiChatbotProfiles_count',
    time: 'squid_getAiChatbotProfiles_time',
  },
  aiImageGeneration: <ObservableNameMetrics>{
    count: 'squid_aiImageGeneration_count',
    time: 'squid_aiImageGeneration_time',
  },
  aiRemoveBackground: <ObservableNameMetrics>{
    count: 'squid_aiRemoveBackground_count',
    time: 'squid_aiRemoveBackground_time',
  },
  aiAudioTranscribe: <ObservableNameMetrics>{
    count: 'squid_aiAudioTranscribe_count',
    time: 'squid_aiAudioTranscribe_time',
  },
  aiAudioCreateSpeech: <ObservableNameMetrics>{
    count: 'squid_aiAudioCreateSpeech_count',
    time: 'squid_aiAudioCreateSpeech_time',
  },
  discoverGraphQLConnectionSchema: <ObservableNameMetrics>{
    count: 'squid_discoverGraphQLConnectionSchema_count',
    time: 'squid_discoverGraphQLConnectionSchema_time',
  },
  discoverOpenApiSchema: <ObservableNameMetrics>{
    count: 'squid_discoverOpenApiSchema_count',
    time: 'squid_discoverOpenApiSchema_time',
  },
  discoverOpenApiSchemaFromFile: <ObservableNameMetrics>{
    count: 'squid_discoverOpenApiSchemaFromFile_count',
    time: 'squid_discoverOpenApiSchemaFromFile_time',
  },
  graphqlQuery: <ObservableNameMetrics>{
    count: 'squid_graphqlQuery_count',
    time: 'squid_graphqlQuery_time',
  },
  testGraphQLConnection: <ObservableNameMetrics>{
    count: 'squid_testGraphQLConnection_count',
    time: 'squid_testGraphQLConnection_time',
  },
  graphql: <ObservableNameMetrics>{
    count: 'squid_graphql_count',
    time: 'squid_graphql_time',
  },
} as const;

export type ObservableName = keyof typeof ObservableNames;

export interface MetricEvent {
  appId: AppId;
  name: MetricName;
  tags: Record<string, string>;
  timestamp: Date;
  value: number;
  isExposedToUser: boolean;
}

export interface LogEvent {
  message: string;
  level: string;
  tags: Record<string, string>;
  timestamp: Date;
  isExposedToUser: boolean;
  host: string;
  source?: string;
  service?: string;
}

export const AUDIT_LOG_EVENT_NAMES = ['ai_agent'] as const;
export type AuditLogEventName = (typeof AUDIT_LOG_EVENT_NAMES)[number];

export interface AuditLogEvent {
  appId: AppId;
  name: AuditLogEventName;
  timestamp: Date;
  context: {
    clientId?: string;
    userId: string;
  };
  tags: Record<string, string>;
}

/** Tag for metric events. Value: '0' - not an error, '1' - is an error. */
export const O11Y_TAG_IS_ERROR = 'isError';

/** Tag for log events. Metrics have an explicit appId field. */
export const O11Y_TAG_APP_ID = 'appId';

/** Tag for metric and log events. */
export const O11Y_TAG_INTEGRATION_ID = 'integrationId';

/** Tag for AI events. */
export const O11Y_TAG_AI_MODEL = 'aiModel';
export const O11Y_TAG_AI_PROFILE = 'aiProfile';

export const O11Y_TAG_API_KEY_SOURCE = 'apiKeySource';

/** Tag for metric and log events. Value: '0' - not a tenant originated, '1' - is a tenant originated. */
export const O11Y_TAG_IS_TENANT_ORIGINATED = 'isTenantOriginated';

/** Contains a full (with a service name) function name for backend functions. */
export const O11Y_TAG_FUNCTION_NAME = 'functionName';

/** Contains a type of the function name for backend functions (See ExecuteFunctionAnnotationType.). */
export const O11Y_TAG_FUNCTION_TYPE = 'functionType';

export function getBooleanMetricTagValue(value: boolean): string {
  return value ? '1' : '0';
}

export const COUNT_METRIC_SUFFIXES = ['_count'];
export const GAUGE_METRIC_SUFFIXES = ['_time', '_size', '_value', '_entries'];
