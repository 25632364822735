import { AppId, IntegrationId, IntegrationInfo, IntegrationType, SquidRegion } from './public-types';
import { RpcManager } from './rpc.manager';
import { getConsoleBaseUrl } from './console-utils';

/**
 * IntegrationClient manages integrations within an application,
 * providing methods for listing, retrieving, updating, and deleting integrations.
 * @category Platform
 */
export class IntegrationClient {
  private readonly iacBaseUrl: string;

  /** @internal */
  constructor(
    private readonly rpcManager: RpcManager,
    region: SquidRegion,
    appId: AppId,
  ) {
    this.iacBaseUrl = getConsoleBaseUrl(region, `openapi/iac/applications/${appId}/integrations`);
  }

  /**
   * Lists all integrations for the current application.
   *
   * @param type - (Optional) Filter by integration type.
   * @returns A promise that resolves with a list of integrations.
   */
  async list<ConfigurationType = Record<string, any>>(
    type?: IntegrationType,
  ): Promise<Array<IntegrationInfo<ConfigurationType>>> {
    const integrations = await this.rpcManager.get<IntegrationInfo[]>(this.iacBaseUrl);
    const result = type ? integrations.filter(integration => integration.type === type) : integrations;
    return result as Array<IntegrationInfo<ConfigurationType>>;
  }

  /**
   * Retrieves the integration by its ID.
   *
   * @param integrationId - The ID of the integration to retrieve.
   * @returns A promise that resolves with the integration info, or `undefined` if not found.
   */
  async get<ConfigurationType = Record<string, any>>(
    integrationId: IntegrationId,
  ): Promise<IntegrationInfo<ConfigurationType> | undefined> {
    return await this.rpcManager.get<IntegrationInfo<ConfigurationType>>(`${this.iacBaseUrl}/${integrationId}`);
  }

  /**
   * Deletes the integration with the given ID.
   *
   * @param integrationId - The ID of the integration to delete.
   * @returns A promise that resolves when the integration has been deleted.
   */
  async delete(integrationId: IntegrationId): Promise<void> {
    await this.rpcManager.delete(`${this.iacBaseUrl}/${integrationId}`);
  }

  /**
   * Deletes multiple integrations by their IDs.
   *
   * @param integrationIds - An array of integration IDs to delete.
   * @returns A promise that resolves when all deletions are complete.
   */
  async deleteMany(integrationIds: Array<IntegrationId>): Promise<void> {
    await Promise.all(integrationIds.map(integrationId => this.delete(integrationId)));
  }

  /**
   * Creates or updates an integration.
   *
   * @param integration - The integration information to upsert.
   * @returns A promise that resolves when the operation is complete.
   */
  async upsertIntegration<ConfigurationType = Record<string, any>>(
    integration: IntegrationInfo<ConfigurationType>,
  ): Promise<void> {
    await this.rpcManager.put<IntegrationInfo[]>(`${this.iacBaseUrl}/${integration.id}`, integration);
  }
}
