/* eslint-disable jsdoc/require-jsdoc */

import { BehaviorSubject, Observable } from 'rxjs';
import { DestructManager } from './destruct.manager';
import { DocumentStore } from './document-store';
import { IdResolutionMap } from '../../internal-common/src/types/mutation.types';

/** @internal */
export default class DocumentIdentityService {
  private readonly changeNotifier = new BehaviorSubject<IdResolutionMap>({});

  constructor(
    private readonly documentStore: DocumentStore,
    private readonly destructManager: DestructManager,
  ) {
    this.destructManager.onDestruct(() => {
      this.changeNotifier.complete();
    });
  }

  migrate(idResolutionMap: IdResolutionMap): void {
    // Update the document in the database before notifying any other subscribers.
    Object.entries(idResolutionMap).forEach(([squidDocId, newSquidDocId]) => {
      this.documentStore.migrateDocId(squidDocId, newSquidDocId);
    });

    this.changeNotifier.next(idResolutionMap);
  }

  observeChanges(): Observable<IdResolutionMap> {
    return this.changeNotifier.asObservable();
  }
}
