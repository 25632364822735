import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AiAgentAndContexts, StudioService } from '@squidcloud/console-web/app/studio/studio.service';
import { AGENT_ID_PARAMETER, getRequiredPageParameter } from '@squidcloud/console-web/app/utils/http-utils';
import { getMessageFromError } from 'assertic';
import { SnackBarService } from '@squidcloud/console-web/app/global/services/snack-bar.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { GuardrailKeysType } from '@squidcloud/client';
import { debounceTime, Observable, take } from 'rxjs';
import { FileUploadComponent } from '@squidcloud/console-web/app/global/components/magic-form/file-upload/file-upload.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'guardrails',
  standalone: false,
  templateUrl: './guardrails.component.html',
  styleUrl: './guardrails.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GuardrailsComponent {
  @ViewChild('fileUpload') fileUpload!: FileUploadComponent;
  readonly agentId: string;
  readonly agentAndContexts$: Observable<AiAgentAndContexts | undefined>;

  guardrailStates: Record<GuardrailKeysType, boolean> = {
    offTopicAnswers: false,
    disableProfanity: false,
    professionalTone: false,
    disablePii: false,
  };

  form!: FormGroup;

  readonly PresetGuardrails: Array<{ title: string; subtitle: string; key: GuardrailKeysType }> = [
    {
      title: 'Topic and Scope Limitation',
      subtitle: 'When enabled, the agent will decline to answer off-topic questions from users.',
      key: 'offTopicAnswers',
    },
    {
      title: 'Personally Identifiable Information (PII)',
      subtitle: 'Avoid including PII in agent responses. This option is currently US-centric.',
      key: 'disablePii',
    },
    {
      title: 'Professional Voice',
      subtitle: 'Uses a professional voice to respond to users’ questions.',
      key: 'professionalTone',
    },
    {
      title: 'Profanity',
      subtitle: 'Avoids the use of profanity in responses.',
      key: 'disableProfanity',
    },
  ];

  constructor(
    activatedRoute: ActivatedRoute,
    private readonly studioService: StudioService,
    private readonly snackBarService: SnackBarService,
    private readonly formBuilder: FormBuilder,
  ) {
    this.agentId = getRequiredPageParameter(AGENT_ID_PARAMETER, activatedRoute.snapshot);
    this.agentAndContexts$ = this.studioService.observeAgent(this.agentId);

    this.form = this.formBuilder.group({
      custom: new FormControl('', []),
    });

    this.agentAndContexts$.pipe(take(1)).subscribe(async agentAndContexts => {
      if (agentAndContexts) {
        const { agent } = agentAndContexts;
        this.guardrailStates.offTopicAnswers = !!agent.options.guardrails?.offTopicAnswers;
        this.guardrailStates.disableProfanity = !!agent.options.guardrails?.disableProfanity;
        this.guardrailStates.professionalTone = !!agent.options.guardrails?.professionalTone;
        this.guardrailStates.disablePii = !!agent.options.guardrails?.disablePii;
        this.form.patchValue({
          custom: agent.options.guardrails?.custom ? agent.options.guardrails?.custom : '',
        });
      }
    });

    this.form.get('custom')?.valueChanges.pipe(debounceTime(500)).subscribe(this.addCustomGuardrail.bind(this));
  }

  async updateGuardrails(guardrail: GuardrailKeysType, event: MatSlideToggleChange): Promise<void> {
    try {
      await this.studioService.updateGuardrails(this.agentId, { [guardrail]: event.checked });
    } catch (e) {
      console.error('Error:', e);
    }
  }

  async addCustomGuardrail(text?: string): Promise<void> {
    if (!text) {
      await this.deleteCustomGuardrail();
      return;
    }

    try {
      await this.studioService.upsertCustomGuardrail(this.agentId, text);
    } catch (error) {
      const message = getMessageFromError(error, 'Unable to add Guardrail');
      this.snackBarService.warning(message);
    }
  }

  async deleteCustomGuardrail(): Promise<void> {
    try {
      await this.studioService.deleteCustomGuardrail(this.agentId);
      this.snackBarService.success('Guardrail deleted');
    } catch (e) {
      console.error('Error:', e);
      this.snackBarService.warning('The custom guardrail you are trying to delete was not found');
    }
  }
}
