import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import deepDiff from 'deep-diff';
import { assertTruthy } from 'assertic';
import merge from 'lodash/merge';
import { kebabCase } from 'change-case';
import {
  FormCallbackResponse,
  FormElement,
  FormSelectOption,
  FormTextAreaFloatingAction,
  FormUtils,
  GENERIC_SNACKBAR_ERROR_TEXT,
} from '@squidcloud/console-web/app/utils/form';

import { SnackBarService } from '@squidcloud/console-web/app/global/services/snack-bar.service';
import { RecaptchaComponent } from 'ng-recaptcha-2';

export const MAGIC_FORM_LAYOUT_TYPES = [
  /**
   * A form is shown as a part of a modal dialog.
   * Horizontal space is limited, so field labels are built-in into fields.
   */
  'dialog',
  /**
   * A form is shown as a part of full-width page component.
   * Labels are shown to the left of the related fields.
   */
  'page',
] as const;

type MagicFormLayoutType = (typeof MAGIC_FORM_LAYOUT_TYPES)[number];

export interface FormConfig<FormDetailsType = Record<string, unknown> & { captchaToken?: string }> {
  formElements: Array<FormElement>;
  submitButtonText: string;
  onSubmit?: (formDetails: FormDetailsType) => FormCallbackResponse;
  onDelete?: () => FormCallbackResponse;
  onCancel?: () => FormCallbackResponse;
  cancelButtonText?: string;
  hideCancelButton?: boolean;
  disableSubmitButtonWhenPristine?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  disabledText?: string;
  layoutType?: MagicFormLayoutType;
  /** Extra class added to buttons row. */
  buttonRowClass?: string;
  // For Testing
  testPrefix?: string;
  submitButtonTestId?: string;
  cancelButtonTestId?: string;
  hideRequiredTextForValidValues?: boolean;
  useCaptcha?: boolean;
  formBottomText?: string;
}

@Component({
  selector: 'magic-form',
  templateUrl: './magic-form.component.html',
  styleUrls: ['./magic-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MagicFormComponent<DataType> implements OnInit, OnChanges {
  errorMessage?: string;
  functionIsRunning = false;

  FormUtils = FormUtils;

  @Input({ required: true }) data!: FormConfig<DataType>;

  @ViewChild('captchaRef') captchaRef!: RecaptchaComponent;

  form!: FormGroup;
  layoutType!: MagicFormLayoutType;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
    private readonly snackBar: SnackBarService,
  ) {}

  ngOnInit(): void {
    assertTruthy(this.form, 'Form is undefined because no "data" is provided.');
  }

  getTooltip(control: AbstractControl): string {
    if (control.valid || !control.touched) return '';

    for (const control of Object.values(this.form.controls)) {
      const errors = control.errors || {};
      const errorValues = Object.values(errors);
      if (errorValues.length > 0) {
        return errorValues[0];
      }
    }
    return '';
  }

  ngOnChanges(): void {
    const formControls: Record<string, FormControl> = {};

    for (const formElement of this.data.formElements) {
      const validators = FormUtils.getValidators(formElement);
      const defaultValue = FormUtils.getDefaultValue(formElement);
      formControls[formElement.nameInForm] = new FormControl(
        { value: defaultValue, disabled: this.data.disabled },
        validators,
      );
    }

    this.layoutType = this.data.layoutType || 'dialog';

    this.form = this.formBuilder.group(formControls);
    if (this.data.disabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
    this.form.valueChanges.subscribe(data => {
      this.data.formElements.forEach((formElement, i) => {
        if (formElement.onChange) {
          const newElement = formElement.onChange({ ...formElement }, data);
          const diffs = deepDiff(formElement, newElement) || [];

          if (!diffs.length) return;

          merge(this.data.formElements[i], newElement);

          let typeChanged = false;
          let resetValidators = false;

          diffs.forEach(diff => {
            if (diff.path?.[0] === 'type') {
              typeChanged = true;
              resetValidators = true;
            }
            if (diff.path?.[0] === 'hidden' || diff.path?.[0] === 'extraValidators') {
              resetValidators = true;
            }
          });

          const control = formControls[newElement.nameInForm];

          if (resetValidators) {
            control.setValidators(FormUtils.getValidators(newElement));
          }
          if (typeChanged) {
            control.setValue(FormUtils.getDefaultValue(newElement), {
              emitEvent: false,
            });
          }

          control.updateValueAndValidity();
          this.cdr.markForCheck();
        }
      });
    });
  }

  async onCancel(): Promise<void> {
    if (!this.data.onCancel) {
      return;
    }
    this.functionIsRunning = true;
    try {
      const errorMessage = await this.data.onCancel();
      if (errorMessage) this.errorMessage = errorMessage;
    } finally {
      this.functionIsRunning = false;
      this.cdr.markForCheck();
    }
  }

  async onDelete(): Promise<void> {
    if (!this.data.onDelete) {
      return;
    }
    this.functionIsRunning = true;
    try {
      const errorMessage = await this.data.onDelete();
      if (errorMessage) this.errorMessage = errorMessage;
    } finally {
      this.functionIsRunning = false;
      this.cdr.markForCheck();
    }
  }

  async onSubmit(captchaToken?: string): Promise<void> {
    if (!this.form.valid) return;
    if (!this.data.onSubmit) {
      return;
    }
    this.functionIsRunning = true;
    try {
      const errorMessage = await this.data.onSubmit({ ...this.form.value, captchaToken });
      if (errorMessage) this.errorMessage = errorMessage;
    } finally {
      this.functionIsRunning = false;
      this.cdr.markForCheck();
    }
  }

  fileChanged(formElement: FormElement, file: File | undefined): void {
    this.form.controls[formElement.nameInForm].setValue(file);
  }

  getAsStringOrUndefined(value: unknown): string | undefined {
    assertTruthy(value === undefined || typeof value === 'string', () => `Value not a string: ${value}`);
    return value;
  }

  getFormElementDataTestId(formElement: FormElement): string {
    const prefix = this.data.testPrefix || 'magic-form';
    return `${prefix}-${kebabCase(formElement.nameInForm)}-${formElement.type}`;
  }

  getSelectOptionDataTestId(option: FormSelectOption): string {
    const prefix = this.data.testPrefix || 'magic-form';
    return `${prefix}-${kebabCase(option.name)}-select-option`;
  }

  getSubmitButtonDataTestId(): string {
    if (this.data.submitButtonTestId) return this.data.submitButtonTestId;
    const prefix = this.data.testPrefix || 'magic-form';
    return `${prefix}-submit-button`;
  }

  getCancelButtonDataTestId(): string {
    if (this.data.cancelButtonTestId) return this.data.cancelButtonTestId;
    const prefix = this.data.testPrefix || 'magic-form';
    return `${prefix}-cancel-button`;
  }

  getFloatingAction(formElement: FormElement): FormTextAreaFloatingAction | undefined {
    return formElement.type === 'textarea' ? formElement.floatingAction : undefined;
  }

  onSubmitWithCaptcha(): void {
    // This call will result to 'captchaResolved' or 'captchaError' to be called.
    this.captchaRef.execute();
  }

  private isInsideCaptchaReset = false;

  /** Resets captcha to make it ready for new checks.
   * Captcha must be reset before any future interaction with user after it was resolved.
   */
  private resetCaptcha(): void {
    this.isInsideCaptchaReset = true;
    try {
      this.captchaRef.reset();
    } finally {
      this.isInsideCaptchaReset = false;
    }
  }

  async captchaResolved(captchaToken: string | null): Promise<void> {
    if (captchaToken === null || captchaToken === '') {
      if (!this.isInsideCaptchaReset) {
        console.error(`Got unexpected result from captcha: '${captchaToken}'`);
        this.snackBar.warning(GENERIC_SNACKBAR_ERROR_TEXT);
      }
      return;
    }
    // Reset for the future uses in case if submit button will be clicked again.
    this.resetCaptcha();
    await this.onSubmit(captchaToken);
  }

  captchaError($event: unknown): void {
    console.warn('Got captcha error', $event);
    this.snackBar.warning(GENERIC_SNACKBAR_ERROR_TEXT);
    // Reset for the future uses in case if submit button will be clicked again.
    this.resetCaptcha();
  }
}
