import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, filter, from, map, Observable, of, switchMap } from 'rxjs';
import { AiAgentId, AppId, EnvironmentId, SquidRegion } from '@squidcloud/client';
import { ApplicationService } from '../../../../application/application.service';
import { parseAppId } from '@squidcloud/internal-common/types/communication.types';
import { convertToSquidRegion } from '@squidcloud/console-common/clouds-and-regions';
import { environment } from '@squidcloud/console-web/environments/environment';

interface AiAgentWebInfo {
  appId: AppId;
  agentId: AiAgentId | undefined;
  integrationId?: string;
  aiQuery: boolean;
  introText: string;
}

export interface AiTestChatWidgetParams {
  appId: AppId;
  integrationId?: string;
  agentId: AiAgentId | undefined;
  region: SquidRegion;
  aiQuery: boolean;
  environmentId?: EnvironmentId;
  apiKey: string;
  introText: string;
}

const INTRO_TEXT = 'Hi, I am Rudder, an AI from Squid. How may I assist you?';

export type ChatMessage = {
  id: string;
  type: 'ai' | 'user';
  message: string;
};

@Injectable({
  providedIn: 'root',
})
export class AiTestChatFlyOutService {
  private readonly testChatHistorySubject = new BehaviorSubject<Array<ChatMessage>>([]);
  private readonly testChatActiveSubject = new BehaviorSubject<boolean>(false);
  private readonly aiAgentInfoSubject = new BehaviorSubject<AiAgentWebInfo | undefined>(undefined);

  constructor(private readonly applicationService: ApplicationService) {
    // Close test chat every time application is changed.
    applicationService.currentApplication$
      .pipe(
        map(app => app?.appId),
        distinctUntilChanged(),
      )
      .subscribe(() => {
        this.closeTestChat();
      });
  }

  readonly widgetParams$: Observable<AiTestChatWidgetParams> = this.aiAgentInfoSubject.pipe(
    distinctUntilChanged((a, b) => a?.appId === b?.appId && a?.agentId === b?.agentId),
    filter(Boolean),
    switchMap(agentInfo => {
      return this.applicationService.observeCurrentApplication().pipe(
        filter(Boolean),
        switchMap(application => {
          return from(this.applicationService.getApiKey()).pipe(
            switchMap(apiKey => {
              const parsedAppId = parseAppId(application.appId);
              const result: AiTestChatWidgetParams = {
                appId: parsedAppId.appId,
                environmentId: parsedAppId.environmentId,
                agentId: agentInfo.agentId,
                aiQuery: agentInfo.aiQuery,
                integrationId: (agentInfo.aiQuery ? agentInfo.integrationId : undefined) || 'ai_agents',
                introText: agentInfo.introText,
                region: convertToSquidRegion(
                  application.cloudId,
                  application.region,
                  application.shard,
                  environment.stage,
                ),
                apiKey,
              };
              return of(result);
            }),
          );
        }),
      );
    }),
  );

  setApplicationAndAgentId(
    appId: AppId,
    agentId: AiAgentId | undefined,
    aiQuery = false,
    introText = INTRO_TEXT,
    integrationId?: string,
  ): void {
    this.closeTestChat();
    this.aiAgentInfoSubject.next({ appId, agentId, aiQuery, introText, integrationId });
  }

  get testChatIsActive$(): Observable<boolean> {
    return this.testChatActiveSubject.asObservable();
  }

  observeChatHistory(): Observable<Array<ChatMessage>> {
    return this.testChatHistorySubject.asObservable();
  }

  setHistory(history: Array<ChatMessage>): void {
    this.testChatHistorySubject.next(history);
  }

  toggleTestChat(): void {
    this.testChatActiveSubject.next(!this.testChatActiveSubject.value);
    if (!this.testChatActiveSubject.value) {
      this.testChatHistorySubject.next([]);
      this.aiAgentInfoSubject.next(undefined);
    }
  }

  closeTestChat(): void {
    this.testChatActiveSubject.next(false);
    this.testChatHistorySubject.next([]);
    this.aiAgentInfoSubject.next(undefined);
  }
}
