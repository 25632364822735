/** A type alias for an application id. */
export type AppId = string;

/** A tuple of environment identifiers like 'dev' or 'prod'. */
export const ENVIRONMENT_IDS = ['dev', 'prod'] as const;

/** A type representing valid environment identifiers derived from ENVIRONMENT_IDS. */
export type EnvironmentId = (typeof ENVIRONMENT_IDS)[number];

/** A type alias for a squid developer identifier. */
export type SquidDeveloperId = string;

/** A type alias for an integration id. */
export type IntegrationId = string;

/** A type alias for a client identifier. */
export type ClientId = string;

/** A type alias for a squid document identifier. */
export type SquidDocId = string;

/** A type alias for a client request identifier. */
export type ClientRequestId = string;

/** ID of AI agent. Also known as AI profile id. */
export type AiAgentId = string;

/**
 * The built-in agent id. Cannot be customized.
 * @category AI
 */
export const BUILT_IN_AGENT_ID = 'built_in_agent';

/**
 * A type alias for an AI context identifier.
 * @category AI
 */
export type AiContextId = string;
