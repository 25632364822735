import { RpcManager } from './rpc.manager';
import { IntegrationId } from '../../internal-common/src/public-types/communication.public-types';
import { ApiEndpointId, HttpMethod } from '../../internal-common/src/public-types/integrations/api.public-types';
import { CallApiRequest } from '../../internal-common/src/public-types-backend/api-call.public-context';
import { HttpResponse } from './squid-http-client';
import { ApiOptions } from '../../internal-common/src/public-types/api-client.public-types';

const DEFAULT_OPTIONS: ApiOptions = { headers: {}, queryParams: {}, pathParams: {} };

/**
 * ApiClient facilitates making HTTP API requests to external integrations,
 * supporting various HTTP methods such as GET, POST, PUT, PATCH, and DELETE.
 * @category Platform
 */
export class ApiClient {
  /** @internal */
  constructor(private readonly rpcManager: RpcManager) {}

  /**
   * Sends a GET request to the specified integration endpoint.
   *
   * @param integrationId The integration to send the request to.
   * @param endpointId The API endpoint to call.
   * @param options Optional API request options (headers, query params, etc).
   * @returns A promise resolving to the HTTP response.
   */
  async get<ResponseBodyType = unknown>(
    integrationId: IntegrationId,
    endpointId: ApiEndpointId,
    options?: ApiOptions,
  ): Promise<HttpResponse<ResponseBodyType>> {
    return this.request<ResponseBodyType>(integrationId, endpointId, undefined, options, 'get');
  }

  /**
   * Sends a POST request to the specified integration endpoint.
   *
   * @param integrationId The integration to send the request to.
   * @param endpointId The API endpoint to call.
   * @param body Optional request body to send.
   * @param options Optional API request options (headers, query params, etc).
   * @returns A promise resolving to the HTTP response.
   */
  async post<ResponseBodyType = unknown, RequestBodyType = unknown>(
    integrationId: IntegrationId,
    endpointId: ApiEndpointId,
    body?: RequestBodyType,
    options?: ApiOptions,
  ): Promise<HttpResponse<ResponseBodyType>> {
    return this.request<ResponseBodyType>(integrationId, endpointId, body, options, 'post');
  }

  /**
   * Sends a DELETE request to the specified integration endpoint.
   *
   * @param integrationId The integration to send the request to.
   * @param endpointId The API endpoint to call.
   * @param body Optional request body to send.
   * @param options Optional API request options (headers, query params, etc).
   * @returns A promise resolving to the HTTP response.
   */
  async delete<ResponseBodyType = unknown, RequestBodyType = unknown>(
    integrationId: IntegrationId,
    endpointId: ApiEndpointId,
    body?: RequestBodyType,
    options?: ApiOptions,
  ): Promise<HttpResponse<ResponseBodyType>> {
    return this.request<ResponseBodyType>(integrationId, endpointId, body, options, 'delete');
  }

  /**
   * Sends a PATCH request to the specified integration endpoint.
   *
   * @param integrationId The integration to send the request to.
   * @param endpointId The API endpoint to call.
   * @param body Optional request body to send.
   * @param options Optional API request options (headers, query params, etc).
   * @returns A promise resolving to the HTTP response.
   */
  async patch<ResponseBodyType = unknown, RequestBodyType = unknown>(
    integrationId: IntegrationId,
    endpointId: ApiEndpointId,
    body?: RequestBodyType,
    options?: ApiOptions,
  ): Promise<HttpResponse<ResponseBodyType>> {
    return this.request<ResponseBodyType>(integrationId, endpointId, body, options, 'patch');
  }

  /**
   * Sends a PUT request to the specified integration endpoint.
   *
   * @param integrationId The integration to send the request to.
   * @param endpointId The API endpoint to call.
   * @param body Optional request body to send.
   * @param options Optional API request options (headers, query params, etc).
   * @returns A promise resolving to the HTTP response.
   */
  async put<ResponseBodyType = unknown, RequestBodyType = unknown>(
    integrationId: IntegrationId,
    endpointId: ApiEndpointId,
    body?: RequestBodyType,
    options?: ApiOptions,
  ): Promise<HttpResponse<ResponseBodyType>> {
    return this.request<ResponseBodyType>(integrationId, endpointId, body, options, 'put');
  }

  /**
   * Performs an HTTP API request to the given integration ID and endpoint ID.
   * The provided options will be merged with the default options.
   * In case of error (status code >= 400 or other error), the promise will be rejected with an RpcError.
   */
  async request<ResponseBodyType = unknown, RequestBodyType = unknown>(
    integrationId: IntegrationId,
    endpointId: ApiEndpointId,
    body?: RequestBodyType,
    options?: ApiOptions,
    method?: HttpMethod,
  ): Promise<HttpResponse<ResponseBodyType>> {
    const optionsToSend: ApiOptions = {
      ...DEFAULT_OPTIONS,
      ...this.convertOptionsToStrings(options || {}),
    };

    const apiRequest: CallApiRequest<RequestBodyType> = {
      integrationId,
      endpointId,
      body,
      options: optionsToSend,
      overrideMethod: method,
    };

    return await this.rpcManager.rawPost<ResponseBodyType>('api/callApi', apiRequest, undefined, undefined, false);
  }

  private convertOptionsToStrings(apiOptions: ApiOptions): ApiOptions {
    return {
      headers: this.convertToStrings(apiOptions.headers),
      queryParams: this.convertToStrings(apiOptions.queryParams),
      pathParams: this.convertToStrings(apiOptions.pathParams),
    };
  }

  private convertToStrings(items: Record<string, string | number | boolean> | undefined): Record<string, string> {
    if (!items) return {};
    const entries = Object.entries(items)
      .filter(([, value]) => value !== undefined)
      .map(([key, value]) => [key, String(value)]);
    return Object.fromEntries(entries);
  }
}
