/* eslint-disable jsdoc/require-jsdoc */
import { from, Observable, race } from 'rxjs';
import { map } from 'rxjs/operators';
import { RpcManager } from './rpc.manager';
import { deserializeObj, serializeObj } from '../../internal-common/src/utils/serialization';
import { ExecuteBackendFunctionRequest } from '../../internal-common/src/types/backend-function.types';
import { BackendFunctionResultData } from '../../internal-common/src/types/socket.types';
import {
  FileArrayPlaceholderParam,
  FilePlaceholderParam,
} from '../../internal-common/src/public-types/code-executor.public-types';

/** @internal */
export class BackendFunctionManager {
  constructor(private readonly rpcManager: RpcManager) {}

  executeFunctionAndSubscribe<T>(functionName: string, ...params: unknown[]): Observable<T> {
    const fileArr: unknown[] = [];
    const paramArr: unknown[] = [];
    let fileIndex = 0;
    params.forEach(param => {
      if (typeof File !== 'undefined') {
        if (param instanceof File) {
          fileArr.push(param);
          const filePlaceholder: FilePlaceholderParam = {
            type: 'file',
            __squid_placeholder__: true,
            fileIndex: fileIndex++,
          };
          paramArr.push(filePlaceholder);
        } else if (this.isArrayOfFiles(param)) {
          const fileArrayPlaceholder: FileArrayPlaceholderParam = {
            type: 'fileArray',
            __squid_placeholder__: true,
            fileIndexes: param.map((_, index) => {
              fileArr.push(param[index]);
              return fileIndex++;
            }),
          };
          paramArr.push(fileArrayPlaceholder);
        } else {
          paramArr.push(param);
        }
      } else {
        paramArr.push(param);
      }
    });

    const request: ExecuteBackendFunctionRequest = {
      functionName,
      paramsArrayStr: serializeObj(paramArr),
    };

    // Append '?functionName' suffix to every POST request for visibility in the browser's 'Network' tab.
    const postUrl = `backend-function/execute?${encodeURIComponent(functionName)}`;
    return race(
      from(
        this.rpcManager.post<BackendFunctionResultData>(
          postUrl,
          request,
          fileArr.length > 0 ? (fileArr as File[]) : [],
        ),
      ).pipe(
        map(response => {
          if (!response.success) {
            throw new Error(response.payload);
          }
          return deserializeObj(response.payload);
        }),
      ),
    );
  }

  private isArrayOfFiles(value: unknown): value is File[] {
    if (typeof File === 'undefined') return false;
    return Array.isArray(value) && value.every(item => item instanceof File);
  }
}
