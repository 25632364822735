import { IntegrationId } from './communication.public-types';

/** @internal */
export const AI_AGENTS_INTEGRATION_ID = 'ai_agents';

/** List of all integration types supported by Squid. */
export const INTEGRATION_TYPES = [
  'active_directory',
  'ai_agents',
  'ai_chatbot',
  'algolia',
  'alloydb',
  'api',
  'auth0',
  'azure_cosmosdb',
  'azure_postgresql',
  'azure_sql',
  'bigquery',
  'built_in_db',
  'built_in_gcs',
  'built_in_queue',
  'built_in_s3',
  'cassandra',
  'clickhouse',
  'cloudsql',
  'cockroach',
  'cognito',
  'confluence',
  'confluent',
  'datadog',
  'db2',
  'descope',
  'documentdb',
  'dynamodb',
  'elastic_enterprise_search',
  'elastic_observability',
  'elastic_search',
  'firebase_auth',
  'firestore',
  'gcs',
  'google_docs',
  'google_drive',
  'graphql',
  'hubspot',
  'jira',
  'jwt_hmac',
  'jwt_rsa',
  'kafka',
  'linear', // Link to docs.
  'mariadb',
  'monday',
  'mongo',
  'mssql',
  'mysql',
  'newrelic',
  'okta',
  'onedrive',
  'oracledb',
  'pinecone',
  'postgres',
  'redis',
  's3',
  'salesforce_crm',
  'sap_hana',
  'sentry',
  'servicenow',
  'snowflake',
  'spanner',
  'xata',
  'zendesk',
  'mail',
  'slack',
] as const;

/**
 * @category Database
 */
export const DATA_INTEGRATION_TYPES = [
  'bigquery',
  'built_in_db',
  'clickhouse',
  'cockroach',
  'mongo',
  'mssql',
  'mysql',
  'oracledb',
  'postgres',
  'sap_hana',
  'snowflake',
] as const;

/**
 * @category Auth
 */
export const AUTH_INTEGRATION_TYPES = [
  'auth0',
  'jwt_rsa',
  'jwt_hmac',
  'cognito',
  'okta',
  'descope',
  'firebase_auth',
] as const;

/** Supported integration types for GraphQL-based services. */
export const GRAPHQL_INTEGRATION_TYPES = ['graphql', 'linear'] as const;

/** Supported integration types for HTTP-based services. */
export const HTTP_INTEGRATION_TYPES = ['api', 'confluence'] as const;

/** Represents a supported integration type identifier, such as 'postgres', 'auth0', or 's3'. */
export type IntegrationType = (typeof INTEGRATION_TYPES)[number];

/** Supported schema types for integrations */
export const INTEGRATION_SCHEMA_TYPES = ['data', 'api', 'graphql'] as const;

/** Describes the type of schema the integration provides, such as data, API, or GraphQL schema. */
export type IntegrationSchemaType = (typeof INTEGRATION_SCHEMA_TYPES)[number];

/**
 * @category Database
 */
export const BUILT_IN_DB_INTEGRATION_ID: IntegrationId = 'built_in_db';

/**
 * @category Queue
 */
export const BUILT_IN_QUEUE_INTEGRATION_ID: IntegrationId = 'built_in_queue';
/**
 * ID for the cloud specific storage integration: s3 (built_in_s3) or gcs (built_in_gcs).
 * @category
 */
export const BUILT_IN_STORAGE_INTEGRATION_ID: IntegrationId = 'built_in_storage';

/** Metadata describing a configured integration, including its ID, type, timestamps, and optional configuration object. */
export interface IntegrationInfo<ConfigurationType = Record<string, any> | undefined> {
  /** The unique identifier of the integration instance. */
  id: IntegrationId;
  /** The type of the integration (e.g., postgres, auth0, s3). */
  type: IntegrationType;
  /** The date when the integration was created. */
  creationDate?: Date;
  /** The date when the integration was last updated. */
  updateDate?: Date;
  /** The configuration object for the integration, if any. */
  configuration: ConfigurationType;
}
