/* eslint-disable jsdoc/require-jsdoc */
import { assertTruthy, truthy } from 'assertic';
import {
  BehaviorSubject,
  combineLatestWith,
  defaultIfEmpty,
  firstValueFrom,
  NEVER,
  Observable,
  startWith,
  switchMap,
} from 'rxjs';
import { map } from 'rxjs/operators';
import { getSquidDocId, parseSquidDocId } from '../../../internal-common/src/types/document.types';
import {
  cloneDeep,
  compareValues,
  groupBy,
  isEqual,
  replaceKeyInRecord,
} from '../../../internal-common/src/utils/object';
import { deserializeObj, encodeValueForMapping } from '../../../internal-common/src/utils/serialization';
import { validateFieldSort, validateQueryLimit } from '../../../internal-common/src/utils/validation';
import DocumentIdentityService from '../document-identity.service';
import { DocumentReference } from '../document-reference';
import { DocumentReferenceFactory } from '../document-reference.factory';
import {
  ALL_OPERATORS,
  CollectionName,
  Condition,
  DocumentData,
  FieldName,
  FieldSort,
  IntegrationId,
  Operator,
  PrimitiveFieldType,
  Query,
  SerializedSimpleQuery,
  SimpleCondition,
  SquidDocId,
} from '../public-types';
import { LocalQueryManager } from './local-query-manager';
import { Pagination, PaginationOptions } from './pagination';
import { QuerySubscriptionManager } from './query-subscription.manager';
import { SnapshotEmitter } from './snapshot-emitter';
import { isSimpleCondition } from '../../../internal-common/src/types/query.types';

/** @internal */
export class QueryBuilderFactory {
  constructor(
    private readonly querySubscriptionManager: QuerySubscriptionManager,
    private readonly localQueryManager: LocalQueryManager,
    private readonly documentReferenceFactory: DocumentReferenceFactory,
    private readonly documentIdentityService: DocumentIdentityService,
  ) {}

  getForDocument<DocumentType extends DocumentData>(squidDocId: SquidDocId): QueryBuilder<DocumentType> {
    const { collectionName, integrationId, docId } = parseSquidDocId(squidDocId);
    const docIdAsJson = deserializeObj<Record<string, string>>(docId);
    const query = this.get<DocumentType>(collectionName, integrationId);
    for (const [fieldName, fieldValue] of Object.entries(docIdAsJson)) {
      query.where(fieldName, '==', fieldValue);
    }
    return query;
  }

  get<DocumentType extends DocumentData>(
    collectionName: CollectionName,
    integrationId: IntegrationId,
  ): QueryBuilder<DocumentType> {
    return new QueryBuilder<DocumentType>(
      collectionName,
      integrationId,
      this.querySubscriptionManager,
      this.localQueryManager,
      this.documentReferenceFactory,
      this,
      this.documentIdentityService,
    );
  }
}

/**
 * Interface used solely for inheritDoc
 * @category Database
 */
export interface HasDereference {
  /**
   * Dereferences the document references in the result of this query. For example, collection.query().snapshot()
   * returns an array of DocumentReference objects, but collection.query().dereference().snapshot() returns an array of
   * the actual document data.
   */
  dereference(): any;
}

/**
 *  Query builder base class.
 *  @category Database
 */
export abstract class BaseQueryBuilder<MyDocType extends DocumentData> {
  /**
   * An indicator when an empty in operator is used in the query.
   * @internal
   */
  containsEmptyInCondition = false;

  /**
   * Adds a condition to the query.
   * @param fieldName The name of the field to query.
   * @param operator The operator to use.
   * @param value The value to compare against.
   * @returns The query builder.
   */
  abstract where(
    fieldName: (keyof MyDocType & FieldName) | string,
    operator: Operator | 'in' | 'not in',
    value: PrimitiveFieldType | Array<PrimitiveFieldType>,
  ): this;

  /**
   * A shortcut for `where(fieldName, '==', value)`
   *
   * @param fieldName The name of the field to query.
   * @param value The value to compare against.
   * @returns The query builder.
   */
  eq(fieldName: (keyof MyDocType & FieldName) | string, value: PrimitiveFieldType): this {
    return this.where(fieldName, '==', value);
  }

  /**
   * A shortcut for `where(fieldName, '!=', value)`
   * @param fieldName The name of the field to query.
   * @param value The value to compare against.
   * @returns The query builder.
   */
  neq(fieldName: (keyof MyDocType & FieldName) | string, value: PrimitiveFieldType): this {
    return this.where(fieldName, '!=', value);
  }

  /**
   * A shortcut for `where(fieldName, 'in', value)`
   * @param fieldName The name of the field to query.
   * @param value An array of values to compare against.
   * @returns The query builder.
   */
  in(fieldName: (keyof MyDocType & FieldName) | string, value: Array<PrimitiveFieldType>): this {
    return this.where(fieldName, 'in', value);
  }

  /**
   * A shortcut for `where(fieldName, 'not in', value)`
   * @param fieldName The name of the field to query.
   * @param value An array of values to compare against.
   * @returns The query builder.
   */
  nin(fieldName: (keyof MyDocType & FieldName) | string, value: Array<PrimitiveFieldType>): this {
    return this.where(fieldName, 'not in', value);
  }

  /**
   * A shortcut for `where(fieldName, '>', value)`
   * @param fieldName The name of the field to query.
   * @param value The value to compare against.
   * @returns The query builder.
   */
  gt(fieldName: (keyof MyDocType & FieldName) | string, value: PrimitiveFieldType): this {
    return this.where(fieldName, '>', value);
  }

  /**
   * A shortcut for `where(fieldName, '>=', value)`
   * @param fieldName The name of the field to query.
   * @param value The value to compare against.
   * @returns The query builder.
   */
  gte(fieldName: (keyof MyDocType & FieldName) | string, value: PrimitiveFieldType): this {
    return this.where(fieldName, '>=', value);
  }

  /**
   * A shortcut for `where(fieldName, '<', value)`
   * @param fieldName The name of the field to query.
   * @param value The value to compare against.
   * @returns The query builder.
   */
  lt(fieldName: (keyof MyDocType & FieldName) | string, value: PrimitiveFieldType): this {
    return this.where(fieldName, '<', value);
  }

  /**
   * A shortcut for `where(fieldName, '<=', value)`
   * @param fieldName The name of the field to query.
   * @param value The value to compare against.
   * @returns The query builder.
   */
  lte(fieldName: (keyof MyDocType & FieldName) | string, value: PrimitiveFieldType): this {
    return this.where(fieldName, '<=', value);
  }

  /**
   * A shortcut for `where(fieldName, 'like', pattern).`
   *
   * @param fieldName The name of the field to query.
   * @param pattern The pattern to compare against. '%' matches 0 or more characters. '_' matches exactly one
   *   character. '\' can be used to escape '%', '_'. or another '\'. Note that any '\' that is not followed by '%',
   *   '_', or '\' is invalid.
   * @param caseSensitive Whether to use case-sensitive comparison. Defaults to true.
   * @returns The query builder.
   */
  like(fieldName: (keyof MyDocType & FieldName) | string, pattern: string, caseSensitive?: boolean): this {
    this.throwIfInvalidLikePattern(pattern);
    return this.where(fieldName, caseSensitive ? 'like_cs' : 'like', pattern);
  }

  /**
   * A shortcut for `where(fieldName, 'not like', pattern).`
   *
   * @param fieldName The name of the field to query.
   * @param pattern The pattern to compare against. '%' matches 0 or more characters. '_' matches exactly one
   *   character. '\' can be used to escape '%', '_'. or another '\'. Note that any '\' that is not followed by '%',
   *   '_', or '\' is invalid.
   * @param caseSensitive Whether to use case-sensitive comparison. Defaults to true.
   * @returns The query builder.
   */
  notLike(fieldName: (keyof MyDocType & FieldName) | string, pattern: string, caseSensitive?: boolean): this {
    this.throwIfInvalidLikePattern(pattern);
    return this.where(fieldName, caseSensitive ? 'not like_cs' : 'not like', pattern);
  }

  /**
   * Adds a condition to the query to check if the specified field includes any of the given values.
   *
   * @param fieldName The name of the field to query.
   * @param values The values to check for inclusion in the field array.
   * @returns The query builder.
   */
  arrayIncludesSome(fieldName: (keyof MyDocType & FieldName) | string, values: Array<PrimitiveFieldType>): this {
    return this.where(fieldName, 'array_includes_some', values);
  }

  /**
   * Adds a condition to the query to check if the specified field includes all the given values.
   *
   * @param fieldName The name of the field to query.
   * @param values The values to check for inclusion in the field array.
   * @returns The query builder.
   */
  arrayIncludesAll(fieldName: (keyof MyDocType & FieldName) | string, values: Array<PrimitiveFieldType>): this {
    return this.where(fieldName, 'array_includes_all', values);
  }

  /**
   * Adds a condition to the query to check if the specified field does not include any of the given values.
   *
   * @param fieldName The name of the field to query.
   * @param values The values to check for non-inclusion in the field array.
   * @returns The query builder.
   */
  arrayNotIncludes(fieldName: (keyof MyDocType & FieldName) | string, values: Array<PrimitiveFieldType>): this {
    return this.where(fieldName, 'array_not_includes', values);
  }

  /**
   * Sets a limit to the number of results returned by the query. The maximum limit is 20,000 and the default is 1,000
   * if none is provided.
   * @param limit The limit to set.
   * @returns The query builder.
   */
  abstract limit(limit: number): this;

  /**
   * Adds a sort order to the query. You can add multiple sort orders to the query. The order in which you add them
   * determines the order in which they are applied.
   * @param fieldName The name of the field to sort by.
   * @param asc Whether to sort in ascending order. Defaults to true.
   * @returns The query builder.
   */
  abstract sortBy(fieldName: (keyof MyDocType & FieldName) | string, asc?: boolean): this;

  /**
   * Returns the query object built by this query builder.
   */
  abstract build(): Query;

  private throwIfInvalidLikePattern(pattern: string): void {
    const invalidBackslash = /\\(?![%_\\])/;
    if (invalidBackslash.test(pattern)) {
      throw new Error(`Invalid pattern. Cannot have any \\ which are not followed by _, % or \\`);
    }
  }
}

/** @internal */
class DereferenceEmitter<DocumentType extends DocumentData> implements SnapshotEmitter<DocumentType> {
  constructor(private readonly queryBuilder: QueryBuilder<DocumentType>) {}

  /** @inheritDoc */
  peek(): Array<DocumentType> {
    return this.queryBuilder.peek().map(ref => ref.data);
  }

  /** @inheritDoc */
  snapshot(): Promise<Array<DocumentType>> {
    return firstValueFrom(this.snapshots(false).pipe(defaultIfEmpty([])));
  }

  /** @inheritDoc */
  snapshots(subscribe?: boolean): Observable<Array<DocumentType>> {
    return this.queryBuilder.snapshots(subscribe).pipe(
      map(refs => {
        return refs.map(ref => ref.data);
      }),
    );
  }

  getSortOrders(): Array<FieldSort<any>> {
    return this.queryBuilder.getSortOrders();
  }

  clone(): DereferenceEmitter<DocumentType> {
    return new DereferenceEmitter<DocumentType>(this.queryBuilder.clone());
  }

  addCompositeCondition(conditions: Array<SimpleCondition>): DereferenceEmitter<DocumentType> {
    this.queryBuilder.addCompositeCondition(conditions);
    return this;
  }

  limit(limit: number): DereferenceEmitter<DocumentType> {
    this.queryBuilder.limit(limit);
    return this;
  }

  getLimit(): number {
    return this.queryBuilder.getLimit();
  }

  flipSortOrder(): DereferenceEmitter<DocumentType> {
    this.queryBuilder.flipSortOrder();
    return this;
  }

  extractData(data: DocumentType): DocumentType {
    return data;
  }

  serialize(): SerializedSimpleQuery {
    return {
      ...this.queryBuilder.serialize(),
      dereference: true,
    };
  }

  paginate(options?: Partial<PaginationOptions>): Pagination<DocumentType> {
    return new Pagination<DocumentType>(this, options);
  }
}

/**
 * A query builder that can be used to build a query that returns a list of documents.
 * @category Database
 */
export class QueryBuilder<DocumentType extends DocumentData>
  extends BaseQueryBuilder<DocumentType>
  implements SnapshotEmitter<DocumentReference<DocumentType>>, HasDereference
{
  private forceFetchFromServer = false;
  /** @internal */
  protected query: Query<DocumentType>;

  /**
   * @internal
   */
  constructor(
    private readonly collectionName: CollectionName,
    private readonly integrationId: IntegrationId,
    private readonly querySubscriptionManager: QuerySubscriptionManager,
    private readonly localQueryManager: LocalQueryManager,
    private readonly documentReferenceFactory: DocumentReferenceFactory,
    private readonly queryBuilderFactory: QueryBuilderFactory,
    private readonly documentIdentityService: DocumentIdentityService,
  ) {
    super();
    this.query = {
      integrationId,
      collectionName,
      conditions: [],
      limit: -1,
      sortOrder: [],
    };
  }

  /** @inheritDoc */
  where(
    fieldName: (keyof DocumentType & FieldName) | string,
    operator: Operator,
    value: PrimitiveFieldType | Array<PrimitiveFieldType>,
  ): this {
    assertTruthy(ALL_OPERATORS.includes(operator), `Invalid operator: ${operator}`);
    assertTruthy(value !== undefined, 'Condition value cannot be undefined');

    if (operator === 'in' || operator === 'not in') {
      const values = Array.isArray(value) ? [...value] : [value];
      if (operator === 'in' && values.length === 0) {
        this.containsEmptyInCondition = true;
      }
      for (const value of values) {
        this.query.conditions.push({
          fieldName: fieldName as any,
          operator: operator === 'in' ? '==' : '!=',
          value,
        });
      }
      return this;
    }
    this.query.conditions.push({
      fieldName: fieldName as any,
      operator,
      value,
    });
    return this;
  }

  /** @inheritDoc */
  limit(limit: number): this {
    validateQueryLimit(limit);
    this.query.limit = limit;
    return this;
  }

  getLimit(): number {
    return this.query.limit;
  }

  limitBy(limit: number, ...fields: FieldName[]): this {
    const sorts = this.query.sortOrder.map(s => {
      return s.fieldName;
    }) as string[];

    assertTruthy(
      isEqual(fields.sort(), sorts.slice(0, fields.length).sort()),
      'All fields in limitBy must be appear in the first fields in the sortBy list.',
    );

    this.query.limitBy = { limit, fields, reverseSort: false };
    return this;
  }

  /** @inheritDoc */
  sortBy(fieldName: (keyof DocumentType & FieldName) | string, asc = true): this {
    const fieldSort = { asc, fieldName };
    validateFieldSort(fieldSort);
    assertTruthy(
      !this.query.sortOrder.some(so => so.fieldName === fieldName),
      `${fieldName} already in the sort list.`,
    );
    this.query.sortOrder.push(fieldSort);
    return this;
  }

  /** @inheritDoc */
  build(): Query {
    const mergedConditions = this.mergeConditions();
    return { ...this.query, conditions: mergedConditions };
  }

  private mergeConditions(): Array<Condition<DocumentType>> {
    const simpleConditions = this.query.conditions.filter(isSimpleCondition) as Array<SimpleCondition>;
    const result: Array<Condition> = [];
    const groupByFieldName = groupBy(simpleConditions || [], condition => condition.fieldName);
    for (const fieldNameGroup of Object.values(groupByFieldName)) {
      const groupByOperator = groupBy(fieldNameGroup, operator => operator.operator);
      for (const [operator, operatorGroup] of Object.entries(groupByOperator)) {
        if (operator === '==' || operator === '!=') {
          result.push(...operatorGroup);
          continue;
        }
        const sorted = [...operatorGroup];
        sorted.sort((o1, o2) => compareValues(o1.value, o2.value));
        if (operator === '>' || operator === '>=') {
          result.push(sorted[sorted.length - 1]);
        } else if (operator === '<' || operator === '<=') {
          result.push(sorted[0]);
        } else {
          result.push(sorted[0]);
        }
      }
    }
    return [...this.query.conditions.filter(c => !isSimpleCondition(c)), ...result] as Array<Condition<DocumentType>>;
  }

  // noinspection JSUnusedGlobalSymbols
  getSortOrder(): FieldSort<DocumentType>[] {
    return this.query.sortOrder;
  }

  /**
   * @inheritDoc
   */
  snapshot(): Promise<Array<DocumentReference<DocumentType>>> {
    return firstValueFrom(this.snapshots(false).pipe(defaultIfEmpty([])));
  }

  /**
   * Forces the query to return data from the server even if there is a query that already returned the requested
   * result.
   */
  setForceFetchFromServer(): this {
    this.forceFetchFromServer = true;
    return this;
  }

  /**
   * @inheritDoc
   */
  peek(): Array<DocumentReference<DocumentType>> {
    return this.localQueryManager.peek(this.build());
  }

  /**
   * @inheritDoc
   */
  snapshots(subscribe = true): Observable<Array<DocumentReference<DocumentType>>> {
    if (this.containsEmptyInCondition) {
      return new BehaviorSubject([]);
    }

    const query = this.build();
    return this.querySubscriptionManager
      .processQuery(query, this.collectionName, {}, {}, subscribe, this.forceFetchFromServer)
      .pipe(
        map(docs => {
          return docs.map(docRecord => {
            assertTruthy(Object.keys(docRecord).length === 1);
            const doc = docRecord[this.collectionName];
            const squidDocId = getSquidDocId(truthy(doc).__docId__, this.collectionName, this.integrationId);
            return this.documentReferenceFactory.create(squidDocId, this.queryBuilderFactory);
          });
        }),
      );
  }

  /**
   * @inheritDoc
   */
  changes(): Observable<Changes<DocumentType>> {
    let beforeDocMap: Record<SquidDocId, DocumentType> | undefined = undefined;
    let beforeDocSet = new Set<DocumentType>();

    return (this.snapshots() as Observable<Array<DocumentReference<DocumentType>>>).pipe(
      combineLatestWith(
        this.documentIdentityService.observeChanges().pipe(
          switchMap(idResolutionMap => {
            Object.entries(idResolutionMap).forEach(([squidDocId, newSquidDocId]) => {
              replaceKeyInRecord(beforeDocMap || {}, squidDocId, newSquidDocId);
            });
            /**
             * Avoid notifying subscribers when a document id changes. Subscribers
             * should only be notified when the snapshots observable pushes a
             * new value.
             */
            return NEVER;
          }),
          startWith({}),
        ),
      ),
      map(([data]) => {
        let inserts: Array<DocumentReference<DocumentType>> = [];
        const updates: Array<DocumentReference<DocumentType>> = [];
        const deletes: Array<DocumentType> = [];
        if (!beforeDocMap) {
          inserts = data;
        } else {
          for (const docAfter of data) {
            const squidDocId = docAfter.squidDocId;
            const docAfterData = docAfter.dataRef;
            if (beforeDocSet.has(docAfterData)) {
              delete beforeDocMap[squidDocId];
              beforeDocSet.delete(docAfterData);
              continue;
            }

            if (beforeDocMap[squidDocId]) {
              updates.push(docAfter);
              const beforeDoc = beforeDocMap[squidDocId];
              delete beforeDocMap[squidDocId];
              beforeDocSet.delete(beforeDoc);
            } else {
              inserts.push(docAfter);
            }
          }
          for (const beforeDocData of beforeDocSet) {
            deletes.push(beforeDocData);
          }
        }
        beforeDocMap = {};
        beforeDocSet = new Set();
        for (const afterDoc of data) {
          const afterDocData = afterDoc.dataRef;
          beforeDocMap[afterDoc.squidDocId] = afterDocData;
          beforeDocSet.add(afterDocData);
        }
        return new Changes<DocumentType>(inserts, updates, deletes);
      }),
    );
  }

  /**
   * A unique hash for the query. Identical queries should return the same hash
   * value.
   *
   * @returns The query's hash string.
   */
  get hash(): string {
    return encodeValueForMapping(this.build());
  }

  /** @inheritDoc */
  dereference(): SnapshotEmitter<DocumentType> {
    return new DereferenceEmitter(this);
  }

  getSortOrders(): Array<FieldSort<any>> {
    return this.query.sortOrder;
  }

  clone(): QueryBuilder<DocumentType> {
    const res = new QueryBuilder<DocumentType>(
      this.collectionName,
      this.integrationId,
      this.querySubscriptionManager,
      this.localQueryManager,
      this.documentReferenceFactory,
      this.queryBuilderFactory,
      this.documentIdentityService,
    );
    res.query = cloneDeep(this.query);
    res.containsEmptyInCondition = this.containsEmptyInCondition;
    return res;
  }

  addCompositeCondition(conditions: Array<SimpleCondition>): QueryBuilder<DocumentType> {
    if (!conditions.length) {
      return this;
    }
    this.query.conditions.push({ fields: conditions as any });
    return this;
  }

  flipSortOrder(): QueryBuilder<DocumentType> {
    this.query.sortOrder = this.query.sortOrder.map(sort => {
      return { ...sort, asc: !sort.asc };
    });
    if (this.query.limitBy) {
      this.query.limitBy.reverseSort = !this.query.limitBy.reverseSort;
    }
    return this;
  }

  serialize(): SerializedSimpleQuery {
    return {
      type: 'simple',
      dereference: false,
      query: this.build(),
    };
  }

  /** @internal */
  extractData(data: DocumentReference<DocumentType>): DocumentType {
    return data.dataRef;
  }

  paginate(options?: Partial<PaginationOptions>): Pagination<DocumentReference<DocumentType>> {
    return new Pagination<DocumentReference<DocumentType>>(this, options);
  }
}

/**
 * Describes the changes to a query result.
 * @category Database
 */
export class Changes<DocumentType extends DocumentData> {
  // noinspection JSUnusedGlobalSymbols
  /** @internal */
  constructor(
    /** The newly inserted documents to the query result */
    readonly inserts: Array<DocumentReference<DocumentType>>,
    /** The documents that were updated in the query result */
    readonly updates: Array<DocumentReference<DocumentType>>,
    /** The actual document data that was deleted from the query result */
    readonly deletes: Array<DocumentType>,
  ) {}
}
