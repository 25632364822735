import { RpcManager } from './rpc.manager';
import { QueryMetricsRequest, QueryMetricsResponse } from '../../internal-common/src/public-types/metric.public-types';
import { assertTruthy } from 'assertic';
import { fillMissedPoints } from '../../internal-common/src/utils/metric-utils';

const METRIC_REQUEST_WITH_DEFAULT_OPTIONAL_FIELDS: Omit<
  Required<QueryMetricsRequest>,
  'name' | 'fn' | 'domain' | 'periodStartSeconds' | 'periodEndSeconds'
> = {
  groupByTags: [],
  orderByTags: [],
  pointIntervalAlignment: 'align-by-start-time',
  tagFilter: {},
  tagDomains: {},
  noDataBehavior: 'return-no-result-groups',
  fillValue: null,
  pointIntervalSeconds: 0,
};

/**
 * A client for reporting user metrics and querying both application-related Squid and user metrics.
 * @category Platform
 */
export class ObservabilityClient {
  private pendingPromises: Promise<unknown>[] = [];
  private isReporting = false;

  /** @internal */
  constructor(private readonly rpcManager: RpcManager) {}

  /**
   * Reports metrics to the metric submission queue.
   * The metric can be sent immediately or with some (several seconds) delay
   * due to optimizations applied by Squid client.
   * If no tags are provided tags are set to an empty record ({}).
   * If no timestamp is provided the timestamp is set to now().
   */
  reportMetric<MetricNameType = string>(metric: MetricReport<MetricNameType>): void {
    const tags = metric.tags || {};
    const invalidTagNames = Object.keys(tags).filter(
      tagName => !SAFE_TAG_NAME_REGEX.test(tagName) || tagName.length >= MAX_TAG_NAME_LENGTH,
    );
    assertTruthy(invalidTagNames.length === 0, () => `Tag name is not allowed: ${invalidTagNames.join(',')}`);

    this.isReporting = true;
    const fullMetric = { ...metric, tags, timestamp: metric.timestamp === undefined ? Date.now() : metric.timestamp };
    const reportPromise = this.rpcManager.post('/observability/metrics', { metrics: [fullMetric] }).finally(() => {
      this.pendingPromises = this.pendingPromises.filter(p => p !== reportPromise);
      this.isReporting = this.pendingPromises.length > 0;
    });
    this.pendingPromises.push(reportPromise);
  }

  /**
   * Flushes all pending (unsent) metrics.
   * Note: Even after flushing, it may take some time for these metrics to become visible via 'queryMetrics' due to the
   * eventual consistency of the metrics storage.
   */
  async flush(): Promise<void> {
    if (this.isReporting) {
      await Promise.all(this.pendingPromises);
    }
  }

  /** Queries a batch of metric values. */
  async queryMetrics<MetricName extends string = string>(
    metricRequest: QueryMetricsRequest<MetricName>,
  ): Promise<QueryMetricsResponse<MetricName>> {
    // Core request requires all fields to be defined and correct.
    const request = {
      ...METRIC_REQUEST_WITH_DEFAULT_OPTIONAL_FIELDS,
      ...metricRequest,
      fn: Array.isArray(metricRequest.fn) ? metricRequest.fn : [metricRequest.fn],
    } as Required<QueryMetricsRequest<MetricName>>;

    // TODO: make client side request validation for all fields.

    if (!request.pointIntervalSeconds) {
      request.pointIntervalSeconds = request.periodEndSeconds - request.periodStartSeconds;
    }
    const response = await this.rpcManager.post<QueryMetricsResponse<MetricName>>(
      '/observability/metrics/query',
      request,
    );

    // Fill missed points and tag domains.
    fillMissedPoints(request, response.resultGroups);
    return response;
  }
}

/** A single metric event. */
export interface Metric<MetricNameType = string> {
  /** Name of the metric. */
  name: MetricNameType;

  /** Value of the metric. */
  value: number;

  /** Time associated with the metric. Milliseconds since UNIX epoch. */
  timestamp: number;

  /** Set of tags for the metric. */
  tags: Record<string, string>;
}

/**
 * MetricReport represents a single metric event with some optional fields.
 * These optional fields are automatically populated by Squid with default values.
 * @category Platform
 */
export type MetricReport<MetricNameType = string> = Omit<Metric<MetricNameType>, 'tags' | 'timestamp'> &
  Partial<Pick<Metric, 'tags' | 'timestamp'>>;

// Keep in sync with ObservabilityController.kt
const SAFE_TAG_NAME_REGEX = /^[a-zA-Z0-9_-]+$/;
const MAX_TAG_NAME_LENGTH = 1000;
