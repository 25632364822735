import {
  AgentContextRequest,
  AiAgent,
  AiAgentChatOptions,
  AiAgentContext,
  AiAudioCreateSpeechOptions,
  AiChatModelName,
  AiContextMetadata,
  AiContextMetadataAndFilter,
  AiContextMetadataFilter,
  AiContextMetadataOrFilter,
  AiGenerateImageOptions,
  AiSearchOptions,
  AiSearchResultChunk,
  AllAiAgentChatOptions,
  ApiKeySource,
} from '../public-types/ai-agent.public-types';
import { AiAgentId, AppId, ClientRequestId } from '../public-types/communication.public-types';
import { O11Y_TAG_AI_MODEL, O11Y_TAG_AI_PROFILE, O11Y_TAG_API_KEY_SOURCE } from './observability.types';

type ModelDataType = {
  maxTokens: number;
  defaultTokens: number;
};

export const AiModelData: Record<AiChatModelName, ModelDataType> = {
  'gemini-1.5-pro': {
    maxTokens: 2_097_152,
    defaultTokens: 16_385,
  },
  'gemini-2.0-flash': {
    maxTokens: 1_048_576,
    defaultTokens: 16_385,
  },
  'claude-3-7-sonnet-latest': {
    maxTokens: 200_000,
    defaultTokens: 16_385,
  },
  'claude-3-5-sonnet-latest': {
    maxTokens: 200_000,
    defaultTokens: 16_385,
  },
  'claude-3-5-haiku-latest': {
    maxTokens: 200_000,
    defaultTokens: 16_385,
  },
  'gpt-4o': {
    maxTokens: 128_000,
    defaultTokens: 16_385,
  },
  'gpt-4o-mini': {
    maxTokens: 128_000,
    defaultTokens: 16_385,
  },
  o1: {
    maxTokens: 200_000,
    defaultTokens: 16_385,
  },
  'o1-mini': {
    maxTokens: 128_000,
    defaultTokens: 16_385,
  },
  'o3-mini': {
    maxTokens: 200_000,
    defaultTokens: 16_385,
  },
};

export interface AiChatRequest {
  agentId: AiAgentId;
  clientRequestId: ClientRequestId;
  prompt?: string;
  options?: AllAiAgentChatOptions;
}

export interface AiAskRequest {
  agentId: AiAgentId;
  prompt?: string;
  options?: AllAiAgentChatOptions;
}

export interface AiGenerateImageRequest {
  prompt: string;
  options: AiGenerateImageOptions;
}

export interface AiAskQuestionInternalRequest {
  appId: AppId;
  prompt: string;
  options: AiAgentChatOptions;
}

export interface AiAskQuestionInternalResponse {
  answer: string;
}

export interface AiContextSizeRequest {
  appId: AppId;
  agentId: AiAgentId;
}

export interface AiContextSizeResponse {
  sizeBytes: number;
}

/**
 * Metric table 'tags' schema for AI events.
 * @internal
 */
export interface AiMetricTag extends Record<string, string> {
  [O11Y_TAG_AI_PROFILE]: string;
  [O11Y_TAG_AI_MODEL]: AiChatModelName;
  [O11Y_TAG_API_KEY_SOURCE]: ApiKeySource;
}

export interface AiAskResponse {
  responseString: string;
}

export interface AiAskWithVoiceResponse {
  responseString: string;
  voiceResponse: AiAudioCreateSpeechResponse;
}

export interface AiTranscribeAndAskWithVoiceResponse {
  transcribedPrompt: string;
  responseString: string;
  voiceResponse: AiAudioCreateSpeechResponse;
}

export interface AiTranscribeAndChatResponse {
  transcribedPrompt: string;
}

export interface AiSearchRequest {
  options: AiSearchOptions;
  agentId: AiAgentId;
}

export interface AiSearchResponse {
  chunks: Array<AiSearchResultChunk>;
}

export interface AiAudioCreateSpeechResponse {
  mimeType: string;
  extension: string;
  base64File: string;
}

export interface AiAudioCreateSpeechRequest {
  input: string;
  options: AiAudioCreateSpeechOptions;
}

export interface GetAgentRequest {
  agentId: AiAgentId;
}

export interface GetAgentResponse {
  agent: AiAgent | undefined;
}

export interface GetAgentContextRequest {
  agentId: AiAgentId;
  contextId: string;
}

export interface DeleteAgentRequest {
  agentId: AiAgentId;
}

export interface ListAgentContextsRequest {
  agentId: AiAgentId;
}

export interface ListAgentContextsResponse {
  contexts: Array<AiAgentContext>;
}

export interface DeleteAgentContextsRequest {
  agentId: AiAgentId;
  contextIds: Array<string>;
}

export interface UpsertAgentContextsRequest {
  agentId: AiAgentId;
  contextRequests: Array<AgentContextRequest>;
}

export interface UpsertAgentCustomGuardrailsRequest {
  agentId: AiAgentId;
  customGuardrail: string;
}

export interface DeleteAgentCustomGuardrailsRequest {
  agentId: AiAgentId;
}

export interface ProvideAgentFeedbackRequest {
  agentId: AiAgentId;
  feedback: string;
}

export interface ResetAgentFeedbackRequest {
  agentId: AiAgentId;
}

export interface ListAgentsResponse {
  agents: Array<AiAgent>;
}

const INTERNAL_METADATA_KEYS = ['groupId', 'appId', 'integrationId', 'profileId', 'index', 'text', 'filePathInBucket'];

export function validateAiContextMetadata(metadata: AiContextMetadata): void {
  for (const key in metadata) {
    const value = metadata[key];
    if (typeof value !== 'string' && typeof value !== 'number' && typeof value !== 'boolean' && value !== undefined) {
      throw new Error(`Invalid metadata value for key ${key} - cannot be of type ${typeof value}`);
    }
    if (INTERNAL_METADATA_KEYS.includes(key)) {
      throw new Error(
        `Invalid metadata key ${key} - cannot be an internal key. Internal keys: ${INTERNAL_METADATA_KEYS.join(', ')}`,
      );
    }
    // Validate that a key can only contains letters, numbers, and underscores
    if (!/^[a-zA-Z0-9_]+$/.test(key)) {
      throw new Error(`Invalid metadata key ${key} - can only contain letters, numbers, and underscores`);
    }
  }
}

export function validateAiContextMetadataFilter(filter: AiContextMetadataFilter): void {
  if (isAndFilter(filter)) {
    for (const subFilter of filter.$and) {
      validateAiContextMetadataFilter(subFilter);
    }
  } else if (isOrFilter(filter)) {
    for (const subFilter of filter.$or) {
      validateAiContextMetadataFilter(subFilter);
    }
  } else {
    for (const key in filter) {
      // Validate that a key can only contains letters, numbers, and underscores
      if (!/^[a-zA-Z0-9_]+$/.test(key)) {
        throw new Error(`Invalid metadata filter key ${key} - can only contain letters, numbers, and underscores`);
      }
      // Validate that the key is not an internal key
      if (INTERNAL_METADATA_KEYS.includes(key)) {
        throw new Error(
          `Invalid metadata filter key ${key} - cannot be an internal key. Internal keys: ${INTERNAL_METADATA_KEYS.join(
            ', ',
          )}`,
        );
      }
      const value = filter[key];
      if (
        typeof value !== 'object' &&
        typeof value !== 'string' &&
        typeof value !== 'number' &&
        typeof value !== 'boolean'
      ) {
        throw new Error(`Invalid metadata filter value for key ${key} - cannot be of type ${typeof value}`);
      }
    }
  }
}

function isAndFilter(filter: AiContextMetadataFilter): filter is AiContextMetadataAndFilter {
  return '$and' in filter;
}

function isOrFilter(filter: AiContextMetadataFilter): filter is AiContextMetadataOrFilter {
  return '$or' in filter;
}
