/**
 * A transactionId - alias for string
 * @category Database
 */
export type TransactionId = string;

/**
 * @internal
 */
export const NOOP_FN = (fn: () => any): any => {
  return fn();
};
