<div class="guardrails_section">
  <div class="section_title">
    Agent Guardrails
    <div class="icon_container">
      <mat-icon class="guardrails_icon" svgIcon="barricade"></mat-icon>
    </div>
  </div>
  @for (pg of PresetGuardrails; track pg.key) {
    <div class="section_row">
      <div class="left">
        <div class="text_wrapper">
          <div class="title">{{ pg.title }}</div>
          <div class="subtitle">{{ pg.subtitle }}</div>
        </div>
      </div>
      <div class="right">
        <div class="form_field boolean_field_container">
          <mat-slide-toggle
            (change)="updateGuardrails(pg.key, $event)"
            [(ngModel)]="guardrailStates[pg.key]"
            class="section_toggle_button"
            color="primary"></mat-slide-toggle>
        </div>
      </div>
    </div>
  }
  <div class="section_row">
    <div class="left">
      <div class="text_wrapper">
        <div class="title">Custom Policy</div>
        <div class="subtitle">Write custom policies, rules, or guidelines that
          the agent should abide by in its responses.
        </div>
      </div>
    </div>
  </div>

  <form [formGroup]="form">
    <mat-form-field
      [hideRequiredMarker]="true"
      appearance="outline"
      class="form_field textarea_form_field"
    >
      <textarea
        name="custom"
        formControlName="custom"
        autocomplete="off"
        style="height: 127px"
        class="agent_desc_textarea"
        placeholder="Add optional custom policy here"
        matInput
      ></textarea>
    </mat-form-field>
  </form>
</div>
