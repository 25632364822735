import { RpcManager } from './rpc.manager';
import {
  DocumentTextDataResponse,
  ExtractDataFromDocumentFileOptions,
} from '../../internal-common/src/public-types/extraction.public-types';

/**
 * ExtractionClient provides methods for extracting structured data
 * from document files using AI-driven processing.
 * @category Platform
 */
export class ExtractionClient {
  /** @internal */
  constructor(private readonly rpcManager: RpcManager) {}

  /**
   * Extracts structured text data from a document file using AI.
   *
   * @param file - The document file to extract data from.
   * @param options - (Optional) Extraction options such as parsing hints or preprocessing flags.
   * @returns A promise that resolves with the extracted document data.
   */
  async extractDataFromDocumentFile(
    file: File,
    options?: ExtractDataFromDocumentFileOptions,
  ): Promise<DocumentTextDataResponse> {
    if (!options) {
      options = {};
    }
    return await this.rpcManager.post<DocumentTextDataResponse>(
      'documentExtraction/extractDataFromDocumentFile',
      options,
      [file],
    );
  }
}
