@let isAdmin = ('ADMIN' | minRole);

<form [formGroup]="form">
  <div class="field_name_and_field">
    <div class="name_and_description">
      <div class="name">
        Agent ID
      </div>
      <span class="description">The name of your agent.</span>
    </div>

    <div class="immutable_card">
      <input-card>
        {{ agentId }}
        <div class="buttons">
          <button
            title="Copy connector id"
            class="medium_menu_button secondary_icon_button" mat-flat-button>
            <mat-icon class="icon" svgIcon="copy_icon"></mat-icon>
          </button>
        </div>
      </input-card>
    </div>
  </div>

  <div class="field_name_and_field">
    <div class="name_and_description">
      <div class="name">
        Agent Description
      </div>
      <span class="description">
       Give your agent a description of what it does so you’ll remember it in the future. For example: “Answers questions about my Jira tickets”.
    </span>
    </div>

    <mat-form-field
      [hideRequiredMarker]="true"
      appearance="outline"
      class="form_field textarea_form_field"
    >
    <textarea
      name="description"
      formControlName="description"
      autocomplete="off"
      style="height: 127px"
      class="agent_desc_textarea"
      placeholder="Enter your description"
      matInput
    ></textarea>
    </mat-form-field>
  </div>

  <div class="field_name_and_field">
    <div class="name_and_description">
      <div class="name">
        Set Agent to Public
      </div>
      <span class="description">
      Public agents are accessible without an API key. View the <a
        href="https://docs.getsquid.ai/docs/security/security-rules/secure-ai-agents/" class="link">documentation</a> to learn about securing public agents.
    </span>
    </div>

    <div class="form_field boolean_field_container">
      <mat-slide-toggle
        name="isPublic"
        formControlName="isPublic"
        class="section_toggle_button"
        color="primary"></mat-slide-toggle>
    </div>
  </div>

  <div class="field_name_and_field">
    <div class="name_and_description">
      <div class="name">
        Report Audit Logs
      </div>
      <span class="description">
      Logs all agent interactions and answers.
    </span>
    </div>

    <div class="form_field boolean_field_container">
      <mat-slide-toggle
        name="auditLog"
        formControlName="auditLog"
        class="section_toggle_button"
        color="primary"></mat-slide-toggle>
    </div>
  </div>
</form>

<guardrails />

@if (isAdmin) {
  <div class="danger_zone">
    <div class="danger_zone_title">Danger Zone</div>
    <action-card
      title="Delete Agent"
      description="Once this agent is deleted there is no going back. A sunken ship cannot be resurfaced."
      (action)="showDeleteAgentDialog()"
      cta="Delete Agent"
      [warning]="true"
      data-testid="delete-agent-card"
    />
  </div>
}

