import { ChangeDetectionStrategy, Component } from '@angular/core';
import { debounceTime, Observable, take } from 'rxjs';
import { AiAgentAndContexts, StudioService } from '@squidcloud/console-web/app/studio/studio.service';
import { AGENT_ID_PARAMETER, getRequiredPageParameter } from '@squidcloud/console-web/app/utils/http-utils';
import { ActivatedRoute, Router } from '@angular/router';
import { FormUtils } from '@squidcloud/console-web/app/utils/form';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';
import { GlobalUiService } from '@squidcloud/console-web/app/global/services/global-ui.service';
import { SnackBarService } from '@squidcloud/console-web/app/global/services/snack-bar.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-agent-settings',
  standalone: false,
  templateUrl: './agent-settings.component.html',
  styleUrl: './agent-settings.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentSettingsComponent {
  readonly agentId: string;
  readonly agentAndContexts$: Observable<AiAgentAndContexts | undefined>;

  form!: FormGroup;

  constructor(
    activatedRoute: ActivatedRoute,
    private readonly studioService: StudioService,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly globalUiService: GlobalUiService,
    private readonly snackBarService: SnackBarService,
    protected readonly applicationService: ApplicationService,
  ) {
    this.agentId = getRequiredPageParameter(AGENT_ID_PARAMETER, activatedRoute.snapshot);
    this.agentAndContexts$ = this.studioService.observeAgent(this.agentId);

    this.form = this.formBuilder.group({
      description: new FormControl('', []),
      isPublic: new FormControl('', []),
      auditLog: new FormControl('', []),
    });

    this.agentAndContexts$.pipe(take(1)).subscribe(async agentAndContexts => {
      if (agentAndContexts) {
        const { agent } = agentAndContexts;
        this.form.patchValue({
          description: agent.description || '',
          isPublic: agent.isPublic,
          auditLog: agent.auditLog,
        });
      }
    });

    this.form
      .get('description')
      ?.valueChanges.pipe(debounceTime(500))
      .subscribe(this.updateAgentDescription.bind(this));
    this.form.get('isPublic')?.valueChanges.subscribe(this.updateAgentIsPublic.bind(this));
    this.form.get('auditLog')?.valueChanges.subscribe(this.updateAgentAuditLog.bind(this));
  }

  async updateAgentDescription(description: string): Promise<void> {
    const { agent } = this.studioService.getAgentAndContextsOrFail(this.agentId);
    await this.studioService.upsertAgent(this.agentId, {
      ...agent,
      description,
    });
  }

  async updateAgentIsPublic(isPublic: boolean): Promise<void> {
    const { agent } = this.studioService.getAgentAndContextsOrFail(this.agentId);
    await this.studioService.upsertAgent(this.agentId, {
      ...agent,
      isPublic,
    });
  }

  async updateAgentAuditLog(auditLog: boolean): Promise<void> {
    const { agent } = this.studioService.getAgentAndContextsOrFail(this.agentId);
    await this.studioService.upsertAgent(this.agentId, {
      ...agent,
      auditLog,
    });
  }

  showDeleteAgentDialog(): void {
    void this.globalUiService.showDeleteDialog(
      `Deleting this agent cannot be undone or recovered.`,
      async () => {
        try {
          await this.studioService.deleteAgent(this.agentId);
          void this.router.navigate([
            '/application',
            this.applicationService.getCurrentApplicationOrFail().appId,
            'studio',
          ]);
          this.snackBarService.success('Agent deleted');
        } catch {
          this.snackBarService.warning('Unable to delete');
        }
      },
      `Arrrrr you sure?`,
    );
  }

  protected readonly FormUtils = FormUtils;
}
