import { AiAgent, AiAgentId, AiStatusMessage } from '../public-types';
import { filter, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { RpcManager } from '../rpc.manager';
import { SocketManager } from '../socket.manager';
import {
  AiAgentMessageToClient,
  AiStatusMessageToClient,
  MessageToClient,
} from '../../../internal-common/src/types/socket.types';
import { AiOngoingChatSequencesMap, AiStatusUpdatesMap, EMPTY_CHAT_ID } from './ai-agent-client.types';
import { AiAgentReference } from './ai-agent-client-reference';
import { ListAgentsResponse } from '../../../internal-common/src/types/ai-agent.types';

/**
 * AiAgentClient manages AI agent interactions, including listing agents,
 * handling real-time chat responses, and processing AI status updates
 * through WebSocket notifications.
 * @category AI
 */
export class AiAgentClient {
  private readonly ongoingChatSequences: AiOngoingChatSequencesMap = {};
  private readonly statusUpdates: AiStatusUpdatesMap = {};

  /** @internal */
  constructor(
    private readonly rpcManager: RpcManager,
    private readonly socketManager: SocketManager,
  ) {
    this.socketManager
      .observeNotifications()
      .pipe(
        filter((notification: MessageToClient) => notification.type === 'aiChatbot'),
        // Keeping the map call because `typedoc` still needs the cast.
        map(n => n as AiAgentMessageToClient),
      )
      .subscribe(notification => {
        this.handleChatResponse(notification).then();
      });

    this.socketManager
      .observeNotifications()
      .pipe(
        filter((notification: MessageToClient) => notification.type === 'aiStatus'),
        map(n => n as AiStatusMessageToClient),
      )
      .subscribe(notification => {
        this.handleStatusMessage(notification).then();
      });
  }

  /**
   * Retrieves an instance of AiAgentReference for a specific agent ID.
   * This reference provides methods for interacting with the agent.
   */
  agent(id: AiAgentId): AiAgentReference {
    return new AiAgentReference(id, this.ongoingChatSequences, this.statusUpdates, this.rpcManager, this.socketManager);
  }

  /**
   * Lists all agents available in the system.
   * @returns A promise that resolves to an array of AiAgent instances.
   */
  async listAgents(): Promise<Array<AiAgent>> {
    const response = await this.rpcManager.post<ListAgentsResponse>(`ai/agent/listAgents`, {});
    return response.agents;
  }

  private async handleChatResponse(message: AiAgentMessageToClient): Promise<void> {
    const tokenSequence = this.ongoingChatSequences[message.clientRequestId];
    if (!tokenSequence) {
      return;
    }

    const { token, complete, tokenIndex } = message.payload;

    if (complete && !token.length) {
      tokenSequence.next({ value: '', complete: true, tokenIndex: undefined });
    } else {
      // If this matches, this is an image or a link markdown
      if (token.match(/\[.*?]\((.*?)\)/g)) {
        tokenSequence.next({
          value: token,
          complete,
          tokenIndex: tokenIndex === undefined ? undefined : tokenIndex,
        });
      } else {
        for (let i = 0; i < token.length; i++) {
          tokenSequence.next({
            value: token[i],
            complete: complete && i === token.length - 1,
            tokenIndex: tokenIndex === undefined ? undefined : tokenIndex,
          });
        }
      }
    }
  }

  private async handleStatusMessage(message: AiStatusMessageToClient): Promise<void> {
    const { agentId, chatId, payload } = message;
    const { title, tags } = payload;
    const subject = this.getStatusSubject(agentId, chatId);
    subject?.next({ agentId, chatId, title, tags });
  }

  private getStatusSubject(agentId: AiAgentId, chatId?: string): Subject<AiStatusMessage> | undefined {
    const chatSubjects = this.statusUpdates[agentId];
    if (!chatSubjects) return undefined;

    const key = chatId || EMPTY_CHAT_ID;
    return this.statusUpdates[agentId][key];
  }
}
