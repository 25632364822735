@let agentAndContexts = agentAndContexts$ | async;
@let agent = agentAndContexts?.agent;
@let contextMap = agentAndContexts?.contextMap;

<ng-template #studioFlyout>
  <studio-flyout
    [agentId]="agentId"
    [eligibleAiFunctions]="eligibleAiFunctions"
    [eligibleConnectedAgentIds]="eligibleConnectedAgentIds"
    [eligibleConnectors]="eligibleConnectors"
    [existingConnectors]="existingConnectors"
    (create)="onCreate($event.type, $event.options)"
    (connect)="onConnect($event.type, $event.options)"
  ></studio-flyout>
</ng-template>

@if (agentAndContexts) {
  <div class="create">
    <div class="left">
      <form [formGroup]="form" class="form">
        <div>
          @let modelControl = form.controls['model'];
          <div class="field_header">LLM Model</div>
          <mat-form-field
            [hideRequiredMarker]="true"
            [ngClass]="{ valid: modelControl.valid && modelControl.touched }"
            appearance="outline"
            floatLabel="always"
            class="form_field dialog_form_field select_field alt"
          >
            <mat-select formControlName="model" class="select_box gray_select">
              @for (model of modelOptions; track model.value) {
                <mat-option [value]="model.value">{{ model.name }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          @let instructionsControl = form.controls['instructions'];
          <div class="field_header">Instructions</div>
          <p class="instructions_field_info">Tell the agent how it should respond. You can include tone, personality,
            wordiness, and more.</p>
          <mat-form-field
            [hideRequiredMarker]="true"
            [ngClass]="{ valid: instructionsControl.valid && instructionsControl.touched }"
            appearance="outline"
            floatLabel="always"
            class="form_field dialog_form_field textarea_form_field alt"
          >
            <textarea
              name="instructions"
              formControlName="instructions"
              placeholder="Use natural language to write your instructions here"
              [attr.data-testid]="'agent-instructions-textarea'"
              [cdkTextareaAutosize]="true"
              [cdkAutosizeMinRows]="10"
              autocomplete="off"
              matInput
            ></textarea>
          </mat-form-field>
        </div>
      </form>
      <p *ngIf="instructionsUpdatedDate$ | async as instructionsUpdatedDate" class="autosave">
        Autosaved: {{ instructionsUpdatedDate }}</p>
    </div>
    <div class="right">
      @if (abilitiesCount > 0) {
        @if (abilitiesCount >= 10) {
          <div class="search_container">
            @if (!showManyAbilitiesCard) {
              <button mat-flat-button class="primary_button large_button" (click)="addAbility()">
                Add Abilities
              </button>
            }
            @if (isSearching) {
              <input matInput type="text" class="search_input" placeholder="Search abilities"
                     (input)="searchAbilities($event)" />
            }
            <button mat-flat-button
                    (click)="onSearchClick()"
                    class="search_button secondary_icon_button"
                    title="Search abilities">
              <mat-icon class="edit_icon" svgIcon="search_icon" />
            </button>
          </div>
          @if (showManyAbilitiesCard) {
            <knowledge-card class="many_abilities_card" (dismiss)="hideManyAbilitiesCard()">
              <ng-container content>
                <div class="many_abilities_container">
                  <h3 class="">Continue to enhance your agent</h3>
                  <p>Give it a set of tools by adding more abilities.</p>
                  <button mat-flat-button class="primary_button large_button"
                          (click)="addAbility()"
                          [attr.data-testid]="'add-abilities-right-panel-button'"
                  >
                    Add Abilities
                  </button>
                </div>
              </ng-container>
            </knowledge-card>
          }
        } @else {
          <div class="header">
            <button mat-flat-button
                    class="primary_button large_button add_button"
                    (click)="addAbility()"
                    [attr.data-testid]="'add-abilities-header-button'"
            >
              Add Abilities
            </button>
          </div>
        }
        <div class="categories">
          @if (getEntries(contextMap).length) {
            <abilities-category title="Knowledge Base">
              <div class="cards">
                <!-- Defaults to 'text' if the context type is not defined.-->
                @for (entry of getEntries(contextMap); track entry[1].title) {
                  @if (searchTerm) {
                    @if (entry[1].title.includes(searchTerm)) {
                      <abilities-card
                        [type]="entry[1].type || inferContextType(entry[1])"
                        [name]="entry[1].title"
                        [description]="entry[1].text"
                        (delete)="onDelete(entry[1].type || inferContextType(entry[1]), { contextId: entry[0] })"
                      />
                    }
                  } @else {
                    <abilities-card
                      [type]="entry[1].type || inferContextType(entry[1])"
                      [name]="entry[1].title"
                      [description]="entry[1].text"
                      (delete)="onDelete(entry[1].type || inferContextType(entry[1]), { contextId: entry[0] })"
                    />
                  }
                }
              </div>
            </abilities-category>
          }
          @for (entry of getEntries(connectedIntegrationsByCategory); track entry[0]) {
            <abilities-category [title]="getCategoryName(entry[0])">
              <div class="cards">
                @for (connectedIntegration of entry[1]; track connectedIntegration.integrationId) {
                  @if (isAbilityType(connectedIntegration.integrationType)) {
                    @if (searchTerm) {
                      @if (connectedIntegration.integrationId.includes(searchTerm) || connectedIntegration.integrationType.includes(searchTerm)) {
                        <abilities-card
                          [type]="connectedIntegration.integrationType"
                          [name]="connectedIntegration.integrationId"
                          [description]="connectedIntegration.description"
                          (edit)="editConnector(connectedIntegration.integrationId, connectedIntegration.integrationType)"
                          (delete)="
                          deleteConnector(connectedIntegration.integrationId, connectedIntegration.integrationType)"
                        />
                      }
                    } @else {
                      <abilities-card
                        [type]="connectedIntegration.integrationType"
                        [name]="connectedIntegration.integrationId"
                        [description]="connectedIntegration.description"
                        (edit)="editConnector(connectedIntegration.integrationId, connectedIntegration.integrationType)"
                        (delete)="
                        deleteConnector(connectedIntegration.integrationId, connectedIntegration.integrationType)"
                      />
                    }
                  }
                }
              </div>
            </abilities-category>
          }
          @if (agent?.options?.connectedAgents?.length) {
            <abilities-category title="Connected Agents">
              <div class="cards">
                @for (connectedAgent of agent?.options?.connectedAgents; track connectedAgent.agentId) {
                  @if (searchTerm) {
                    @if (connectedAgent.agentId.includes(searchTerm)) {
                      <abilities-card
                        type="connected_agent"
                        [name]="connectedAgent.agentId"
                        [description]="connectedAgent.description"
                        (edit)="onEdit('connected_agent', { connectedAgentId: connectedAgent.agentId })"
                        (delete)="onDelete('connected_agent', { connectedAgentId: connectedAgent.agentId })"
                      />
                    }
                  } @else {
                    <abilities-card
                      type="connected_agent"
                      [name]="connectedAgent.agentId"
                      [description]="connectedAgent.description"
                      (edit)="onEdit('connected_agent', { connectedAgentId: connectedAgent.agentId })"
                      (delete)="onDelete('connected_agent', { connectedAgentId: connectedAgent.agentId })"
                    />
                  }
                }
              </div>
            </abilities-category>
          }
          @if (agent?.options?.functions?.length) {
            <abilities-category title="AI Functions">
              <div class="cards">
                @for (fn of (agent?.options?.functions || []); track fn) {
                  @let fnName = getFunctionName(fn);
                  @if (searchTerm) {
                    @if (fnName.includes(searchTerm)) {
                      <abilities-card
                        type="ai_function"
                        [description]="getAiFunctionDescription(fnName)"
                        [name]="fnName"
                        (delete)="onDelete('ai_function', { fn: fnName })"
                      />
                    }
                  } @else {
                    <abilities-card
                      type="ai_function"
                      [description]="getAiFunctionDescription(fnName)"
                      [name]="fnName"
                      (delete)="onDelete('ai_function', { fn: fnName })"
                    />
                  }
                }
              </div>
            </abilities-category>
          }
        </div>
      } @else {
        <knowledge-card [hideClose]="true">
          <ng-container content>
            <div class="zero-state">
              <h2>Enhance your agent</h2>
              <div class="abilities">
                <div class="ability red">
                  <img src="/assets/integrations/pdf.svg" class="icon" />
                  Add Knowledge
                </div>
                <div class="ability green">
                  <img src="/assets/integrations/mongo.svg" class="icon" />
                  Add Connectors
                </div>
                <div class="ability purple">
                  <img src="/assets/integrations/ai_function.svg" class="icon" />
                  Add AI Functions
                </div>
              </div>
              <p>Build out your agent by adding abilities like knowledge, connectors, AI functions and more.</p>
              <button mat-flat-button
                      class="primary_button large_button"
                      [attr.data-testid]="'add-abilities-button'"
                      (click)="addAbility()">Add Abilities
              </button>
            </div>
          </ng-container>
        </knowledge-card>
      }
    </div>
  </div>
}
