import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';

import { AiAgentAndContexts, StudioService } from '@squidcloud/console-web/app/studio/studio.service';
import { Observable } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Clipboard } from '@angular/cdk/clipboard';
import { getSortedKeys } from '@squidcloud/console-web/app/utils/angular-utils';
import { GlobalUiService } from '@squidcloud/console-web/app/global/services/global-ui.service';
import { SnackBarService } from '@squidcloud/console-web/app/global/services/snack-bar.service';
import {
  AbilityType,
  getAbilityMetadata,
  isAbilityType,
} from '@squidcloud/console-web/app/studio/agent/abilities/types';
import { Router } from '@angular/router';
import { assertTruthy, getMessageFromError, truthy } from 'assertic';
import { AiAgentId } from '@squidcloud/client';
import { OVERVIEW_TAB_ID } from '@squidcloud/console-web/app/studio/studio.utils';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class StudioHomeComponent {
  protected readonly getSortedKeys = getSortedKeys;

  readonly EDUCATION = {
    title: 'Creating AI Agent in Three Steps...',
    steps: [
      {
        number: '01',
        text: 'Give your agent a name and a description',
      },
      {
        number: '02',
        text: 'Provide it with instructions and abilities',
      },
      {
        number: '03',
        text: 'Test and deploy your agent!',
      },
    ],
  };

  readonly agents$: Observable<Record<AiAgentId, AiAgentAndContexts> | undefined>;
  readonly application$ = this.applicationService.currentApplication$;
  navigateToStudio = true;

  constructor(
    private readonly applicationService: ApplicationService,
    private readonly studioService: StudioService,
    private readonly globalUiService: GlobalUiService,
    private readonly snackBar: SnackBarService,
    private readonly router: Router,
    private readonly clipboard: Clipboard,
  ) {
    this.applicationService.currentApplication$.pipe(takeUntilDestroyed()).subscribe(application => {
      void this.studioService.initialize(truthy(application).appId);
    });
    this.agents$ = this.studioService.observeAgents();
  }

  get hasAgents(): boolean {
    const agentAndContexts = this.studioService.getAgentAndContextsMap();
    return !!Object.keys(agentAndContexts).length;
  }

  getTopAbilities(agentId: string): { top: Array<AbilityType>; count: number } {
    const { agent, contextMap } = this.studioService.getAgentAndContextsOrFail(agentId);
    const abilitiesType = [
      ...(agent.options.connectedIntegrations || []).map(i => i.integrationType),
      ...Object.values(contextMap).map(c => c.type || 'text'),
      ...(agent.options.connectedAgents || []).map(() => 'connected_agent'),
      ...(agent.options.functions || []).map(() => 'ai_function'),
    ].filter(isAbilityType);

    if (abilitiesType.length > 3) {
      return {
        top: abilitiesType.slice(0, 2),
        count: abilitiesType.length - 2,
      };
    } else {
      return {
        top: abilitiesType,
        count: 0,
      };
    }
  }

  getImage(type: AbilityType): string {
    return `/assets/integrations/${getAbilityMetadata(type).icon}.svg`;
  }

  createAgent(): void {
    this.globalUiService
      .showDialogWithForm<{ id: string; description: string }>(
        {
          title: `Create New AI Agent`,
          minRole: 'ADMIN',
          textLines: [
            'Give your agent a descriptive name and what it does so you’ll remember it in the future. For example: “Ticketing insight agent” and “Answers questions about my Jira tickets”. View our <a class="link" target="_blank" href="https://docs.getsquid.ai/docs/ai/">Documentation Here.</a>',
          ],
          submitButtonText: 'Create',
          formBottomText:
            'Describe what this agent does for your future reference. This is not instructions for your Agent.',
          formElements: [
            {
              type: 'input',
              required: true,
              nameInForm: 'id',
              label: 'Agent ID',
              placeholder: 'Enter an ID for your agent',
            },
            {
              type: 'textarea',
              required: true,
              attributes: {
                autosize: true,
                minRows: 10,
                maxRows: 15,
              },
              nameInForm: 'description',
              label: 'Description',
              placeholder: 'Enter a description for your agent',
            },
          ],
          onSubmit: async (data): Promise<string | void> => {
            if (!data) return;
            const { id: agentId, description } = data;

            try {
              const agentAndContexts = this.studioService.getAgentAndContexts(agentId);
              assertTruthy(!agentAndContexts, 'An agent with this id already exists');

              await this.studioService.upsertAgent(agentId, {
                description,
                options: {
                  model: 'gpt-4o',
                },
                isPublic: false,
                auditLog: false,
              });
              const application = this.applicationService.getCurrentApplicationOrFail();
              void this.router.navigate(['/application', application.appId, 'studio', agentId, OVERVIEW_TAB_ID]);
              this.snackBar.success('Agent created');
            } catch (error: unknown) {
              const message = error instanceof Error ? error.message : 'Unable to create agent';
              this.snackBar.warning(message);
            }
          },
        },
        true,
      )
      .then();
  }

  onAnchorClick(event: MouseEvent): void {
    if (!this.navigateToStudio) {
      event.preventDefault();
    }
  }

  openMenu(event: MouseEvent): void {
    this.navigateToStudio = false;
    event.stopPropagation();
  }

  onCloseMenu(): void {
    this.navigateToStudio = true;
  }

  deleteAgent(agentId: AiAgentId): void {
    void this.globalUiService.showDeleteDialog(
      `Deleting this agent cannot be undone or recovered.`,
      async () => {
        try {
          await this.studioService.deleteAgent(agentId);
          this.snackBar.success('Agent deleted');
        } catch (error) {
          const errorMessage = getMessageFromError(error, '');
          this.snackBar.warning('Unable to delete: ', errorMessage);
        }
      },
      `Arrrrr you sure?`,
    );
  }

  shareAgent(agentId: AiAgentId, appId: string): void {
    const host = window.location.host;
    let baseUrl;

    if (host.includes('localhost')) {
      baseUrl = 'localhost:4200';
    } else if (host.includes('sandbox')) {
      baseUrl = 'https://console.sandbox.squid.cloud';
    } else {
      baseUrl = 'https://console.getsquid.ai';
    }

    this.clipboard.copy(`${baseUrl}/application/${appId}/studio/${agentId}`);
    this.snackBar.success('Agent URL copied to clipboard');
  }
}
