import { Injectable } from '@angular/core';
import {
  AgentContextRequest,
  AiAgent,
  AiAgentContext,
  AiAgentId,
  AiChatModelName,
  AiClient,
  GuardrailsOptions,
  UpsertAgentRequestParams,
} from '@squidcloud/client';
import { truthy } from 'assertic';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { ApplicationService } from '../application/application.service';
import { SnackBarService } from '../global/services/snack-bar.service';
import { PromisePool } from '@supercharge/promise-pool';

export interface AiAgentAndContexts {
  agent: AiAgent;
  contextMap: Record<string, AiAgentContext>;
}

export interface AiAgentAndContextsForUi {
  agent: AiAgent;
  contexts: Array<AiAgentContext>;
}

@Injectable({
  providedIn: 'root',
})
export class StudioService {
  private readonly flyoutSubject = new BehaviorSubject<boolean>(false);
  private readonly agentSubject = new BehaviorSubject<Record<AiAgentId, AiAgentAndContexts> | undefined>(undefined);

  private applicationId: string | undefined;
  private aiClient!: AiClient;

  constructor(
    private readonly applicationService: ApplicationService,
    private readonly snackBar: SnackBarService,
  ) {}

  async initialize(applicationId: string): Promise<void> {
    if (this.applicationId === applicationId) {
      return;
    } else {
      this.applicationId = applicationId;
    }

    const appSquid = await this.applicationService.getApplicationSquid();
    this.aiClient = appSquid.ai();

    try {
      const aiAgents = await this.getAgentsFromBackend();
      this.agentSubject.next(aiAgents);
    } catch (e) {
      this.snackBar.warning('Unable to fetch the list of agents');
      console.error('Unable to fetch the list of agents', e);
    }
  }

  observeAgents(): Observable<Record<AiAgentId, AiAgentAndContexts> | undefined> {
    return this.agentSubject;
  }

  observeAgent(agentId: string): Observable<AiAgentAndContexts | undefined> {
    return this.observeAgents().pipe(map(agents => agents?.[agentId]));
  }

  getAgentAndContextsMap(): Record<AiAgentId, AiAgentAndContexts> {
    return truthy(this.agentSubject.value, 'AGENT_MAP_NOT_INITIALIZED');
  }

  getAgentAndContexts(agentId: string): AiAgentAndContexts | undefined {
    const agentsMap = this.getAgentsAndContexts();
    return agentsMap?.[agentId];
  }

  getAgentAndContextsOrFail(agentId: string): AiAgentAndContexts {
    return truthy(this.getAgentAndContexts(agentId), 'No agent found');
  }

  chat(agentId: string, prompt: string): Observable<string> {
    return this.aiClient.agent(agentId).chat(prompt);
  }

  async upsertContext(agentId: AiAgentId, contextRequest: AgentContextRequest, file?: File): Promise<void> {
    await this.aiClient.agent(agentId).upsertContext(contextRequest, file);
    await this.refreshAgent(agentId);
  }

  async deleteContext(agentId: AiAgentId, contextId: string): Promise<void> {
    await this.aiClient.agent(agentId).deleteContext(contextId);
    const agentsMap = this.getAgentsAndContexts();
    delete agentsMap?.[agentId].contextMap[contextId];
    this.agentSubject.next(agentsMap);
  }

  async updateInstructions(agentId: AiAgentId, instructions: string): Promise<void> {
    await this.aiClient.agent(agentId).updateInstructions(instructions);

    const agentsMap = this.getAgentsAndContexts();
    const options = truthy(agentsMap?.[agentId].agent.options, 'CANNOT_FIND_AGENT_OPTIONS');
    options.instructions = instructions;
    this.agentSubject.next(agentsMap);
  }

  async deleteInstructions(agentId: AiAgentId): Promise<void> {
    await this.aiClient.agent(agentId).updateInstructions('');
    const agentsMap = this.getAgentsAndContexts();
    const options = truthy(agentsMap?.[agentId].agent.options, 'CANNOT_FIND_AGENT_OPTIONS');
    options.instructions = ``;
    this.agentSubject.next(agentsMap);
  }

  async upsertAgent(agentId: string, upsertRequest: UpsertAgentRequestParams): Promise<void> {
    await this.aiClient.agent(agentId).upsert(upsertRequest);
    await this.refreshAgent(agentId);
  }

  async updateModel(agentId: string, model: AiChatModelName): Promise<void> {
    await this.aiClient.agent(agentId).updateModel(model);
    await this.refreshAgent(agentId);
  }

  async deleteAgent(agentId: AiAgentId): Promise<void> {
    await this.aiClient.agent(agentId).delete();
    const agentsMap = this.getAgentsAndContexts();
    delete agentsMap?.[agentId];
    this.agentSubject.next(agentsMap);
  }

  openFlyout(): void {
    this.flyoutSubject.next(true);
  }

  closeFlyout(): void {
    this.flyoutSubject.next(false);
  }

  observeFlyout(): Observable<boolean> {
    return this.flyoutSubject.asObservable();
  }

  async upsertCustomGuardrail(agentId: AiAgentId, customGuardrail: string): Promise<void> {
    await this.aiClient.agent(agentId).updateCustomGuardrails(customGuardrail);
    await this.refreshAgent(agentId);
  }

  async deleteCustomGuardrail(agentId: AiAgentId): Promise<void> {
    await this.aiClient.agent(agentId).deleteCustomGuardrail();
    await this.refreshAgent(agentId);
  }

  async updateGuardrails(agentId: string, guardrails: GuardrailsOptions): Promise<void> {
    await this.aiClient.agent(agentId).updateGuardrails(guardrails);
    await this.refreshAgent(agentId);
  }

  private async getAgentsFromBackend(): Promise<Record<string, AiAgentAndContexts>> {
    const appSquid = await this.applicationService.getApplicationSquid();
    const agents = await appSquid.ai().listAgents();
    const agentsMap: Record<string, AiAgentAndContexts> = {};
    await PromisePool.for(agents)
      .withConcurrency(agents.length || 1)
      .handleError((e, agent) => {
        console.error('Unable to fetch contexts for agent', agent, e);
      })
      .process(async agent => {
        agentsMap[agent.id] = await this.attachContextsToAgent(agent);
      });
    return agentsMap;
  }

  private async getAgentFromBackend(agentId: AiAgentId): Promise<AiAgentAndContexts | undefined> {
    const appSquid = await this.applicationService.getApplicationSquid();
    const agent = await appSquid.ai().agent(agentId).get();
    if (!agent) {
      return undefined;
    }
    return await this.attachContextsToAgent(agent);
  }

  private async refreshAgent(agentId: AiAgentId): Promise<void> {
    const agentAndContexts = await this.getAgentFromBackend(agentId);
    if (!agentAndContexts) {
      return undefined;
    }
    const allAgents = this.getAgentsAndContexts();
    if (!allAgents) {
      return undefined;
    }
    allAgents[agentId] = agentAndContexts;
    this.agentSubject.next(allAgents);
  }

  private async attachContextsToAgent(agent: AiAgent): Promise<AiAgentAndContexts> {
    const appSquid = await this.applicationService.getApplicationSquid();
    const contexts = await appSquid.ai().agent(agent.id).listContexts();
    const contextMap: Record<string, AiAgentContext> = {};
    contexts.forEach(context => {
      contextMap[context.id] = context;
    });
    return {
      agent,
      contextMap,
    };
  }

  private getAgentsAndContexts(): Record<AiAgentId, AiAgentAndContexts> | undefined {
    return truthy(this.agentSubject.getValue(), 'List of agents were not initialized');
  }
}
